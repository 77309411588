import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { DataTable, DialogWindow, Selector, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import BlockIcon from '@mui/icons-material/Block';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useData } from '../../config/Provider';

const GuidanceTimeTable = ({nav}) => {
  
  const [ days , setDays ] = useState([])
  const [ subjects , setSubjects ] = useState([])
  const [ divisions , setDivisions ] = useState([])
  const [ Header , setHeader ] = useState([])
  const [ openDialog , setOpenDialog ] = useState(false)
  const [ subject , setSubject ] = useState(0)
  const [ day , setDay ] = useState(0)
  const [ dayStr , setDayStr ] = useState('')
  const [ teacher , setTeacher ] = useState(0)
  const [teacherSubject ,setTeacherSubject ] = useState([])
  const [checkTeacher ,setCheckTeacher ] = useState(0)
  const [classroomId ,setClassroomId ] = useState(0)
  const [classroomTitle ,setClassroomTitle ] = useState('')
  const [oldTeacherName ,setOldTeacherName ] = useState('')
  const {lang,dir} = useData()

  const getSub  = (day_id,subject_num) => {
    var val = '--' 
    var t_id = 0
    for ( var i = 0 ; i < teacherSubject?.length ; i++ ) {
      if (subject_num == teacherSubject[i]?.tb_sort && day_id == teacherSubject[i]?.tb_day_id ) {
        val = `${teacherSubject[i]?.teacher_fname} ${teacherSubject[i]?.teacher_lname} / ${teacherSubject[i]?.subject_title}`
        t_id = teacherSubject[i]?.teacher_id
      }
    }

    return {name : val,id : t_id}
  }

  const getData = async (id) => {
    await API.get( `/get-guidance-table/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setDays(response?.data?.days)
          setDivisions(response?.data?.division)
          setTeacherSubject(response?.data?.subjects)
          var rows = []
          var Headerrows = []
          
          Headerrows?.push({
            title : 'Days'
          })

          for (var i = 0 ; i < response?.data?.division[0]?.cr_count ; i++) {
            
            rows?.push({ 
              title : '--',
              num : i+1,
            })

            Headerrows?.push({
              title : i+1 + ' ' + lang?.subject_
            })
        
          }

          setHeader(Headerrows)
          setSubjects(rows)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  }

  const TableLoop = () => {
    return ( days?.map( ( v , k ) => 
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text' , textAlign:dir==='rtl'?'right':'left'}} > {v.wd_title} </TableCell> 
        {subjects?.map((item,k) => (
              <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} key={`${k}x`} > { getSub( v.wd_id  , item.num)?.name }</TableCell>
        ))}
      </TableRow>
    ))
  } 
 
  const Close = () => {
    setClassroomId(0)
  }
 
  const Table = () => (
    <>
      <TableHeader {...{ title:lang?.timetable_  , buttonIcon : <ArrowBackIcon /> , buttonTitle: lang?.back_ , page: Close   }} />
      <Typography color='primary.text' ml={1}>{lang?.classroom_} : {classroomTitle}</Typography>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </>
  )

  const ClassRoomsSelect = () => {

    const [ search , setSearch ] = useState('')
    const [ classrooms , setClassrooms ] = useState([])
    const [ searchClassroomWord , setSearchClassroomWord ] = useState('')
    
    const getClassrooms = async () => {
      await API.get( `/guidance-divisions/` , Vals?.GET_HEADER )
      .then( ( response )  => setClassrooms(response?.data?.data) )
      .catch((e) => console.log(e) )
    }

    const searchClassrooms = async () => {
      await API.get( `/search-guidance-divisions/${searchClassroomWord}` , Vals?.GET_HEADER )
      .then( ( response )  => setClassrooms(response?.data?.data) )
      .catch((e) => console.log(e) )
    }
 
    useEffect(() => {
      if (searchClassroomWord?.length > 0) {
        searchClassrooms()
      } else {
        getClassrooms()
      }
    } , [searchClassroomWord?.length])

    const Select = (id,e) => {
      setClassroomId(id)
      getData(id)
      setClassroomTitle(e)
    }

    const Tag = ({item}) => (
        <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : teacher === item?.teacher_id ? 'primary.orange' : 'primary.border' }]}
           onClick={() => Select(item?.division_id , `${item?.cr_title}  / ${item?.division_title}`)} >
            <Typography color='primary.text'> {item?.cr_title}  / {item?.division_title}</Typography>
        </Box>
    )

    return (
      <Box sx={[Style?.TagContainer , {  mt:1 }]}>
          <Typography variant='h6' color='primary.text'> {lang?.select_classroom_} </Typography>
          <TextInput {...{value:searchClassroomWord,set:setSearchClassroomWord,placeholder:lang?.search_,type:'text'}} />
          <Box sx={[Style?.TagInnerContainer , { borderColor:'black' }]}>
              {classrooms?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
          </Box>
      </Box>
    )
  }
 
 
  return (
    <Box sx={Style?.Container}>
      {classroomId == 0 ? <ClassRoomsSelect /> : <Table /> }
    </Box>
  )
}

export default GuidanceTimeTable