import { InputBase  } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, validateEmail } from '../../config/functions';
import { useParams } from 'react-router-dom';
import { useData } from '../../config/Provider';

const AddParent = ({nav}) => {

  const [ image , setImage ] = useState(null)
  const [ emailValid , setEmailValid ] = useState(true)
  const [ email , setEmail ] = useState('')
  const [ password , setPassword ] = useState('')
  const [ name , setName ] = useState('')
  const { id } = useParams()
  const { lang } = useData()

  const getfile = (e) => {
    setImage(e)
  }

  const handleCreate64 = useCallback(async (e) => {
    const file = e.target.files[0]
    const base64 = await ToBase64(file)
    setImage(base64)
    // setUpload(file)
    e.target.value = ''
  },[])

const ToBase64 = (file) => {
  return new Promise( (resolve,reject) => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }

  })
 }

 const deleteImg = () => {
  setImage('')
 }


  const add = async () => {

    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }

    await API.post( `/add-parent/` , {  email : email , password : password , name : name , id : parseInt(id) } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/students/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  return (
    <Box p={2} sx={Style?.Container}>
      <TableHeader {...{ title:lang?.add_parent_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : add }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={name} onChange={(e) => setName(e.target.value)} placeholder={lang?.name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
            <InputBase value={password} onChange={(e) => setPassword(e.target.value)} placeholder={lang?.password_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box>
        
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
        </Box>

      </Box>

    </Box>
  )
}

export default AddParent