export const Style = {
    Input : {
        input : {color:'primary.text'  },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1,
        mt:2
    } , 

    Selector :{
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        width:'100%' ,
        mt:2,
        color:'primary.text',
        label : { color : 'red' }
    },

    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2
    } ,
}