import React from 'react'
import { Style } from './Style'
import { Table , TableHead , TableCell , TableBody , TableRow } from '@mui/material';
import { useData } from '../../config/Provider';

const DataTable = (props) => {
  const {Header , Data} = props
  const { dir } = useData()
  return (
    <Table sx={Style?.Table}>
      <TableHead>
        <TableRow sx={[Style?.TableRow,{}]}>
          { Header?.map((v,k) => ( <TableCell key={k} sx={{fontWeight:'bold' , color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {v.title} </TableCell> )) }
        </TableRow>
      </TableHead>
      <TableBody>
        {Data}
      </TableBody>
    </Table>
  )
  
}

export default DataTable