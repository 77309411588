import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { TableHeader } from '../../components'
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { useParams } from 'react-router-dom'
import { API, validateEmail } from '../../config/functions';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useData } from '../../config/Provider';

const EditParent = ({nav}) => {

  const [ name , setName ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ emailValid , setEmailValid ] = useState(true)
  const { id } = useParams()
  const {lang} = useData()
  const save = async () => {

    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }

    await API.post( `/save-parent/` , { name : name , email : email , id : id  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/students/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const get = async () => {
    await API.get( `/get-parent/${id}`, Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            setEmail(response?.data?.data?.email)
            setName(response?.data?.data?.name)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    get()
  } , [id])

  return (
    <Box p={2} sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.edit_parent_ , buttonTitle : lang?.save_ , buttonIcon : <CheckRoundedIcon /> , page : save }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={name} onChange={(e) => setName(e.target.value) } placeholder={lang?.name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditParent