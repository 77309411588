
import { createTheme } from '@mui/material/styles';
 

export const Dark = createTheme({
   
    palette: {
      primary: {
        main: '#101010',
        secondary : '#171717',
        orange : '#e9a282',
        blue : '#1a76bf',
        text:'#fafafa',
        red : '#cb5d5d',
        border: '#363636', 
        bg:'#ede9ea'
      },
    },
});

export const Light = createTheme({
    palette: {
      primary: {
        main: '#ffffff',
        secondary : '#fbfbfb',
        orange : '#e9a282',
        blue : '#1a76bf',
        text:'#676767',
        red : '#cb5d5d',
        border: '#e7e7e7',
        bg:'#ede9ea'
      },
    },
  });
    