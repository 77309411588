import { Box, Button, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TextInput } from '../../components'
import { useData } from '../../config/Provider'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import AddIcon from '@mui/icons-material/Add';
import { Style } from './Style';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const EditAssessment = ({nav}) => {
    const {id} = useParams()
    const [title, setTitle] = useState('')
    const [score, setScore] = useState(null)
    const [option, setOption] = useState('')
    const [data, setData] = useState([])
    const { lang } = useData()
 
    const getData = async () => {
        await API.get( `/get-template/${id}`  , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                const op = response?.data?.data?.template_type
                setScore(response?.data?.data?.template_score)
                setOption(options?.find((i) => i.value == op)?.value )
                setTitle(response?.data?.data?.template_title)
            } else {
                console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        getData()
    },[id])
    const options = [
        {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
        {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
        {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
        {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/dictonary.png'} , 
        {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/exam.png'} , 
    ]

    const submit = async () => {
        
        if ( !title || score == 0 || !option.trim() ) {
            return false
        }

        await API.post( `/save-template/` , { score:score,title:title,option:option,id:id  } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                nav(`/assessments/`)
            } else {
                console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    return (
        <Box m={5} sx={Style?.Container}>
            <Box sx={Style?.inputsContainer}>
                <Box sx={{width:{xs:'100%',md:'50%'}}}>
                    <TextInput {...{value:title,set:setTitle,placeholder:lang?.title_,type:'text'}}/>
                </Box>
                <Box sx={{width:{xs:'100%',md:'50%' }}} mt={2}>
                    <TextInput {...{value:score,set:setScore,placeholder:lang?.score_,type:'number'}}/>
                </Box>
            </Box>
     
            <Box sx={{width:'80%' }}>
                <Grid container mt={1}>
                    {options?.map((item,key) => (
                        <Grid item={true} xs={12} sm={12} md={2.4} lg={2.4} xl={2.4}>
                            <Box sx={Style?.OptionCard} onClick={() => setOption(item?.value)}>
                                <Box sx={{textAlign:'right'}}>
                                    {option === item?.value ? (<CheckCircleOutlineIcon sx={{color:'primary.blue'}} />):(<PanoramaFishEyeIcon sx={{color:'primary.orange'}} />)}
                                </Box>
                                <Box sx={Style?.OptionCardContainer}>
                                    <CardMedia component="img" image={item?.image} alt="LOUNX logo" sx={Style?.CardMedia}/>
                                    <Typography color='primary.text' fontSize={20}>{item?.title}</Typography>
                                </Box>
                            </Box>            
                        </Grid>
                    ))}
                </Grid>  
            </Box>

            <Box sx={{width:'80%' , mt:10 }}>
                <Button sx={Style?.CreateBtn} variant="contained" startIcon={<CheckIcon />} onClick={submit}>
                    {lang?.save_}
                </Button>
            </Box>
  
        </Box>
    )
}

export default EditAssessment