import React ,  {useState,useEffect }from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { TableHeader } from '../../components';
import { Box } from '@mui/system';
import { useData } from '../../config/Provider';

const GuidanceSchoolAnnouncements = () => {

    const {lang} = useData()
    const [school, setSchool ] = useState([])
    const [teacher, setTeacher ] = useState([])
    const [section, setSection ] = useState('school')
 
    const getData = async () => {
      await API.get( `/get-guidance-announcements/` , Vals?.GET_HEADER )
      .then( function ( response ) {
          if (response?.data?.status === true) {
            setSchool(response?.data?.data)
            setTeacher(response?.data?.teacher)
          } else {
            console.log(response?.data?.msg)
          }
      })
      .catch((e) => console.log(e) )
    }

    const Style = {
        root: {
            minWidth: 275,
            margin: '10px',
            backgroundColor:'primary.main'
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            color:'primary.text'
        },
        date: {
            fontSize: 14,
            color:'primary.text'
        },
        Container : {
          mr : 2 ,
          ml : 2 ,
          mt : 2 ,
          p:2,
          backgroundColor:'white',
          border:'2px solid #e4e6e5',
          borderRadius:2,
          float:'left',
          width:'95%'
      } ,
  
        Tag : {
          borderRadius:15,
          pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
          float:'left',
          alignItem:'canter',
          display:'flex',
          cursor:'pointer',
          borderWidth:0,
          borderWidth:1,
          borderStyle:'solid',
        } ,
    }

    const types = [
      { title : lang?.teachers_ , value : 'teacher'} , 
      { title : lang?.students_ , value : 'student' } , 
      { title : lang?.parents_ , value : 'parent' } , 
      { title : lang?.all_ , value : 'all' } , 
    ]

    const PostCard = ({ item }) => {

        return (
          <Card sx={Style?.root}>
            <CardContent>
              <Typography sx={Style?.title} gutterBottom>
                {item?.a_title}
              </Typography>
              <Typography variant="body2" component="p" color='primary.text'>
                {item?.a_decription}
              </Typography>
              <Typography sx={Style?.date} gutterBottom  color='primary.text'>
                {CalculateTime(item?.a_date)} - {lang?.school_}
              </Typography>
            </CardContent>
          </Card>
        );
      };

      const TeacherPostCard = ({ item }) => {

        return (
          <Card sx={Style?.root}>
            <CardContent>
              <Typography sx={Style?.title} gutterBottom>
                {item?.a_title}
              </Typography>
              <Typography variant="body2" component="p" color='primary.text'>
                {item?.a_decription}
              </Typography>
              <Typography sx={Style?.date} gutterBottom  color='primary.text'>
                {CalculateTime(item?.a_date)} - {item?.teacher_fname} {item?.teacher_lname}
              </Typography>
            </CardContent>
          </Card>
        );
      };

      useEffect(() => {
        getData()
      } , [])

      const sections = [
        { title : lang?.teachers_ , value : 'teacher' } , 
        { title : lang?.school_ , value : 'school'} , 
      ]

  return (
    <Box sx={Style?.Container}>
        <TableHeader {...{title:lang?.announcements_}} />
        
        <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
          {sections?.map((item,key) => 
            <Box key={key} sx={[Style?.Tag , 
              { borderColor :  item?.value === section ? 'primary.orange' : 'primary.border' }
            ]} onClick={() => setSection(item?.value) }>
              <Typography color='primary.text'> {item?.title} </Typography>
            </Box>
          )}
        </Box>
      
        {section === 'school' && ( school?.map((item,key) =>  <PostCard key={key} {...{item}} />  ) )}
        {section === 'teacher' && ( teacher?.map((item,key) =>  <TeacherPostCard key={key} {...{item}} />  )) }
    </Box>
  )
}

export default GuidanceSchoolAnnouncements