import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const centerTextPlugin = {
  id: 'centerTextPlugin',
  afterDraw: (chart) => {
    if (chart.config.type !== 'doughnut') {
      return;
    }

    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;

    const fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + 'em sans-serif';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#111';
    ctx.textAlign = 'center';

    const centerX = width / 2;
    const centerY = height / 2;

    ctx.fillText(chart.config.options.centerText, centerX, centerY);
  },
};

ChartJS.register(centerTextPlugin);

const ChartDoughnut = ({ colorA, colorB, height, width, title , sets }) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data: sets,
        backgroundColor: [colorA, colorB],
        borderColor: [colorA, colorB],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Doughnut
      height = {height}
      width = {width}
      data = {data}
      options = {{
        plugins : {
          legend : {
            display : false,
          },
        },
        centerText : title ,
      }}
    />
  )
}

export default ChartDoughnut;
