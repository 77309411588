import React from 'react';
import * as XLSX from 'xlsx'; 
import { saveAs } from 'file-saver';
import { useData } from '../../config/Provider';
import { Button } from '@mui/material';
import { Btn } from '../../components';

const Ztest = () => {

    const { lang } = useData() 
  const headerTitles = [ lang?.email_ , lang?.first_name_ , lang?.last_name_ , lang?.mobile_ , lang?.number_  ];  

  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([], { header: headerTitles });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, 'data.xlsx');
  };

  return (
    <Btn {...{func:generateExcel,buttonTitle:lang?.download_excel_}} />
  );
};

export default Ztest;
