
import React, { useEffect, useState } from 'react'
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { AppBar,Avatar,Badge,Button,Collapse,IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Popover, TextField, Typography } from '@mui/material'
import { Box, Container, Stack } from '@mui/system'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useData } from '../../config/Provider';
import { API, useLocalState } from '../../config/functions';
import { Style } from './Style';
import DensityMediumTwoToneIcon from '@mui/icons-material/DensityMediumTwoTone';
import { En } from '../../config/langs/en';
import { Ar } from '../../config/langs/ar';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import MessageIcon from '@mui/icons-material/Message';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import LogoutIcon from '@mui/icons-material/Logout';
import Vals from '../../config/Vars';
import { useNavigate } from 'react-router-dom';

const TopBar = () => {

    const { showSidebar, setShowSidebar , lang , mobileNav , setMobileNav , setlogged , setLangDiloag , userInfo , dir , info } = useData()
    const [ Islogged , setIslogged ] = useLocalState("Islogged" ); 
    const navigate = useNavigate()
 
    const [search, setSearch] = useState('');
    const [mainDropdown, setMainDropdown] = useState(false);
 
    const hideSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    function notificationsLabel(count) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }

    const logout = async () => {
        try {
            await API.post(`/logout/`, Vals?.POST_HEADER)
            .then(function (response) {
                if (response?.data?.status === 'ok') {
                    setlogged(false)
                    setIslogged(false)
                    navigate('/login/')
                    // window.location.href='/#/login'
                } else {
                    alert(response?.data?.msg)
                }
            })
        } catch (error) {
            alert(error)
        }
    }
    const dropProfile = [
        { title : lang?.profile_ , icon : <AccountCircleIcon /> , func : () => navigate('/profile/') } ,
        { title : lang?.languages_ , icon : <GTranslateIcon /> , func : () => setLangDiloag(true) } ,
        { title : lang?.logout_ , icon : <LogoutIcon /> , func : logout } ,
    ]

    const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  return (
        <AppBar position='sticky'  sx={{top:0,zIndex:10,boxShadow:'0',backgroundColor:'primary.secondary'}}>

            <Container sx={{display:{xs:'block',md:'none'}}} maxWidth="100">
              
                <Box sx={{display:'flex',justifyContent: 'space-between',p: 1,m: 1}}>


                        <IconButton aria-label="delete" sx={{color:'primary.text'}} onClick={() => setMobileNav(!mobileNav)}>

                            {mobileNav ? <HighlightOffRoundedIcon sx={{color:'primary.orange'}} /> : <DensityMediumTwoToneIcon sx={{color:'primary.orange'}}/> } 

                        </IconButton>
                            
                        {userInfo?.type !== 'master' && (

                            <Box sx={{justifyContent:'space-evenly',display:'flex',width:'50%'}}>
                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={15} color="warning">
                                
                                        <FolderCopyIcon sx={{color:'#707578'}} />
                                
                                    </Badge>
                                
                                </IconButton> 

                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={5} color="success">
                                
                                        <MessageIcon sx={{color:'#707578'}}  />
                                
                                    </Badge>
                                
                                </IconButton>

                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={2} color="error">
                                
                                        <NotificationsIcon sx={{color:'#707578'}}  />
                                
                                    </Badge>
                                
                                </IconButton>
                            </Box>

                        ) }


                        <Box>
                             <Avatar sx={Style?.Avatar}
                                aria-owns={mainDropdown ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                src=''
                                onClick={() => setMainDropdown(!mainDropdown)} /> 
 
                        </Box>

                </Box>

            </Container>

            <Container sx={{display:{xs:'none',md:'block'}}} maxWidth="100">
              
                <Box sx={{display:'flex',justifyContent: 'space-between',p: 1,m: 1}}>

                    <Stack spacing={2} direction="row">

                        <IconButton aria-label="delete" sx={{color:'primary.text'}} onClick={hideSidebar}>

                            <DensityMediumTwoToneIcon sx={{color:'primary.orange'}}/>

                        </IconButton>
                            
                    </Stack>
                    
                    <Stack spacing={2} direction="row">


                        {userInfo?.type !== 'master' && (
                            <>

                                <Box sx={Style?.inputCon}>
                                    <InputBase 
                                        type={'text'} 
                                        value={search} 
                                        onChange={(e) => setSearch(e.target.value)} 
                                        placeholder={lang?.search_} 
                                        sx={Style?.Input} 
                                        variant="outlined" 
                                        autoComplete='off'
                                    />
                                    <SearchIcon sx={{color:'#717276'}} />
                                </Box>
                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={15} color="warning">
                                
                                        <FolderCopyIcon sx={{color:'#707578'}} />
                                
                                    </Badge>
                                
                                </IconButton> 

                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={5} color="success">
                                
                                        <MessageIcon sx={{color:'#707578'}}  />
                                
                                    </Badge>
                                
                                </IconButton>

                                <IconButton sx={{color:'primary.text'}} aria-label={notificationsLabel(100)}>

                                    <Badge badgeContent={2} color="error">
                                
                                        <NotificationsIcon sx={{color:'#707578'}}  />
                                
                                    </Badge>
                                
                                </IconButton>
                            </>
                        )}


                        {/* <Box sx={Style?.DropBtn}
                            aria-owns={mainDropdown ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onClick={() => setMainDropdown(!mainDropdown)}>
                            
                            <Typography> {lang?.account_} </Typography>
                            
 
                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={mainDropdown}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: dir === 'rtl' ? 'left' :'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: dir === 'rtl' ? 'left' :'right',
                            }}
                            open={mainDropdown}
                            onClose={() => setMainDropdown(!mainDropdown)}
                            >
                                {dropProfile.map((item,key) => (
                                    <MenuItem key={key} onClick={item?.func} sx={{direction:dir}}>
                                        {item?.icon}
                                        <Typography textAlign="center"  pl={1} pr={1} >{item?.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>

                            {mainDropdown ? <ExpandLess /> : <ExpandMore />}
                    
                        </Box>  */}

                        <Box sx={Style?.AvatarMCon}
                        aria-owns={mainDropdown ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onClick={() => setMainDropdown(!mainDropdown)}>
                            <Box sx={Style?.AvatarCon}>
                                <Avatar   variant="rounded"/>
                                <Box sx={{
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'flex-start',
                                    justifyContent:'flex-start',
                                    ml :  1.5,
                                    mr :  1.5
                                }}>
                                    <Typography sx={{color:'primary.text',fontSize:17}}> {info?.name} </Typography>
                                    <Typography  sx={{color:'#c1c1c1',fontSize:15}}> {info?.type} </Typography>
                                </Box>
                                {mainDropdown ? <ExpandLess /> : <ExpandMore />}

                            </Box>

                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={mainDropdown}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: dir === 'rtl' ? 'left' :'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: dir === 'rtl' ? 'left' :'right',
                            }}
                            open={mainDropdown}
                            onClose={() => setMainDropdown(!mainDropdown)}
                            >
                                {dropProfile.map((item,key) => (
                                    <MenuItem key={key} onClick={item?.func} sx={{direction:dir}}>
                                        {item?.icon}
                                        <Typography textAlign="center"  pl={1} pr={1} >{item?.title}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>


                        </Box>

                    </Stack>

                </Box>

            </Container>

        </AppBar>
  )
}

 export default TopBar