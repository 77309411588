import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { TableHeader } from '../../components'
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useData } from '../../config/Provider';

const EditTeacherAnnouncement = ({nav}) => { 

  const [ title , setTitle ] = useState('')
  const [ decription , setDescription ] = useState('')
  const {id} = useParams()
  const {lang}=useData()
  const [selectedEv, setselectedEv] = useState('')
  const page = async () => {
    await API.post( `/save-teacher-announcement/` , { id:id ,  decription : decription , title : title , type :  selectedEv  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/my-announcements/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getData = async () => {
    await API.get( `/get-teacher-announcement/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setDescription(response?.data?.data?.a_decription)
          setTitle(response?.data?.data?.a_title)
          setselectedEv(response?.data?.data?.show_type)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getData()
  } , [])
 
  const types = [
    { title : lang?.students_ , value : 'student' } , 
    { title : lang?.parents_ , value : 'parent' } , 
  ]

  return (
    <Box sx={Style?.Container}>
      
      <TableHeader {...{ title : lang?.edit_announcement_ , buttonTitle : lang?.save_   , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
        
          <Box>

            <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
              {types?.map((item,key) => 
                <Box key={key} sx={[Style?.Tag , 
                  { borderColor :  item?.value === selectedEv ? 'primary.orange' : 'primary.border' }
                ]} onClick={() => setselectedEv(item?.value) }>
                  <Typography color='primary.text'> {item?.title} </Typography>
                </Box>
              )}
            </Box>

          </Box>

          <InputBase 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            placeholder= {lang?.title_} 
            sx={Style?.Input} 
            variant="outlined" 
            autoComplete='off'/>
        
          <InputBase 
            value={decription} 
            onChange={(e) => setDescription(e.target.value)} 
            placeholder={lang?.description_} 
            sx={Style?.Input} 
            variant="outlined" 
            rows={5}
            multiline={true}
            autoComplete='off'/>
        
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditTeacherAnnouncement