import React, { useEffect } from 'react'
import { Backdrop, CircularProgress, Grid, IconButton, InputBase , Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Btn , TableHeader , TextInput } from '../../components'
import { Style } from './Style'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useParams } from 'react-router-dom'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { ExportPdf } from '../../components/exportPdf/ExportPdf'
import ClearIcon from '@mui/icons-material/Clear';
import zIndex from '@mui/material/styles/zIndex'
import { useData } from '../../config/Provider'

const Questions = () => {
  
  const [ load , setload ] = useState(false)
  const [ createPdf , setCreatePdf ] = useState(false)
  const [ data , setData ] = useState([])
  const [questions, setQuestions] = useState([])
  const [PDFinfo, setPDFinfo] = useState([])
  const [score, setScore] = useState([])
  const [r, setr] = useState([])
  const {id} = useParams()
  var rows = []
  const { lang } = useData()
  const [formData, setFormData] = useState({});
  const [answerData, setAnswerData] = useState({});
  const [answerArr, setAnswerArr] = useState([]);
  const [options, setOptions] = useState([])
  const [totalScore, setTotalScore] = useState(0)
  const [rtotal, setrtotal] = useState(false)

  
  const handleAnswers = (event) => {
    const newFormData = {}
    const { name, value } = event.target
    newFormData[name] = {text : value}
    setAnswerData({ ...answerData, ...newFormData })
  }
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [inputType, questionId, fieldType] = name.split('_');
  
    setFormData(prevState => ({
      ...prevState,
      [`text_${questionId}`]: {
        ...prevState[`text_${questionId}`],
        [fieldType]: inputType === 'score' ? parseInt(value) : value,
      },
    }));

  };

  const countTotalScore = () => {
    const newTotalScore = Object.values(formData).reduce((acc, curr) => {
      if (curr.score) {
        return acc + parseInt(curr.score);
      }
      return acc;
    }, 0);
    setTotalScore(newTotalScore);
  }

  useEffect(() => {
    countTotalScore()
  }, [handleInputChange,rtotal])
  
  const SetOptions = async (arr) => {
    const opt = await arr?.map((item) => {
      const parsedOptions = JSON.parse(item.question_options);
      const optionsArray = parsedOptions.map(option => ({
        value: option.value,
        question_id: option.question_id
      }));
      return optionsArray;
    }).flat();
    
    setOptions(opt)
  }

  const getData = async () => {
    await API.get( `/get-questions/${id}` , Vals?.GET_HEADER )
    .then( response => {
      if (response?.data?.status === true) {
        setQuestions(response?.data?.data)
        setPDFinfo(response?.data?.info)
        setScore(response?.data?.info?.template_score)
        const newFormData = {};
        response?.data?.data?.map( async (item) => {
          newFormData[`text_${item?.question_id}`] = { text: item?.question_text , score: item?.question_score }
        })
        setFormData({ ...formData, ...newFormData });
        SetOptions(response?.data?.data)
    

      } else {
        console.log(response?.data?.msg)
      }
 
    }) 
    .catch((e) => console.log(e) )
  }
 

  useEffect(() => {
    getData()
  } , [])
 
  const types = [ 
    { name : 'text'  } , 
    { name : 'option' } , 
  ]

  const addQuestion = async (e) => {
 
    await API.post( `/add-question/` , { input : e , template_id : id } , Vals?.POST_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setQuestions([{ question_type : response?.data?.type , question_id : response?.data?.id } , ...questions ])
        formData[`text_${response?.data?.id}`] = {text : '' , score : 0}
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const deleteQuestion = async (question_id) => {
 
    await API.post( `/delete-question/` , { question_id : question_id , template_id : id } , Vals?.POST_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        const a = questions.filter(q => q.question_id  !== question_id);
        setQuestions(a)
        setrtotal(!rtotal)
      } else {
        console.log(response?.data?.msg)
      }
      
    })
    .catch((e) => console.log(e) )
  }
 
  const [ text , setText ] = useState('')
  const [ answer , setAnswer ] = useState('')

  const choose = (id) => {
    if ( !answerData?.[`answer_${id}`]?.text.trim() ) {
      return false
    }
    setOptions([{ value : answerData?.[`answer_${id}`]?.text , question_id : id } , ...options ])
    answerData[`answer_${id}`] = {text : ''}
  }

  const deleteOption = (e, id) => {
    if (options.find((item) => item?.value == e && item?.question_id == id)){
      const filter = options?.filter((item) => item?.value !== e || item?.question_id != id )
      setOptions(filter)
    }
  }

  const saveTemplate = async () => {

    setload(true)
    await API.post( `/save-questions/` , { data : formData , template_id : id , options: options } , Vals?.POST_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setload(false)
      } else {
        console.log(response?.data?.msg)
      }
      setload(false)
    })
    .catch((e) => console.log(e) )

  }

  var rows = []
  
  const CreatePDF = () => {
   
    for ( var i = 0 ; i < Object.keys(formData)?.length ; i++ ) {
      rows?.push({
        question : formData[Object.keys(formData)[i]]["text"]
      })
    }

    setr(rows)
    
    setload(true)
    setCreatePdf(true)
    setTimeout(() => {
      setCreatePdf(false)
      setload(false)
    }, 1500);
  
  }
    
  const [ a , seta ] = useState({
    title: PDFinfo?.template_title,
    teacherName: `${PDFinfo?.teacher_fname} ${PDFinfo?.teacher_lname}`,
    schoolName: PDFinfo?.school_name,
    questions: r
  })

  const pdf = ExportPdf(a);

  return (
    <>
      {createPdf && ( 
        <Box sx={{height:'100%' , width:'100%' }}>
          {pdf}
        </Box>  
      )}
      <Box sx={Style?.Container}>
        
      <Box sx={{ position: 'fixed', bottom: '60%', right: '0', p: 2, bgcolor: (score - totalScore) >= 0 ?  'primary.main' : 'red' ,  zIndex:1 }}>
      <Typography variant="p" color="primary.text">
        {lang?.remaining_score} : { score - totalScore}
      </Typography>
    </Box>

        <TableHeader  {...{title:'Questions' , buttonTitle:'save' , page:saveTemplate }}/>
          <Grid container>
        
            <Grid item={true} xs={12} >
              {types?.map((item,k) => ( 
                <Box key={k} sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : 'primary.blue'  }]} onClick={() => addQuestion(item?.name)} >
                  <Typography color='primary.text'> {item?.name}</Typography>
                </Box>
              ))}

                <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : 'primary.blue'  }]} onClick={CreatePDF} >
                  <Typography color='primary.text'> {lang?.download_pdf_}</Typography>
                </Box>              
            </Grid>
            <Grid   item={true} xs={12} mt={3}  >
              {questions?.map((item,key) => ( 
                <Box sx={{p:2,borderWidth:1,borderStyle:'solid',borderColor:'gray' , mt:2 , borderRadius : 1}} key={key}>
                  <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                  <Typography  color='primary.text' mb={2}> { item?.question_type === 'text' ? 'Text' :'Select Options' } </Typography> 
                  <IconButton onClick={() => deleteQuestion(item.question_id)} aria-label="edit">
                    <DeleteTwoToneIcon sx={{color:'primary.orange'}}/>
                  </IconButton>
                  </Box>
                  {item?.question_type === 'text' && (
                    <>
 
                      <InputBase 
                        type='text' 
                        value={formData[`text_${item?.question_id}`]?.text} 
                        onChange={handleInputChange} 
                        name={`text_${item?.question_id}_text`} 
                        placeholder={lang?.question_} 
                        sx={Style?.Input} 
                        variant="outlined" 
                        autoComplete='off'
                      />

                      <InputBase 
                        type='number' 
                        value={formData[`text_${item?.question_id}`]?.score} 
                        onChange={handleInputChange} 
                        name={`text_${item?.question_id}_score`} 
                        placeholder={lang?.score_} 
                        sx={Style?.Input} 
                        variant="outlined" 
                        autoComplete='off'
                      />
                    </>

                  )}

                  {item?.question_type === 'option' && (
                    <Box>
                      <InputBase type='text' 
                        value={formData[`text_${item?.question_id}`]?.text} 
                        onChange={handleInputChange} 
                        name={`text_${item?.question_id}`} 
                        placeholder={lang?.question_} 
                        sx={Style?.Input} 
                        variant="outlined" 
                        autoComplete='off' />
                      <Box sx={{
                        display:'flex',
                        flexDirection:'row',
                      }}>

                        <InputBase type='text' 
                          value={answerData[`answer_${item?.question_id}`]?.text} 
                          onChange={handleAnswers} 
                          name={`answer_${item?.question_id}`} 
                          placeholder={lang?.write_option_} 
                          sx={Style?.Input} 
                          variant="outlined" 
                          autoComplete='off' />

                        <InputBase type='text' 
                          value={formData[`score_${item?.question_id}`]?.score} 
                          onChange={handleAnswers} 
                          name={`score_${item?.question_id}`} 
                          placeholder={lang?.score_} 
                          sx={Style?.Input} 
                          variant="outlined" 
                          autoComplete='off' />

                      </Box>

                      <Box sx={{display:'flex',mt:3}}>
                        {options?.map((v,key) => (
                          v?.question_id  == item?.question_id &&
                          <Box key={key} sx={Style?.optionTag}>
                                <Typography color='primary.text'>{v?.value}</Typography>
                                <ClearIcon onClick={() => deleteOption ( v?.value , v?.question_id ) } sx={{mr:0,ml:5,color:'primary.text'}} />
                          </Box>
                        ))}
                      </Box>

                      <Box sx={{width:'100%'}}>
                        <Btn {...{buttonTitle:lang?.add_option_,func:() => choose(item?.question_id)}} />
                      </Box>

                    </Box>
                  )}

                </Box>         
              ))}
            </Grid>
          </Grid>

          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={load}
            // onClick={handleClose}
          >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>

  )
}

export default Questions