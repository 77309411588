import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { TableHeader } from '../../components'
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { useParams } from 'react-router-dom'
import { API } from '../../config/functions';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useData } from '../../config/Provider';

const EditSchoolSubject = ({nav}) => {

  const [ title , setTitle ] = useState('')
  const { id } = useParams()
  const {lang} = useData()
  const save = async () => {
    await API.post( `/save-subject/` , { title : title , id : id  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/subjects/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const get = async () => {
    await API.get( `/get-subject/${id}`, Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            setTitle(response?.data?.title)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    get()
  } , [id])

  return (
    <Box p={2} sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.edit_subject_ , buttonTitle : lang?.save_ , buttonIcon : <CheckRoundedIcon /> , page : save }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={title} onChange={(e) => setTitle(e.target.value) } placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditSchoolSubject