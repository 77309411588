import { Alert, AlertTitle, Button, CardMedia, Grid, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Style } from './Style'
import LoginIcon from '@mui/icons-material/Login';
import Vals from '../../config/Vars';
import { API, useLocalState } from '../../config/functions';
import { useState } from 'react';
import { useData } from '../../config/Provider';

const Login = ({nav}) => {

    const {logged,setlogged,userType, setUserType,setInfo,info,socket } = useData()
    const [ email , setEmail ] = useState('')
    const [ password , setpassword ] = useState('')
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);
    const [screenHeight, setScreenHeight] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    useEffect(() => {
      setScreenHeight(window.innerHeight);
    }, [])

    const Submit = async () => {
        await API.post( `/login/` , { email : email , password : password } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === 'ok') {
                setUserType(response?.data?.type)
                setInfo(response?.data?.info)
                setlogged(true)
                setIslogged(true)
                socket?.emit('store socket', { user_id : response?.data?.info , socketId: socket?.id });
                // nav('/')
                window.location.href='/'
            } else {
                setShowAlert(true)
                setAlertMsg(response?.data?.msg)
                setlogged(false)
            }

        })
        .catch((e) => console.log(e) )
    }

    return (
        <Box >
            <Grid container spacing={0} m={0} p={0} sx={{height:'100vh',}}>
                <Grid item xs={0} sm={0} md={9} lg={9} xl={9} >
                    <Box sx={Style?.ImageContainer}>
                        <CardMedia 
                            component="img"
                            image={"./loginbg.png"}
                            alt="Calssenda logo"
                            sx={Style?.logo}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                    <Box sx={Style?.FormContainer}>

                        <Box sx={{textAlign:'left',width:'100%',ml:3,mb:2}}>
                            <CardMedia 
                                component="img"
                                image={"./logo512.png"}
                                alt="Calssenda logo"
                                sx={{width:300,mb:5}}
                            />
                            <Typography variant='h5' sx={{color:'#c2c2c2',fontWeight:'bold'}}>Signs in</Typography>
                        </Box>

                        {showAlert && (
                            <Alert severity="info" sx={Style?.alertStyle}>
                                {alertMsg} 
                            </Alert>
                        )}

                        <InputBase 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                Submit() 
                            }
                        }}
                        value={email} onChange={(e) => setEmail(e.target.value)} placeholder='E-mail' sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>
                        <InputBase 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                Submit() 
                            }
                        }}
                        value={password} onChange={(e) => setpassword(e.target.value)} type='password'  placeholder='password' sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>
                        <Box sx={Style?.Button}>
                            <Button onClick={Submit} sx={{height:45,width:'95.1%',backgroundColor:'primary.orange', '&:hover' : {backgroundColor:'primary.blue',} , color:'white'}} variant="contained" endIcon={<LoginIcon />}>Login</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login