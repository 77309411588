import { Box, Button, CardMedia, InputBase, Typography  } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AlertsDialog, Btn, TextEditor  } from '../../components';
import { useData } from '../../config/Provider';
import { Style } from './Style';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useParams } from 'react-router-dom';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
 
const QuestionsAnswers = ({nav}) => {
  
  const { id , student } = useParams()
  const { lang , dir } = useData()
  const [ questions , setQuestions ] = useState([])
  const [ data , setData ] = useState([])
  const [ answers , setAnswers ] = useState([])
  const [ paragraph , setParagraph ] = useState('');
  const [ scores , setScores ] = useState([]);
 
  const getData = async () => {
    await API.get( `/student-view-student-answer/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {

          var rows = []
          var answersRows = []
          var scoresRows = []

          setData(response?.data?.info)
          setQuestions(response?.data?.questions)
          setParagraph(response?.data?.info?.template_paragraph)

          response?.data?.data?.map(( item ) => {
            const options =  item?.question_options ? JSON.parse(item?.question_options) : []
            rows?.push({
              question_type : item?.question_type,
              question_score : item?.question_score,
              options : item?.question_type === 'option' ? options : [] , 
              match : item?.question_type === 'match' ? options : [], 
              blank : item?.question_type === 'fill' ? options : [] ,
              question_text : item?.question_text , 
              question_id : item?.question_id 
            })

            if (item?.answer_text) {
              answersRows?.push({
                question_id : item?.question_id ,
                value : item?.question_type !== 'text' ? JSON.parse( item?.answer_text  ) : item?.answer_text  , 
                type : item?.question_type ,
              })
            }

            if (item?.question_type === 'option') {
              scoresRows?.push({
                question_id : item?.question_id , 
                answer_score : options?.find( ( i ) => i?.text === JSON.parse( item?.answer_text  ) )?.isTrue ? item?.question_score : 0
              })
            }

            if (item?.question_type === 'text') {
              scoresRows?.push({
                question_id : item?.question_id , 
                answer_score : item?.answer_score
              })
            }

            if (item?.question_type === 'fill') {

              var d_score = item?.question_score / options?.length
              var v_score = 0

              options?.map((v,k) => {
                v_score += JSON.parse(item?.answer_text)[k] === v?.text ? d_score : 0 
              })

              scoresRows?.push({
                question_id : item?.question_id , 
                answer_score : parseFloat(v_score.toFixed(2))  
              })

            }

            if (item?.question_type === 'match') {
              var d_score = item?.question_score / options?.length
              var v_score = 0
              options?.map((v) => {
                var findMatch = JSON.parse(item?.answer_text)?.find((i) => i?.item1?.texta === v?.texta )
                var studentAnswer = `${findMatch?.item1?.texta}${findMatch?.item2?.textb}`
                var trueAnswer = `${v?.texta}${v?.textb}`
                v_score += studentAnswer === trueAnswer ? d_score : 0
              })
              scoresRows?.push({
                question_id : item?.question_id , 
                answer_score : parseFloat(v_score.toFixed(2))  
              })
            }

          })
          setScores(scoresRows)
          setQuestions(rows)
          setAnswers(answersRows)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getData()
  } , [])
 
  const questionTypesSelector = [
    { title : lang?.multiple_choice_ , value : 'option' },
    { title : lang?.matching_ , value : 'match' },
    { title : lang?.fill_the_blank_ , value : 'fill' },
    { title : lang?.short_answer_ , value : 'text' },
  ]

  const assessmentsTyps = [
    {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
    {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
    {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
    {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/dictonary.png'} , 
    {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/exam.png'} ,  
  ]
 
  const fillBlank = (text, question_id , blanks) => {
    const parts = text?.split('---');
    const values = answers?.find((v) => v?.question_id == question_id)?.value
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <span
            style={{ borderBottom: '1px dashed #e9a282', paddingRight: 50, paddingLeft: 50 }}
            suppressContentEditableWarning
          >
            {values[index]} {values[index] === blanks[index]?.text ? <CheckIcon sx={{color:'green',ml:2,mr:2}} /> : <ClearIcon sx={{color:'red',ml:2,mr:2}}/>}
          </span>
        )}
      </React.Fragment>
    ));
    
  }

  const typeTitle = assessmentsTyps?.find((i) => i.value === data?.template_type )?.title
  const typeImg = assessmentsTyps?.find((i) => i.value === data?.template_type )?.image
 

  return (
    <Box mt={1} ml={5} mr={5} mb={2}>

      <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
      }}>
        <Box sx={{
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          width:'100%',
        }}>

          <CardMedia component="img" image={typeImg} alt="exam logo title" sx={Style?.CardMedia}/>
          
          <Box sx={{
              display:'flex',
              flexDirection:'column',
              ml:1,
              mr:1,
            }}>

            <Typography color='primary.text' fontSize={40}> {data?.template_title} </Typography>
            <Typography color='primary.text' fontSize={20}> {typeTitle} </Typography>

          </Box>

        </Box>
 

      </Box>
 
      {data?.template_type === 'comprehension' && (
        <Box sx={Style?.paragraphContainer} >
          <TextEditor {...{contnet:paragraph , setcontnet:setParagraph }}/>
        </Box>
      )}
 
      {questions?.map((question_item,key) => {

        const op =  answers?.find((i) => i.question_id == question_item?.question_id)?.value ?.length>0
        ?[answers?.find((i) => i.question_id == question_item?.question_id)?.value]:[]

        return(

          <Box sx={Style?.questionsContainer} key={key}>

            <Box sx={Style?.questionHeader}>
  
              <Box sx={Style?.questionInputContainer}>
                {question_item?.question_type==='fill'?
                  <Typography color='primary.text' fontSize={25} > 
                    {key+1} - { fillBlank ( question_item?.question_text , question_item?.question_id , question_item?.blank ) }  
                  </Typography>
                : 
                  <Typography color='primary.text' fontSize={25}>
                    {key+1} - {question_item?.question_text} 
                  </Typography>
                }
              </Box>

              <Box sx={[Style?.selectOptionContainer,{textAlign:dir==='ltr'?'right':'left'}]}>
                <Typography color='primary.text' fontSize={20}> {questionTypesSelector?.find((i) => i?.value == question_item?.question_type )?.title} </Typography>
              </Box>

            </Box>

            {question_item?.question_type === 'match' && (
              
              <Box sx={{ width:'100%', display: 'flex', flexDirection: 'column' , alignItems:'flex-start', justifyContent:'flex-start'  }}>
                <Typography color='primary.text'> { lang?.drag_from_a_to_b_ } </Typography>
                
                <Box >

                  {op[0]?.map((match, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                      <Box sx={{ ...Style?.box, mr: 3 }}>{match?.item1?.texta}</Box>
                      <ArrowForwardIcon sx={{color:'primary.orange',fontSize:30}}/>
                      <Box sx={{ ...Style?.box, ml: 3 }}>{match?.item2?.textb}</Box>
                      {data?.aa_status === 'start' && (
                        <ClearIcon 
                          sx={{color:'primary.red',fontSize:35,ml:2,mr:2}}
                        />
                      )}
                    </Box>
                  ))}

                </Box>

                <Box sx={{ width: '50%' }}>

                  {question_item?.match?.map((match_item, match_key) => {
                    
                    return(
                    <Box
                      key={match_key}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        mt: 3,
                        width:'50%'
                      }}
                    >
                          <Typography sx={{color:'red',fontSize:35}}>
                          </Typography>
                        <Box sx={{width:'50%'}}>

                        { !op[0]?.find( ( v ) => v?.item1?.texta == match_item?.texta) && (
                          <Box
                          sx={{ ...Style?.box }}
                          draggable
                          >
                            <Typography color='primary.text'>{match_item?.texta}</Typography>
                          </Box>
                        ) }  
                      </Box>
                      <Box sx={{width:'50%'}}>
                        {!op[0]?.find( ( v ) => v?.item2?.textb == match_item?.textb)  && (
                          <Box
                            sx={{ ...Style?.box, ml: 3 }}
                          >
                            <Typography color='primary.text'>{match_item?.textb}</Typography>
                          </Box>
                        )}
                      </Box> 
                    </Box>
                  )})}

                </Box>
              </Box>
            )}

            {question_item?.question_type === 'fill' && (
              <Box sx={{width:'100%'}}>

              </Box>
            )}

            {question_item?.question_type === 'option' && (

              <Box mt={3} sx={{width:'100%',}}>

                <Box sx={{
                  display:'flex',
                  flexDirection:'row',
                  mt:2,
                }}>

                  <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    width:'100%'
                  }}>
                    

                    {question_item?.options?.map((option,option_key) => (
                      
                      <Box key={option_key} mt={1} sx={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center'
                      }}>

                        <Typography color='primary.text' sx={{mr:dir==='rtl'?0:5,ml:dir==='ltr'?0:5}} fontSize={20} > {option_key+1} - </Typography>

                        <Box sx={{
                          borderColor:'primary.border',
                          borderStyle:'solid',
                          borderWidth:1,
                          width:'auto',
                          pt:1,pb:1,pr:3,pl:3,
                          maxWidth:'auto',
                          borderRadius:2
                        }}>
                          <Typography color='primary.text'>{option?.text} </Typography>
                        </Box>

                        <Box  sx={{cursor:'pointer',mr:dir==='rtl'?0:5,ml:dir==='ltr'?5:0,display:'flex',flexDirection:'row',}}>
                          
                          { answers?.find((v) => v.question_id == question_item?.question_id)?.value === option?.text ?
                            <CheckCircleOutlineOutlinedIcon  sx={{color:'primary.blue',fontSize:25}} /> : 
                            <CircleOutlinedIcon sx={{color:'primary.orange',fontSize:25}}/> 
                          }

                          {answers?.find((v) => v.question_id == question_item?.question_id)?.value === option?.text && (
                            <Typography sx={{color:'red',fontSize:15,ml:2}}>
                              { question_item?.options?.find((i) => i?.text === answers?.find((v) => v.question_id == question_item?.question_id)?.value )?.isTrue ? (
                                <CheckIcon sx={{color:'green'}} />
                              ) : <ClearIcon sx={{color:'red'}} /> }
                            </Typography>
                          )}
                        
                        </Box>

                      </Box>

                    ))}

                  </Box>

                </Box>

              </Box>
            )}

            {question_item?.question_type === 'text' && (
              <Box mt={3} sx={{width:'100%',}}>
                <Typography color='primary.text'> 
                  {answers?.find((v) => v.question_id == question_item?.question_id)?.value} 
                </Typography>
              </Box>
            )}

            {question_item?.question_type === 'text' ? 
              <Box sx={Style?.footerContainer}>
                <Box sx={{
                    display:'flex',
                    justifyContent:'flex-start' ,
                    alignItems:'center',
                    flexDirection:'row' ,
                    width:'100%'
                  }}>
                
                    <Typography color='primary.text' sx={{fontSize:18}}> {lang?.score_} : {scores?.find((v) => v?.question_id == question_item?.question_id )?.answer_score} / {question_item?.question_score} </Typography>
                </Box>
                
              </Box>
            : 
              <Box sx={Style?.footerContainer}>
                <Typography color='primary.text'> {lang?.score_} : {scores?.find((v) => v?.question_id == question_item?.question_id )?.answer_score } / {question_item?.question_score} </Typography>
              </Box>
            }
          </Box>
           

        )}
      
      )}

    </Box>
  )
}

export default QuestionsAnswers