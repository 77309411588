import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { Style } from './Style'
import { TableCell , TableRow, Typography } from '@mui/material';
import Vals from '../../config/Vars';
import { API , dater } from '../../config/functions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Btn, DataTable, TableHeader } from '../../components';
import { useData } from '../../config/Provider';

const ParentGrades = ({nav}) => {

  const [ data , setData ] = useState([])
  const { lang , dir } = useData()
  const [evSelected, setEvSelected] = useState('today')

  const getData = async () => {
    await API.get( `/parent-view-grades/${evSelected}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const assessmentsTyps = [
    {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
    {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
    {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
    {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/dictonary.png'} , 
    {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/exam.png'} , 
  ]
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.template_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} {v.teacher_lname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.score?v.score:0} / {v.template_score} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.subject_title} {assessmentsTyps?.find((i) => i.value == v.template_type)?.title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {dater(v.aa_date)} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left' }} > 
          <Btn {...{ buttonTitle : <ArrowForwardIcon/> , func : () =>nav(`questions-answers/${v.template_id}`) } } /> 
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    getData()
  } , [evSelected])

  const filterTypes = [
    { title : 'Today' , value : 'today' } ,
    { title : 'Last Week' , value : 'lastweek' } ,
    { title : 'Last month' , value : 'lastmonth' } ,
    { title : 'Last 3 months' , value : 'last3months' } ,
    { title : 'Last 6 months' , value : 'last6months' } ,
    { title : 'All' , value : 'all' } ,
  ]
 
  const Header = [
    { title : lang?.exam_ } ,
    { title : lang?.teacher_ } ,
    { title : lang?.score_ } ,
    { title : lang?.subject_ } ,
    { title : lang?.date_ } ,
    { title : lang?.view_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.grades_  }} />
      {filterTypes?.map((item,key) => 
        <Box key={key} sx={[Style?.Tag , 
          { borderColor : evSelected === item.value  ? 'primary.orange' : 'primary.border' }
        ]} onClick={() => setEvSelected(item?.value) }>
          <Typography color='primary.text'> {item?.title} </Typography>
        </Box>
      )}
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default ParentGrades