import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader , Btn, TextInput, SelectClassroom} from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useData } from '../../config/Provider';


const Exams = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const [ classroom , setClassroom ] = useState(0)
  const [ template , setTemplate ] = useState(0)
  const { lang , dir } = useData()

  const page = () => {
    nav('/teacher-add-exam-template/')
  }

  const getData = async () => {
    await API.get( `/teacher-exams-template/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-teacher-exams-template/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const assIng = (e) => {
    setTemplate(e)
    setOpenDialog(true)
    console.log(template)
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.template_title} </TableCell> 
        <TableCell sx={{color:'primary.text',p:0,textAlign:dir==='rtl'?'right':'left'}} > 
            <IconButton onClick={() => assIng(v.template_id)} aria-label="edit">
              <ArrowForwardIcon sx={{color:'primary.orange'}}/>
            </IconButton>
        </TableCell> 
    
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton onClick={() => nav(`/questions/${v.template_id}`)} aria-label="edit">
            <PsychologyAltIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton onClick={() => nav(`/edit-exam-title/${v.template_id}`)} aria-label="edit">
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.title_ } ,
    { title : lang?.assign_ } ,
    { title : lang?.questions_ } ,
    { title : lang?.edit_ } ,
  ]

  const [ openDialog , setOpenDialog ] = useState(false)

  const assignClass = async () => {

    if ( classroom == 0 || template == 0 ) {
      return false
    }

    await API.post( `/assign-exam-question/`, { classroom_id : classroom , template_id : template } , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setOpenDialog(false)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  }
  return (
    <Box sx={Style?.Container}>
      <SelectClassroom {...{setOpen:setOpenDialog,open:openDialog,classroom , setClassroom,func:assignClass}}/>
      <TableHeader {...{ title:lang?.exam_templates_ , buttonTitle :lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <TextInput {...{set:setSearch,value:search,placeholder:lang?.search_}} />
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Exams