import { Avatar, Backdrop, Card, CircularProgress, Grid, IconButton, InputBase, ListItemButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Style } from './Style';
import { API, CalculateTime, HandleCreate64ForState } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
import { Btn, MediaDialog } from '../../components';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GetAppIcon from '@mui/icons-material/GetApp';

const ParentSchoolChat = () => {

    const [ msgs , setMsgs ] = useState([])
    const [ msg , setMsg ] = useState([])
    const [ file , setFile ] = useState(null)
    const [ show , setShow ] = useState(false)
    const [ loading , setLoading ] = useState(false)
    const chatContainerRef = useRef(null)
    const {socket,info,lang} = useData()
 
    const ChatText = ({item},key) => (
        <Box key={key} sx={[Style?.textMsgContainer , item?.chat_sender_id === info?.id ? Style?.myText : Style?.NotMyText ] } elevation={3}>
           <Box sx={[Style?.textMsgInnerContainer ,  item?.chat_sender_id === info?.id ? {borderBottomRightRadius : 0} : {borderBottomLeftRadius : 0} , { overflowWrap:'break-word' } ] } elevation={3}>
           {item?.chat_type === 'text' ? 
                    <>
                        <Typography sx={Style?.userCardInfoUsername}> {item?.chat_contnet} </Typography>
                        <Typography sx={Style?.userCardInfoDate}> {item?.date === lang?.now_ ? lang?.now_ :CalculateTime(item?.chate_date)} </Typography>
                    </>
                
                : 
                
                    <>
                        <Box sx={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            justifyContent:'space-between',
                            wordBreak:'break-word',
                            width:200 ,
                            cursor:'pointer'
                        }} 
                            onClick={() => handleDownload(item?.chat_contnet)} >
                            <Typography color='primary.text' > {item?.chat_contnet} </Typography>
                            <GetAppIcon sx={{fontSize:30,color:'primary.orange'}} />
                        </Box>
                    </>
                
                }
           </Box>
        </Box>
    )

    const handleDownload = (file) => {
        const downloadUrl = `${Vals?.M_API}/download/${file}`;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sendMsg = () => {
        if (typeof msg !== 'string' || !msg.trim()) {
            return false;
        }
        socket?.emit('SEmessage', { 
            msg : msg , 
            id : info?.id , 
            school : info?.school , 
            getter : info?.school ,
            chat_type : 'user'
        })
        setMsg('')
    }

    useEffect(() => {
 
    socket?.on('RCmessage', (req) => {
        if (req?.getter == info?.id || req?.chat_sender_id == info?.id) {
            setMsgs((e) => [
                ...e,
                {
                    chat_contnet: req?.chat_contnet,
                    chat_sender_id: req?.chat_sender_id,
                    chat_date: req?.chat_date,
                    chat_type: req?.chat_type,
                },
            ]);
            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        }
    });

    } , [])

    const handleClose = () => {
        setFile(null)
        setShow(false)
    }

    const Upload = async () => {
        var data = new FormData();
        data.append(`file` , file?.blob ,  file?.name )
        await API.post(`/upload-parent-file/`, data , Vals?.POST_UPLOAD_HEADER )
        .then(function (response) {
            
            console.log(response)

            if (response?.data?.status) {
                
                socket?.emit('SEmessageFile', { 
                    msg : response?.data?.file , 
                    id : info?.id , 
                    school : info?.school , 
                    getter : info?.school  ,
                    type : 'user' ,
                    chat_type : 'file' 
                })
        
                setFile(null)
                setShow(false)
                setLoading(false)

            } 


        }).catch(function (error) {
            console.log(error)
        });
    }

    const getData = async () => {
        await API.get( `/get-parent-school-msgs/` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setMsgs(response?.data?.data)
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        getData()
    } , [])

    const AlertBox = () => (
        <Box sx={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-around',
            wordBreak:'break-word',
            width:400
        }}>
            <Typography color='primary.text' > {file?.name} </Typography>
            <FileCopyIcon sx={{fontSize:50,color:'primary.text'}} />

        </Box>
    )

    const selectFileUpload = HandleCreate64ForState(setFile,setShow)
 
  return (
    <>

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <MediaDialog {...{
            open:show,
            setOpen:setShow,
            func:Upload,
            alertContent : <AlertBox /> ,
            handleClose : handleClose
        }} />
    
        <Box sx={[Style?.PageHeader ]} elevation={0} spacing={0}>
            <Typography sx={{fontSize:18,color:'primary.text'}}> {lang?.messenger_} </Typography>
            <Typography sx={{fontSize:30,color:'primary.text'}}> {lang?.chat_app_} </Typography>
        </Box>
   
        <Card sx={[Style?.Container ]} elevation={0} spacing={0}>
            
            <Grid container>
        
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    
                    <Box sx={Style?.ChatRightBar}>

                        <Box sx={Style?.ChatHeader}>
                            <Box sx={Style?.ChatUsernameInfo}>
                                <Typography sx={Style?.ChatUsername}> {lang?.school_} </Typography>
                            </Box> 
                        </Box>

                        <Box sx={Style?.ChatTexts}>
                            {msgs?.map((item , key) => ( <ChatText key={key} {...{item,key}} /> ))}
                            <div ref={chatContainerRef}></div>
                        </Box>

                        <Box sx={Style?.TypeInputSectionContainer}>
                    
                            <InputBase onChange={(e)=>setMsg(e.target.value)} value={msg} placeholder={`${lang?.type_}...`}  sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>
                            <IconButton aria-label="file"component="label" >
                                <input type='file' hidden={true}  onChange={ selectFileUpload }/>
                                <AttachFileIcon  sx={{color:"primary.text"}}/>
                            </IconButton> 
                            <IconButton aria-label="send" onClick={sendMsg}>
                                <SendOutlinedIcon  sx={{color:"primary.text"}}/>
                            </IconButton>      

                        </Box>

                    </Box>
                </Grid>
            </Grid>
        </Card>
    </>
  )
}

export default ParentSchoolChat