import { Avatar, Grid, ListItem, TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React , { useState , useEffect } from 'react'
import { DataTable, MultiLine, PieChart, TableHeader, TextInput } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'
import { useData } from '../../config/Provider'
import AlbumIcon from '@mui/icons-material/Album';
import DownloadIcon from '@mui/icons-material/Download';


const SchoolReport = () => {

  const [ search , setSearch ] = useState('')
  const [ title , setTitle ] = useState('')
  const [ select , setSelect ] = useState(0)
  const [ classroom , setClassroom ] = useState(0)
  const [ student , setStudent ] = useState(0)
  const [ studentName , setStudentName ] = useState('')
  const [ data , setData ] = useState([])
  const [ classroomData , setClassroomData ] = useState([])
  const { lang , dir } = useData()
  const Select = (classroom , title) => {
    setClassroom(classroom)
    setTitle(title)
  }

  const getSearch = async () => {
    await API.get( `/search-school-divisions/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getData = async () => {
    await API.get( `/school-divisions/` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroom = async ()  => {
    await API.get( `/school-students-classroom/${classroom}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (search?.length > 0 ) {
      getSearch()
    } else {
      getData()
    }
  }, [search?.length])

  useEffect(() => {
    if (classroom > 0) {
      getClassroom()
    }
  } , [classroom])
 
  const Tag = ({item}) => (
    <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
       onClick={() => Select(item?.division_id , `${item?.cr_title} / ${item?.division_title}`)} >
        <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
    </Box>
  )

  const ClassRoomData = () => {
    const addInfo = (id,name) => {
      setStudent(id)
      setStudentName(name)
    }
    const StudentCard = ({item}) => (
      <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3}>
        <ListItem sx={[Style?.StudentCard,{cruser:'pointer'}]} onClick={() => addInfo(item?.student_id,`${item?.student_fname} ${item?.student_lname}`)}  >
          <Avatar size="larg" sx={Style?.avatar}>{item?.student_fname?.substr(0,1)}</Avatar>
          <Box mt={2} sx={{justifyContent:'space-between',display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
            <Typography color='primary.text' variant='p'>{item?.student_fname} {item?.student_lname}</Typography>
          </Box>
        </ListItem>
      </Grid>
    )
    return (
      <>
        <TableHeader  {...{title:title, buttonIcon : '' , buttonTitle:lang?.back_ , page:() => setClassroom(0)}}/>
          <Grid container>
            {classroomData?.length > 0 ? classroomData?.map((item,k) => ( <StudentCard key={k} {...{item}} /> )) : <Typography color='primary.text'> {lang?.no_students_found_} </Typography>}
          </Grid>
      </>
    )
  }

  const StudentReport = () => {
    
    const [ chartDataArr , setChartDataArr ] = useState([])
    const [ studentFiles , setStudentFiles ] = useState([])
    const [ MedicalFiles , setMedicalFiles ] = useState([])

    const getChartData = async () => {
      await API.get( `/school-student-chart-data/${student}` , Vals?.GET_HEADER )
      .then( function ( response ) {
        if (response?.data?.status === true) {
          setChartDataArr(response?.data?.data)
          setStudentFiles(response?.data?.personalfiles)
          setMedicalFiles(response?.data?.medicalfiles)
        } else {
          console.log(response?.data?.msg)
        }
      })
      .catch((e) => console.log(e) )
    }
    useEffect(() => {
      getChartData()
    } , [student])

    const typesArr = [
      { title : lang?.on_task_ , color : '#007BA7' } ,
      { title : lang?.participating_ , color : '#50C878' } ,
      { title : lang?.cooperative_ , color : '#87CEEB' } ,
      { title : lang?.well_done_ , color : '#00FF00' } ,
      { title : lang?.respectful_ , color : '#40E0D0'  } ,
      { title : lang?.not_organized_ , color : '#E34234'  } ,
      { title : lang?.hard_to_stay_on_task_ , color : '#DC143C' } ,
      { title : lang?.uncooperative_ , color : '#8B0000' } ,
      { title : lang?.disruptive_ , color : '#FF4500' } ,
      { title : lang?.disrespectful_ , color : '#B22222' } ,
    ]

    const downloadFile = (file) => {
      const downloadUrl = `${Vals?.M_API}/download/${file}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const FileCard = ({item}) => (
      <Box sx={{
        height:'auto',
        width:'50%',
        borderWidth:1,
        borderColor:'primary.border',
        borderStyle:'solid',
        p:1,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        wordBreak:'break-word'
      }}>
        <Typography color='primary.text'> { item?.document_name }  </Typography>
        <DownloadIcon sx={{color:'primary.blue',fontSize:40,cursor:'pointer'}} onClick={() => downloadFile(item?.document_file_name)} />
      </Box>
    )

    const TableLoop = () => {

      const DataConvert = (e) => {
        const date = new Date(e);
        const formate = date.toISOString().slice(0, 10);
        return formate
      }

      return ( chartDataArr?.all?.map( ( v , k ) => (
        <TableRow key={k} sx={Style?.Row}> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} {v.teacher_lname} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {v.e_description} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {JSON.parse(v.e_types)?.map((item,key) => <Typography key={key} color='primary.text'> {key+1}-{item?.title} </Typography>)} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} >  {DataConvert(v?.e_date)} </TableCell> 
        </TableRow>
      )))
    } 
    const Header = [
      { title : lang?.teacher_ } ,
      { title : lang?.note_ } ,
      { title : lang?.evaluation_ } ,
      { title : lang?.date_ } ,
    ]

    return (
        <Box sx={{height:'auto'}}> 
            <TableHeader {...{title:studentName,buttonTitle:'back',page:()=>setStudent(0)}}/>
            <Box sx={{
              width:'100%',
              height:'100%',
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',alignItems:'center'
            }}>
              <Box sx={{height:'100%',width:'50%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Box sx={{
                  width:'50%'
                }}>
                  {typesArr?.map((item,key) => (
                    <Box key={key} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',mt:1}}>
                      <AlbumIcon sx={{color:item?.color,ml:2,mr:2}} />
                      <Typography color='primary.text'> { item?.title } </Typography> 
                    </Box>
                  ))}
                </Box>

              </Box>
              <Box sx={{height:'100%',width:'30%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <PieChart  info={chartDataArr}  />
              </Box>
            </Box>

            <Box sx={{width:'100%',mt:3,mb:3}}>
              <DataTable {...{ Header , Data : <TableLoop /> }} />
            </Box>


            <Grid container>
              <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                {studentFiles?.length>0&&(
                  <Typography sx={{color:'primary.text'}}> {lang?.personal_decumants_} </Typography>
                )}
                {studentFiles?.map((item,key) => <FileCard key={key} {...{item}} /> )}
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              {MedicalFiles?.length>0&&(
                  <Typography sx={{color:'primary.text'}}> {lang?.medical_decumants_} </Typography>
              )}
              {MedicalFiles?.map((item,key) => <FileCard key={key} {...{item}} />)}
              </Grid>
            </Grid>
            <Box sx={{height:100,width:'100%'}} />
        </Box>
    )
  }
   
  return (
    <Box sx={Style?.Container}>
      <Box sx={Style?.ItemContainer}>

        {classroom === 0 ? 
          <>
            <TextInput {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
            <Box mt={3}>
              {data?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
            </Box>
          </>
        : 
          student == 0 ? <ClassRoomData/> : <StudentReport />
        }
        
      </Box>
    </Box>
  )
}

export default SchoolReport