import { Backdrop, Button, CircularProgress, IconButton, InputBase, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { AlertsDialog, Btn, GenerateExcelFile, TableHeader } from '../../components'
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, validateEmail } from '../../config/functions';
import { useParams } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useData } from '../../config/Provider';
import * as XLSX from 'xlsx'; 
import { saveAs } from 'file-saver';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CompareSharp } from '@mui/icons-material';

const EditDivision = ({nav}) => {
  const [ title , setTitle ] = useState('')
  const [ count , setCount ] = useState()
  const [ classrooms , setClassrooms ] = useState([])
  const [ classroom , setClassroom ] = useState('empty')
  const [ showAlert , setShowAlert ] = useState(false)
  const [fileInputKey, setFileInputKey] = useState(0);
  const [isNumber, setIsNumber] = useState(false);
  const { id } = useParams()
  const { lang  } = useData()
  const [loading , setLoading ] = useState(true)

  const headerTitles = [ lang?.email_ , lang?.first_name_ , lang?.last_name_ , lang?.mobile_ , lang?.number_  ];  

  const page = async () => {
    setLoading(true)
    await API.post( `/save-division/` , { id:id , title : title , classroom_id : classroom  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          nav('/divisions/')
        } else {
            console.log(response?.data?.msg)
        }
        setLoading(false)
      })
    .catch((e) => console.log(e) )
  }

  const getClassrooms = async () => {
    setLoading(true)
    await API.get( `/school-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setClassrooms(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
        setLoading(false)

      })
    .catch((e) => console.log(e) )
  }

  const getData = async () => {
        setLoading(true)
        await API.get( `/get-division/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setClassroom(response?.data?.data?.cr_id)
          setTitle(response?.data?.data?.division_title)
        } else {
          console.log(response?.data?.msg)
        }
        setLoading(false)
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() =>{
    getClassrooms()
    getData()
  } , [])

  const handleChange = (event) => {
    setClassroom(event.target.value)
  };

  const importData = async ( data ) => {
    setLoading(true)
 
    await API.post( `excel-import` , { id : id , data : data } , Vals?.POST_HEADER )
    .then(res => {
      if ( res?.data?.status ) {
        setLoading(false)

        nav('/divisions/')
      } else {
      }
    })
    .catch(e => console.log(e) )
  }

  const handleFileUpload = (event) => {
    
    const file = event.target.files[0];

    setFileInputKey(prevKey => prevKey + 1);
  
    const reader = new FileReader();
  
    reader.onload = async (e) => {
   
      const dataBuffer = e.target.result;
      const workbook = XLSX.read(dataBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: headerTitles });
      const data = excelData.splice(1);
  
      if ( data?.length <= 40 && data?.length > 1 ) {
        setIsNumber(false)
        let shouldImport = true;
  
        data?.forEach(item => {
          const valuesArray = Object.values(item);
          if (!validateEmail(valuesArray[0])) {
            shouldImport = false;  
          }
        });
    
        if (!shouldImport) {
          setShowAlert(true);
          return; 
        } else {
          importData(data);
        }

      } else {
        setIsNumber(true)
      }
      
    }
  
    reader.readAsArrayBuffer( file )

  }
  
  const AlertContent = () => (
    <Typography sx={{fontSize:18,color:'primary.text'}}> {lang?.one_or_more_email_is_wrong_please_check_the_execl_file_and_try_again_} </Typography>
  )


  return (
    <Box p={2} sx={Style?.Container}>

      <Backdrop sx={{ color: 'primary.orange', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>

      <AlertsDialog {...{
        open:showAlert,
        setOpen:setShowAlert,
        alertText : <AlertContent />
      }} />
    
      <TableHeader {...{ title:lang?.edit_division_ , buttonTitle : lang?.save_ , buttonIcon : <CheckRoundedIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={title} onChange={(e) => setTitle(e.target.value)} placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <Select labelId="label" id="label" label="Classroom" value={classroom} sx={Style?.Selector} placeholder={lang?.classroom_} onChange={handleChange}>
              <MenuItem value='empty' selected>{lang?.select_classroom_}</MenuItem>
              { classrooms?.map((v,k) => ( <MenuItem key={k} value={v.cr_id}>{v.cr_title}</MenuItem> ))}
            </Select>


            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start',mt:5}}>
              <Typography  sx={{fontSize:18,color:'primary.text',mt:2}} > { lang?.import_students_ } </Typography>
              <GenerateExcelFile {...{headerTitles:headerTitles}} />
              <Typography sx={{fontSize:18,color:'primary.text',mt:2}}> {lang?.insert_all_students_data_in_the_file_} </Typography>
              <Typography sx={{fontSize:13,color:!isNumber ? 'primary.text' : 'red' ,mt:0}}> ( {lang?.maximum_number_on_one_file_is_number_students_only_} ) </Typography>
              {/* <Btn {...{func:handleFileUpload,buttonTitle:lang?.upload_}} /> */}

              <IconButton color="white" sx={Style?.iconBtn} aria-label="upload" component="label">
                <input  onChange={handleFileUpload}  hidden accept=".xlsx" type="file" id='uploadExecl' key={fileInputKey}/>
                {lang?.upload_}
              </IconButton>
            </Box>


        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditDivision