export const Style = {
    inputsContainer : {
        display:'flex',
        flexDirection:'column',
        width:'100%',
    } ,
    OptionContainer : {
        display:'flex',
        flexDirection:'column',
    } , 
    OptionCard : {
        width:'70%',
        backgroundColor:'primary.main',
        p:2,
        display:'flex',
        flexDirection:'column',
        height:200,
        mt:5,
        borderRadius:2,
        cursor:'pointer'
    } , 

    OptionCardContainer :{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        mt:0
    } , 
    CardMedia : {
        width:"45%",
        height:"auto",
        mr:'auto',
        ml:'auto',
        pt:'0px',
        pb:'0px'
    },
    CreateBtn:{
        backgroundColor:'primary.blue',
        color:'primary.text',
        '&:hover':{
            color:'primary.text',
            backgroundColor:'primary.orange'
        }
    } ,
    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2
    } ,
}