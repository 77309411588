export const Style = {
  Container : {
    mr : 2 ,
    ml : 2 ,
    mt : 2 ,
    p:2,
    backgroundColor:'white',
    border:'2px solid #e4e6e5',
    borderRadius:2
} ,
    ItemContainer : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      borderRadius:2,
      padding:2,
      height:'auto'
    },
    StudentCard : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      borderRadius:2,
      padding:2,
      height:'auto',
      justifyContnet:'space-between',
      display:'flex',
      alignItems:'center',
      flexDirection:'column',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer'
      },
      margin:2,
      width:'90%'
    } ,
    avatar : {
      height:80,
      width:80,
      bgcolor: 'primary.orange'
    } ,
    Tag : {
      borderRadius:2,
      pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
      float:'left',
      alignItem:'canter',
      display:'flex',
      cursor:'pointer',
      borderWidth:0,
      borderWidth:1,
      borderStyle:'solid',
    } ,
    Input : {
      input : {color:'primary.text' , },
      borderWidth:1,
      borderStyle:'solid',
      borderColor:'primary.border',
      backgroundColor:'primary.main' , 
      '&:hover' : {  backgroundColor : 'primary.main'},
      width:'100%' ,
      padding:1,
      mt:2
  } , 
  optionTag:{
    display:'flex',
    pt:1,
    pb:1,
    pr:1,
    pl:2,
    justifyContent:'space-between',
    alignItems:'center',
    borderColor:'primary.border',
    float:'left',
    ml:3,
    mr:3,
    borderWidth:1,
    borderStyle:'solid' ,
    borderRadius:2
  }
  }