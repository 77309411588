export const Style = {
    
    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2,
        float:'left',
        width:'95%'
    } ,

    InnerContainer : {

    } ,

    Row : {         
    "& td": { 
        borderColor:'primary.border'
    },
    transition:'0.2s', 
    cursor : 'pointer' , 
    borderColor:'red' ,
    '&:hover' : { 
            backgroundColor : 'primary.main'
        }
    } ,

    Tag : {
        borderRadius:15,
        pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
        float:'left',
        alignItem:'canter',
        display:'flex',
        cursor:'pointer',
        borderWidth:0,
        borderWidth:1,
        borderStyle:'solid',
    } ,

    TagInnerContainer : {
        float:'left',
        mt:1 ,
        mb:1 , 
        pt : 2
    } , 

    TagContainer : {
        width:'100%' ,
        p:2,
        float:'left',
        height:'auto',
    }

}