import { InputBase, MenuItem, Select  } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, validateEmail } from '../../config/functions';
import { useEffect } from 'react';
import { useData } from '../../config/Provider';

const AddGuidance = ({nav}) => {

  const [ email , setEmail ] = useState('')
  const [ emailValid , setEmailValid ] = useState(true)
  const [ password , setPassword ] = useState('')
  const [ fname , setFname ] = useState('')
  const [ lname , setlname ] = useState('')
  const { lang } = useData()
 
  const page = async () => {

    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }

    await API.post( `/add-guidance/` , { fname : fname , lname : lname , email : email , password : password  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/guidances/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  return (
    <Box p={2} sx={Style?.Container}>
      <TableHeader {...{ title:lang?.add_guidance_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
            <InputBase value={password} onChange={(e) => setPassword(e.target.value)} placeholder={lang?.password_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={fname} onChange={(e) => setFname(e.target.value)} placeholder={lang?.first_name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={lname} onChange={(e) => setlname(e.target.value)} placeholder={lang?.last_name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default AddGuidance