import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader , Btn} from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const SchoolStudents = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const { lang , dir } = useData()
  const page = () => {
    nav('add-student')
  }

  const getData = async () => {
    await API.get( `/dismissed-students/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-dismiss-students/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const remove = async (id) => {
    await API.post( `/undismiss-student/` , { id : id  } ,Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
              const a = data.filter(student => student.student_id !== id);
              setData(a)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.student_fname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.student_lname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.user_email} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.student_mobile} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.student_number} </TableCell> 
        <TableCell sx={{color:'primary.text',p:0,textAlign:dir==='rtl'?'right':'left'}} > 
          {v.student_parent_id == 0 ? 
            <Btn {...{buttonTitle:'not found' , func : () => nav(`/add-parent/${v.student_id}`) }}/> : 
            <Btn {...{buttonTitle:'info' , func :() => nav(`/edit-parent/${v.student_parent_id}`)  }}/>} 
        </TableCell> 
        <TableCell sx={{color:'primary.text',p:0,textAlign:dir==='rtl'?'right':'left'}} > 
          <Btn {...{buttonTitle:'UnDismiss' , func : () => remove(v.student_id) }}/>
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.first_name_ } ,
    { title : lang?.last_name_ } ,
    { title : lang?.email_ } ,
    { title : lang?.number_ } ,
    { title : lang?.mobile_ } ,
    { title : lang?.parent_ } ,
    { title : lang?.undismiss_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.dismissed_students_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase value={search} onChange={(e) => setSearch(e.target.value)}  placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default SchoolStudents