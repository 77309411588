import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { AlertsDialog, DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField, Typography } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const SchoolSubjects = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const [ deleteAlert , setDeleteAlert ] = useState(false)
  
  
  const {lang,dir} = useData()

  const page = () => {
    nav('add-subject')
  }

  const getData = async () => {
    await API.get( `/school-subjects/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-subject/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const deleteSubject = async (id) => {
    await API.post( `/delete-subject/` , { id : id } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if ( response?.data?.msg === 'teacher_tied' ) {
            setDeleteAlert(true)
        }

        if ( response?.data?.msg === 'delete' ) {
          const newData = data?.filter( ( i ) => i.subject_id != id )
          setData(newData)
        }
      console.log(response?.data?.msg)
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={[Style?.Row,{}]}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.subject_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/edit-subject/${v.subject_id}`)}>
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton aria-label="delete" onClick={() => deleteSubject(v.subject_id)}>
            <DeleteTwoToneIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])

  const DeleteText = () => (
    <Typography sx={{color:'primary.text'}} > {lang?.delete_subject_alert_text_teacher_} </Typography>
  )
 
  const Header = [
    { title : lang?.subject_ } ,
    { title : lang?.edit_ } ,
    { title : lang?.delete_ } ,
  ]
  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{
        open:deleteAlert,
        setOpen:setDeleteAlert,
        alertText : <DeleteText/>
      }}/>
      <TableHeader {...{ title:lang?.subjects_  , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default SchoolSubjects