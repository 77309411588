 

import { Avatar, Card, CardMedia, Grid, IconButton,  Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Style } from './Style'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import BedroomChildTwoToneIcon from '@mui/icons-material/BedroomChildTwoTone';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
import { CalendarCurrentDate, ChartDoughnut, LineChart, MultiLine, OuterCalendar } from '../../components';
import ChartBar from '../../components/charts/bar/ChartBar';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Guidance = ({nav}) => {

  const [ data , setData ] = useState([])
  const {lang}=  useData()
  const getData = async () => {
    await API.get( `/guidance-home/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } 
    })
    .catch((e) => console.log(e) )
  }

  const Data = [
    { title :lang?.timetable_ , num : '' , icon : <CalendarMonthIcon sx={Style?.icon} /> , nav : '/timetable/'  , color:'#2086d1'} ,
    { title : lang?.reports_ , num : data?.students?.students_num , icon : <PeopleAltOutlinedIcon sx={Style?.icon} />  , color:'#49aaf7', nav:'/reports/'} ,
    { title : lang?.grades_ , num : data?.divisions?.divisions_num , icon : <LibraryBooksIcon sx={Style?.icon} />  , color:'#18d384', nav:'/student-grades/'} ,
    { title : lang?.teachers_ , num : data?.teachers?.teachers_num , icon : <PermIdentityOutlinedIcon sx={Style?.icon} />  , color:'#d07048', nav:'/teachers/'} ,
  ]

  useEffect(() => {
    getData()
  },[])

  const students = [
    {name:'Ahmad'},
    {name:'Muhammad'},
    {name:'Naura'},
    {name:'Salem'},
    {name:'Fadi'},
    {name:'Abdul Rahman'},
    {name:'Salim'},
    {name:'Khaled'},
    {name:'Adnan'},
  ]

  return (
    <Box sx={Style?.Container}>
      <Grid container spacing={2} >

        {Data?.map((v,k) => (
          <Grid key={k} item xs={12} sm={12} md={4} lg={3} xl={3}> 
            <Card elevation={0} sx={[Style?.InnerContainer , {backgroundColor:v?.color,cursor:'pointer',}]} onClick={() => nav(v.nav)}> 
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between', alignItems:'center' }}>
                  {/* <Typography sx={{fontSize:28,color:'white',fontWeight:'bold'}} > {v.num} </Typography> */}
                  <Typography variant='p' sx={{fontSize:25,color:'white'}} > {v.title} </Typography>
              </Box>
              <Box sx={{ display:'flex' , justifyContent:'flex-end' , alignItems:'center' , flexDirection:'column'}}>
                {v.icon}
              </Box>
            </Card>
          </Grid>
        ))}

      </Grid>

      <Box sx={Style?.sideContainerMain}>
        
        <Box sx={Style?.sideContainer}>
          
          <Grid container spacing={2} >
          
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={Style?.WhiteContainer}>
                  <Typography fontWeight={'bold'} fontSize={20} mb={2} color='primary.text'> {lang?.school_event_calender_} </Typography>
                  <OuterCalendar url={`view-calendar-guidance`} />
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 0 } ]}>
                  <CalendarCurrentDate />
                </Box>
              </Card>
            </Grid>
            {data?.announcements?.length>0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 

                <Box sx={Style?.BGCon}>

                  <Box sx={Style?.bgInnerCon}>

                      <Typography fontWeight={'bold'} fontSize={35} mb={2}  color='white'> {lang?.announcements_} </Typography>

                      {data?.announcements?.map((item , key) => (
                        <Box sx={Style?.announcementsCon} key={key}>
                          <Typography fontSize={19} color='primary.text'> {item?.a_title} </Typography>
                          <Typography fontSize={15} color='primary.text'> {CalculateTime(item?.a_date)} </Typography>
                        </Box>
                      ))}

                  </Box>
                
                </Box>

              </Grid>
            )}


          </Grid>

        </Box>

        <Box sx={Style?.sideContainer}>

          <Grid container spacing={2} >

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={Style?.WhiteContainer}>
                  <Typography fontWeight={'bold'} fontSize={20} mb={2} color='primary.text'> {lang?.pie_chart_} </Typography>
                  <Box sx={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around'}}>

                    <Box sx={{width:'20%',textAlign:'center'}}>
                      <ChartDoughnut {...{
                        colorA : '#ede9ea' , 
                        colorB : '#f14e53' , 
                        sets : [ 19 , 81 ] ,
                        height:50, width:50 , title : '81%' }} />
                        <Typography fontWeight={'bold'} fontSize={16} mt={2} color='primary.text'> {lang?.active_students_} </Typography>
                    </Box>

                    <Box sx={{width:'20%',textAlign:'center'}}>
                      <ChartDoughnut {...{
                        colorA : '#ede9ea' , 
                        colorB : '#2bc156', 
                        sets : [ 78 , 22 ] ,
                        height:50, width:50 , title : '22%' }} />
                        <Typography fontWeight={'bold'} fontSize={16} mt={2} color='primary.text'> {lang?.inactive_students_} </Typography>
                    </Box>

                    <Box sx={{width:'20%',textAlign:'center'}}>
                      <ChartDoughnut {...{
                        colorA : '#ede9ea' , 
                        colorB : '#063db4', 
                        sets : [ 38 , 62 ] ,
                        height:50, width:50 , title : '62%' }} />
                        <Typography fontWeight={'bold'} fontSize={16} mt={2} color='primary.text'> {lang?.teachers_} </Typography>
                    </Box>

                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 

              <Box sx={Style?.sideContainerMain}>
                
                <Box sx={[Style?.sideContainer,{mt:0}]}>

                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
                      <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                        <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 2 } ]}>
                          <Typography fontWeight={'bold'} fontSize={18} mb={2} color='primary.text'> {lang?.admission_summary_} </Typography>
                          <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'flex-start'}}>
                              <Typography fontWeight={'bold'} fontSize={25} mb={1} color='primary.text'> 4,563 </Typography>
                              <Typography fontWeight={'bold'} fontSize={12}  color='#969696'> <span style={{color:'#79be8f'}}>1,6%</span> then last week </Typography>
                            </Box>
                            <CallReceivedIcon sx={{backgroundColor:'#f84c4a',color:'white',borderRadius:100,fontSize:35,p:1}} />
                          </Box>
                          <ChartBar />
                        </Box>
                      </Card>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
                      <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                        <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 2 } ]}>
                          <Typography fontWeight={'bold'} fontSize={18}  color='primary.text'> {lang?.learn_video_} </Typography>
                          <Box sx={{
                            width:'100%',
                            height:200,
                            backgroundColor:'#c1c1c1',
                            mt:1,borderRadius:5 
                          }}>
                          <iframe width="100%" height="100%" src={data?.video} frameBorder="0" allowFullScreen></iframe>

                          </Box>
                          <Typography fontWeight={'bold'} fontSize={18} mt={2} color='primary.text'> {lang?.how_to_use_classenda_platform_} </Typography>
                          <Box sx={{
                             display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center' ,mt:2 
                          }}>

                          <Typography fontWeight={'bold'} fontSize={18}  color='#da7289'> {lang?.all_videos_} </Typography>
                          <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center' }}>
                            <BarChartIcon sx={{color:'#da7289',fontSize:18}} />
                            <Typography fontWeight={'bold'} fontSize={18}  color='primary.text'> 6,452 </Typography>
                          </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>

                </Box>
                <Box sx={[Style?.sideContainer,{mt:0}]}>
                  <Grid container spacing={2} >

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
                      <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                        <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 2 } ]}>
                          <Typography fontWeight={'bold'} fontSize={18} mb={2} color='primary.text'> {lang?.recent_students_} </Typography>
                          <Typography fontWeight={'bold'} fontSize={15} mb={2} color='#a8a8a8'> New Students </Typography>
                          {students?.map((item,key) => (
                            <Box sx={Style?.StudentCard} key={key}>
                              <Avatar sx={{height:60,width:60,borderRadius:5}} variant='rounded' />
                              <Box ml={1} sx={Style?.StudentCardInfo}>
                                <Typography fontWeight={'bold'} fontSize={18} color='primary.text'> 
                                   {item?.name}
                                </Typography>
                                <Typography fontSize={13} color='#a8a8a8'> 
                                  v1-m2
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                          <Box sx={{width:'100%',textAlign:'center',backgroundColor:'#fee5e9',mt:3,borderRadius:5,cursor:'pointer'}}>
                            <Typography fontSize={18} fontWeight={'bold'} color='#e36783' m={2}> {lang?.view_more_} </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
         
                  </Grid>
                </Box>
              </Box>
            </Grid>

          </Grid>

        </Box>

      </Box>

      <Box sx={{height:100}} />

    </Box>
  )
}

export default Guidance