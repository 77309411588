import { Avatar, Grid, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React , { useState , useEffect } from 'react'
import { TableHeader, TextInput } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'
import { useData } from '../../config/Provider'

const Classrooms = () => {

  const [ search  , setSearch ] = useState('')
  const [ studentSearch  , setStudentSearch ] = useState('')
  const [ title , setTitle ] = useState('')
  const [ select , setSelect ] = useState(0)
  const [ classroom , setClassroom ] = useState(0)
  const [ data , setData ] = useState([])
  const [ classroomData , setClassroomData ] = useState([])
  const {lang} = useData()
  const Select = (classroom , title) => {
    setClassroom(classroom)
    setTitle(title)
  }

  const getSearch = async () => {
    await API.get( `/teacher-search-classrooms/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getData = async () => {
    await API.get( `/teacher-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroom = async ()  => {
    await API.get( `/teacher-classroom/${classroom}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroomSearch = async ()  => {
    await API.get( `/teacher-classroom-search/${classroom}/${studentSearch}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (classroom > 0) {
      if (studentSearch?.length > 0 ) {
        getClassroomSearch()
      } else {
        getClassroom()
      }
    }
  } , [classroom , studentSearch])

  useEffect(() => {
    if (search?.length > 0 ) {
      getSearch()
    } else {
      getData()
    }
  }, [search?.length])
 
  const Tag = ({item}) => (
    <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
       onClick={() => Select(item?.division_id , `${item?.cr_title} / ${item?.division_title}`)} >
        <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
    </Box>
  )

  const StudentCard = ({item}) => (
    <Grid item={true} xs={12} sm={12} md={4} lg={3} xl={3} justifyContent={'center'} alignItems={'center'} display={'flex'}>
      <ListItem sx={[Style?.StudentCard,{cruser:'pointer'}]} >
        <Avatar size="larg" sx={Style?.avatar}>{item?.student_fname?.substr(0,1)}</Avatar>
        <Box mt={2} sx={{justifyContent:'center',display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
          <Typography color='primary.text' variant='p'>{item?.student_fname} {item?.student_lname}</Typography>
        </Box>
      </ListItem>
    </Grid>
  )

  return (
    <Box sx={Style?.Container}>
      <Box sx={Style?.ItemContainer}>

        {classroom === 0 ? 
          <>
            <TextInput {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
            <Box mt={3}>
              {data?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
            </Box>
          </>
        : 
          <Grid container>
            <TableHeader {...{ title : `${title}` , buttonTitle : lang?.back_ , page : () => setClassroom(0) } } />
            <TextInput  {...{ value : studentSearch , set : setStudentSearch , placeholder : lang?.search_ , type : 'text' } } />
            { classroomData?.map(( item , k ) => <StudentCard key={k} {...{item}} /> ) } 
          </Grid>
        }
        
      </Box>
    </Box>
  )
}

export default Classrooms