import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { AlertsDialog, DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';


const GuidancePersonalDocs = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const { lang , dir } = useData()
 
  const getData = async () => {
    await API.get( `/guidance-personal-docs/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-guidance-personal-docs/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const handleDownload = (file) => {
    const downloadUrl = `${Vals?.M_API}/download/${file}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.document_name} </TableCell>
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.document_guidance_comment} </TableCell>
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={()=>nav(`edit-docs/${v.doc_id}/student-docs`)} >
            <EditIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="download" onClick={()=>handleDownload(v.document_file_name)} >
            <DownloadIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
   
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.title_ } ,
    { title : lang?.comment_ } ,
    { title : lang?.comment_ } ,
    { title : lang?.download_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.students_documents_   }} />
      <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default GuidancePersonalDocs