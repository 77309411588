import { InputBase, Typography  } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect }  from 'react'
import { AlertsDialog, TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API , validateEmail } from '../../config/functions';
import { useData } from '../../config/Provider';

const TextInput = ({item}) => (
    <InputBase 
        value={item?.value} 
        onChange={(e) => item?.set(e.target.value)} 
        placeholder={item?.placeholder} 
        sx={Style?.Input} 
        variant="outlined" 
        autoComplete='off'
    />
)

const StudentProfile = ({nav}) => {
  const [ email , setEmail ] = useState('')
  const [ emailValid , setEmailValid ] = useState(true)
  const [ isParent , setIsParent ] = useState(false)
  const [ name , setName ] = useState('')
  const [ relation , setRelation ] = useState('')
  const [ occupation , setOccupation ] = useState('')
  const [ password , setPassword ] = useState('')
  const [ alertDialog , setAlertDialog ] = useState(false)
  const {lang} = useData()
 
  const inputs = [
    { value : name , set : setName , type : 'text', placeholder : lang?.name_ } ,
    { value : relation , set : setRelation , type : 'text', placeholder : lang?.relation_ } ,
    { value : occupation , set : setOccupation , type : 'text', placeholder : lang?.occupation_ } ,
    { value : password , set : setPassword , type : 'password', placeholder : lang?.password_ } ,
  ]

  const page = async () => {

    if (!password.trim() || !name.trim()) {
        return false
    }

    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }

    const data = {
        email : email ,
        name : name ,
        occupation : occupation ,
        password : password ,
        relation : relation
    }

    await API.post( `add-parent-from-student` , data , Vals?.POST_HEADER)
    .then(res => {
        if ( res?.data?.status ) {
            setAlertDialog(true)
            setIsParent(true)
        }
    })
    .catch(e => console.log(e))
 
  }

    const check = async () => {
        await API.get( `check-parent` , Vals?.GET_HEADER)
        .then(res => setIsParent(res?.data?.status))
        .catch(e => console.log(e))
    }
 
    useEffect(() => {
        check()
    } , [])
    
    const AlertContent = () => (
        <Typography sx={{color:'primary.text',}}>{lang?.account_added_successfully_}</Typography>
    )
    return (
        <>
        <AlertsDialog {...{
            open:alertDialog,
            setOpen:setAlertDialog,
            alertText:<AlertContent/>
        }} />
            {!isParent && (
                <Box sx={Style?.Container}>
                    <>
                        <TableHeader {...{ title:lang?.add_parent_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
                        <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
                            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
                                <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
                                {inputs?.map((item,key) => <TextInput key={key} {...{item}} /> )}
                            </Box>
                            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
                            </Box>
                        </Box>
                    </>
                </Box>
            )}
        
        </>
    )
}

export default StudentProfile