import { ThemeProvider } from "@mui/material";
import React ,{ createContext, useState, useContext, useEffect } from "react";
import { io } from "socket.io-client";
import { Light , Dark } from "../style/ThemeStyle";
import { API, useLocalState } from "./functions";
import Vals from "./Vars";
export const Prv = createContext();

export const useData = () => {
    return useContext(Prv);
}
 
const Provider = ({children}) => {
    
    const [ logged , setlogged ] = useState(false);
    const [ dir , setDir ] = useState('');
    const [ triggerLang , setTriggerLang ] = useState(false);
    const [ theme, setTheme ] = useState(false);
    const [ langDiloag , setLangDiloag ] = useState(false);
    const [ mobileNav , setMobileNav ] = useState(false);
    const [ info, setInfo ] = useState({});
    const [ showSidebar, setShowSidebar ] = useState(true);
    const [ page, setPage ] = useState('');
    const [ userType, setUserType ] = useState('');
    const [ lang, setLang ] = useState([]);
    const [ langKey , setLangKey ] = useState(0);
    const [ Islogged  ] = useLocalState("Islogged" );
    const [ savedtheme , _ ] = useLocalState("theme" , false);
    const [ savedlangKey , setSavedLangKey ] = useLocalState("langKey" );
    const [load , setload] = useState(false)
    const [loading , setloading] = useState(true)
    const [popup , setPopup] = useState({ show : false , id  : 0 })
    const [userInfo , setUserInfo] = useState([])
    const [socket, setSocket] = useState(null)

    const getLang = async () => {
      try {
        const response = await API.get(`/lang-content/${langKey}`, Vals?.GET_HEADER);
        if (response?.data?.status === true) {
          const transformedArray = response?.data?.data.reduce((acc, item) => {
            acc[item?.lk_key] = item?.lv_value !== null ? item?.lv_value : item?.lk_key;
            return acc;
          }, {});
          setLang(transformedArray);
          setDir(response?.data?.dir);
          setloading(false);
        } else {
          console.log(response?.data?.msg);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const SetSettings = async () => {
        if (savedlangKey == 0 || !savedlangKey) {
            setLangKey(14)
            setSavedLangKey(14)
        } else {
            setLangKey(savedlangKey)
        }
        setTheme(true)
        setlogged(Islogged)
    }

    useEffect(() => {
        SetSettings()
    } , [])

    useEffect(() => {
        getLang()
    }, [langKey])

    if (!loading )
    return (
        <Prv.Provider value={{
                mobileNav : mobileNav , 
                setMobileNav : setMobileNav , 
                userInfo : userInfo , 
                setUserInfo : setUserInfo ,
                popup : popup , 
                setPopup : setPopup ,
                dir : dir , 
                setDir : setDir ,
                langKey : langKey , 
                setLangKey : setLangKey , 
                triggerLang : triggerLang , 
                setTriggerLang : setTriggerLang ,
                langDiloag : langDiloag , 
                setLangDiloag : setLangDiloag , 
                info:info , 
                setInfo:setInfo ,
                theme : theme ,
                load : load , setload : setload ,
                userType : userType , 
                setUserType : setUserType ,
                page : page , 
                logged : logged , 
                setlogged : setlogged ,
                setPage : setPage ,  
                setTheme : setTheme ,
                showSidebar : showSidebar ,
                setShowSidebar : setShowSidebar ,
                lang : lang ,  
                setLang : setLang ,
                socket : socket, 
                setSocket : setSocket
            }} >
                <ThemeProvider theme={theme?Light:Dark}>
                  <div style={{direction:dir}} dir={dir}>
                    {children}
                  </div>
                </ThemeProvider> 
        </Prv.Provider>
    );

}

export default Provider;