 

import { Avatar, Card, CardMedia, Grid, IconButton,  Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Style } from './Style'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import BedroomChildTwoToneIcon from '@mui/icons-material/BedroomChildTwoTone';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
import { CalendarCurrentDate, ChartDoughnut, LineChart, MultiLine, OuterCalendar } from '../../components';
import ChartBar from '../../components/charts/bar/ChartBar';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import BarChartIcon from '@mui/icons-material/BarChart';
import SmsIcon from '@mui/icons-material/Sms';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const Student = ({nav}) => {

  const [ data , setData ] = useState([])
  const {lang}=  useData()
  const getData = async () => {
    await API.get( `/student-home/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } 
    })
    .catch((e) => console.log(e) )
  }

  const Data = [
    { title :lang?.grades_ , num : '' , icon : <CreditScoreIcon sx={Style?.icon} />, nav : '/student-grades/' , color:'#18d384' } ,
    { title :lang?.timetable_ , num : '' , icon : <CalendarMonthIcon sx={Style?.icon} /> , nav : '/timetable/'  , color:'#d07048'} ,
    { title :lang?.assessments_ , num : '' , icon : <DescriptionIcon sx={Style?.icon} /> , nav : '/assessments/', color:'#2086d1'} ,
    { title :lang?.chat_ , num : '' , icon : <SmsIcon sx={Style?.icon} /> , nav : '/chat/', color:'#49aaf7'} ,
  ]

  useEffect(() => {
    getData()
  },[])

  const ChatItem = ({item}) => (
    <Box sx={Style?.ChatItem} onClick={() => nav(`/questions/${item?.aq_template_id}`)} >
      <Typography color="primary.text" >{item?.template_title}</Typography>
      <Box  sx={{textAlign:'left'}}>
      <Typography color="primary.text" > </Typography>
      </Box>
      <Typography color="primary.text" >{CalculateTime(item?.aq_date)}</Typography>
    </Box>
  )
 
  return (
    <Box sx={Style?.Container}>

      <Grid container spacing={2} >

        {Data?.map((v,k) => (
          <Grid key={k} item xs={12} sm={12} md={4} lg={3} xl={3}> 
            <Card elevation={0} sx={[Style?.InnerContainer , {backgroundColor:v?.color,cursor:'pointer'}]} onClick={() => nav(v?.nav)}> 
              <Typography variant='p' sx={{fontSize:25,color:'white'}} > {v.title} </Typography>
              {v.icon}
            </Card>
          </Grid>
        ))}

      </Grid>

      <Box sx={Style?.sideContainerMain}>
        
        <Box sx={Style?.sideContainer}>
          
          <Grid container spacing={2} >
          
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={Style?.WhiteContainer}>
                  <Typography fontWeight={'bold'} fontSize={20} mb={2} color='primary.text'> {lang?.school_event_calender_} </Typography>
                  <OuterCalendar url={`view-calendar-student`} />
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 0 } ]}>
                  <CalendarCurrentDate />
                </Box>
              </Card>
            </Grid>

            {data?.announcements?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> 

                <Box sx={Style?.BGCon}>

                  <Box sx={Style?.bgInnerCon}>

                      <Typography fontWeight={'bold'} fontSize={35} mb={2}  color='white'> {lang?.announcements_} </Typography>

                      {data?.announcements?.map((item , key) => (
                        <Box sx={Style?.announcementsCon} key={key}>
                          <Typography fontSize={19} color='primary.text'> {item?.a_title} </Typography>
                          <Typography fontSize={15} color='primary.text'> {CalculateTime(item?.a_date)} </Typography>
                        </Box>
                      ))}

                  </Box>
                
                </Box>

              </Grid>
            )}


          </Grid>

        </Box>

        <Box sx={Style?.sideContainer}>

          <Grid container spacing={2} >

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={Style?.WhiteContainer}>
                  <Typography fontWeight={'bold'} fontSize={20} mb={2} color='primary.text'> {lang?.homeworks_} </Typography>
                  <Box sx={{ height:'auto', width:'100%' , flexDirection:'column' }}>
                    {data?.homeworks?.map((item) => (<ChatItem {...{item}}/>))}
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
              <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                <Box sx={Style?.WhiteContainer}>
                  <Typography fontWeight={'bold'} fontSize={20} mb={2} color='primary.text'> {lang?.assessments_} </Typography>
                  <Box sx={{ height:'auto', width:'100%' , flexDirection:'column' }}>
                    {data?.assessment?.map((item) => (<ChatItem {...{item}}/>))}
                  </Box>
                </Box>
              </Card>
            </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 

                  <Box sx={Style?.sideContainerMain}>
                    
                    <Box sx={[Style?.sideContainer,{mt:0}]}>

                      <Grid container spacing={2} >
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} > 
                          <Card elevatio={0} sx={[Style?.WhiteInnerContainer]}> 
                            <Box sx={[Style?.WhiteContainer , { display:'flex',flexDirection:'column' , p : 2 } ]}>
                              <Typography fontWeight={'bold'} fontSize={18}  color='primary.text'> {lang?.learn_video_} </Typography>
                              <Box sx={{
                                width:'100%',
                                height:200,
                                backgroundColor:'#c1c1c1',
                                mt:1,borderRadius:5 
                              }}>
                              <iframe width="100%" height="100%" src={data?.video} frameBorder="0" allowFullScreen></iframe>

                              </Box>
                              <Typography fontWeight={'bold'} fontSize={18} mt={2} color='primary.text'> {lang?.how_to_use_classenda_platform_} </Typography>
                              <Box sx={{
                                display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center' ,mt:2 
                              }}>

                              <Typography fontWeight={'bold'} fontSize={18}  color='#da7289'> {lang?.all_videos_} </Typography>
                              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center' }}>
                                <BarChartIcon sx={{color:'#da7289',fontSize:18}} />
                                <Typography fontWeight={'bold'} fontSize={18}  color='primary.text'> 6,452 </Typography>
                              </Box>
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      </Grid>

                    </Box>
                    <Box sx={[Style?.sideContainer,{mt:0}]}>
                      <Grid container spacing={2} >
    
            
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

          </Grid>

        </Box>

      </Box>

      <Box sx={{height:100}} />

    </Box>
  )
}

export default Student