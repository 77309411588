import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API, dater } from '../../config/functions';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Btn, DataTable, TableHeader, TextInput } from '../../components';
import { useData } from '../../config/Provider';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';




const ParentAssessments = ({nav}) => {

  const [ data , setData ] = useState([])
  const { lang , dir } = useData()

  const getData = async () => {
    await API.get( `/parent-assessments/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const assessmentsTyps = [
    {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
    {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
    {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
    {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/dictonary.png'} , 
    {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/exam.png'} , 
  ]
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.template_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} {v.teacher_lname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.assessments_assigned === 'y' ? <RadioButtonCheckedIcon sx={{color:'green'}} />  :  <RadioButtonUncheckedIcon sx={{color:'red'}} />  } </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.subject_title} {assessmentsTyps?.find((i) => i.value == v.template_type)?.title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {dater(v.aq_date)} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > <Btn {...{buttonTitle: <StickyNote2Icon sx={{color:'white'}} /> , func : () => nav(`/questions-answers/${v.template_id}`) }} /> </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
      getData()
  } , [])
 
  const Header = [
    { title : lang?.exam_ } ,
    { title : lang?.teacher_ } ,
    { title : lang?.assigned_ } ,
    { title : lang?.subject_ } ,
    { title : lang?.date_ } ,
    { title : lang?.view_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.assessments_  }} />
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default ParentAssessments