import { Avatar, Card, Grid, IconButton, InputBase, ListItemButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Style } from './Style';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
const ChatGroup = () => {

    const [ open , setOpen ] = useState(false)
    const [ isParent , setIsParent ] = useState(false)
    const [ user , setUser ] = useState(0)
    const [ msgs , setMsgs ] = useState([])
    const [ msg , setMsg ] = useState([])
    const [ search , setSearch ] = useState([])
    const [ classrooms , setClassrooms ] = useState([])
    const chatContainerRef = useRef(null)

    const [ active , setActive ] = useState({
        name : '',
        type : '',
        pic : '',
        parent : null
    })

    const {socket,info,lang} = useData()

    const Classroom = ({item},key) => (
        <ListItemButton key={key} sx={[Style?.userCard , { borderColor:  active?.name === `${item?.cr_title} ${item?.division_title}`? 'primary.orange' :'primary.border'  }] } elevation={3} onClick={() => startClassroom(item?.division_id , item)}>
            <Avatar src={item?.student_profile_pic} sx={Style?.userCardProfilePic}>{item?.division_title?.substr(0,1)}</Avatar>
            <Box sx={Style?.userCardInfo}>
                <Typography sx={Style?.userCardInfoUsername}> {item?.division_title} {item?.cr_title} </Typography>
                {/* <Typography sx={Style?.userCardInfoMsg}> {item?.msg}... </Typography> */}
            </Box>
        </ListItemButton>
    )

    const ChatText = ({item},key) => (
        <Box key={key} sx={[Style?.textMsgContainer , item?.chat_sender_id === info?.id ? Style?.myText : Style?.NotMyText ] } elevation={3}>
           <Box sx={[Style?.textMsgInnerContainer ,  item?.chat_sender_id === info?.id ? {borderBottomRightRadius : 0} : {borderBottomLeftRadius : 0} , { overflowWrap:'break-word' } ] } elevation={3}>
                <Typography sx={Style?.userCardInfoUsername}> {item?.chat_user_name} </Typography>
                <Typography sx={Style?.userCardInfoUsername}> {item?.chat_contnet} </Typography> 
                <Typography sx={Style?.userCardInfoDate}> {item?.date === lang?.now_ ? lang?.now_ :CalculateTime(item?.chate_date)} </Typography>
           </Box>
        </Box>
    )
 

    const getSearch = async () => {
        await API.get( `/search-teacher-chat-users/${search}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setClassrooms(response?.data?.data)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

 

    const startClassroom = async (id,item) => {
        setUser(id)
        await API.get( `/get-chat-classroom/${id}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setMsgs(response?.data?.data)
            setOpen(true)
            setActive({
                name : `${item?.cr_title} ${item?.division_title}`,
                type : lang?.classroom_,
                pic : 'c' ,
                id : id ,
                parent : false 
            })
            setIsParent(false)
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )

    }

    
    const getUsers = async () => {
        await API.get( `/teacher-chat-users/` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setClassrooms(response?.data?.classrooms)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        if (search?.length > 0) {
            getSearch()
        } else {
            getUsers()
        }
    }, [search?.length])
 
    
    const back = () => {
        setOpen(false)
    }

    const sendMsgClassroom =   () => {
        if (typeof msg !== 'string' || !msg.trim()) {
            return false;
        }
        socket?.emit('SEGmessage', { 
            msg : msg , 
            id : info?.id , 
            school : info?.school , 
            getter : user ,
            type : 'classroom' , 
            teacher : info?.id ,
            user : 'teacher',
            name : info?.name
        })

        setMsg('')

    }

    useEffect(() => {
        socket?.on('RCGmessage', (req) => {
            if (req?.getter == user) {
                setMsgs((e) => {
                    return[...e , {
                        chat_contnet : req?.chat_contnet ,
                        chat_sender_id : req?.chat_sender_id ,
                        chat_date:req?.chat_date ,
                        getter : req?.getter ,
                        chat_user_name : req?.name
                    }]
                })
    
                setTimeout(() => {
                    if (chatContainerRef.current) {
                        chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 1000);
        
            }
        })
    } , [])
 
    return (
        <>
            <Box sx={[Style?.PageHeader ]} elevation={0} spacing={0}>
                <Box>
                    <Typography sx={{fontSize:18,color:'primary.text'}}> {lang?.messenger_} </Typography>
                    <Typography sx={{fontSize:30,color:'primary.text'}}> {lang?.chat_app_} </Typography>

                <Box>
                    {open && (
                        <IconButton onClick={back} sx={{display:{ md:'none' } , }} >
                            <CloseOutlinedIcon sx={{color:'primary.text'}} />
                        </IconButton>
                    )}
                </Box>
            
                </Box>
            </Box>
        
            <Card sx={[Style?.Container ]} elevation={0} spacing={0}>
                
                <Grid container>
            
                    <Grid item xs={12} sm={12} md={2.2} lg={2.2} xl={2.2} sx={{display:{ xs: open ? 'none' : 'flex' ,sm: open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                        <Box sx={Style?.ChatSideBar}>
                            <Box sx={Style?.InputContainer}>
                                <InputBase onChange={(e) => setSearch(e.target.value)} value={search} 
                                    placeholder={lang?.search_} sx={Style?.SearchInput} variant="outlined" autoComplete='off'/>
                            </Box>
                            <Box sx={Style?.ChatItemsComtainer}>
                                {classrooms?.map((item,key) => (<Classroom key={key} {...{item,key}} />))}
                            </Box>
                        </Box>
                    </Grid> 

                    <Grid item xs={12} sm={12} md={9.8} lg={9.8} xl={9.8}  sx={{display:{ xs: !open ? 'none' : 'flex' ,sm: !open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                        
                        <Box sx={Style?.ChatRightBar}>

                            <Box sx={Style?.ChatHeader}>
                            {active?.name && (
                                    <>
                                        <Avatar  sx={Style?.userCardProfilePic}>{active?.name?.substr(0,1)}</Avatar>
                                        <Box sx={Style?.ChatUsernameInfo}>
                                            <Typography sx={Style?.ChatUsername}> {active?.name} </Typography>
                                            
                                            <Box sx={{
                                                display:'flex',
                                                flexDirection:'row',
                                            }}>
                                                <Typography sx={Style?.ChatUserType}> 
                                                    {isParent   ?  lang?.parent_ : active?.type}
                                                </Typography>
                                            
                                            </Box>
                                            
                                        
                                        </Box> 

                                    </>
                            )}

        
                            </Box>

                            <Box sx={Style?.ChatTexts}>
                                {msgs?.map((item , key) => ( <ChatText key={key} {...{item,key}} /> ))}
                                <div ref={chatContainerRef}></div>
                            </Box>

                            <Box sx={Style?.TypeInputSectionContainer}>
                                    
                                {active?.name && (
                                    <>
                                        <InputBase onChange={(e)=>setMsg(e.target.value)} value={msg} placeholder={`${lang?.type_}...`}  sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>

                                        <IconButton aria-label="send" onClick={sendMsgClassroom}>
                                            <SendOutlinedIcon  sx={{color:"primary.text"}}/>
                                        </IconButton>      
                                    </>
                                )}


                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
  )
}

export default ChatGroup