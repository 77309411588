import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API, dater } from '../../config/functions';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DataTable, TableHeader, TextInput } from '../../components';
import { useData } from '../../config/Provider';


const GuidanceTeachers = ({nav}) => {

  const [ data , setData ] = useState([])
  const { lang , dir } = useData()

  const getData = async () => {
    await API.get( `/guidance-teachers/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
    const TableLoop = () => {
        return ( data?.map( ( v , k ) => (
        <TableRow key={k} sx={Style?.Row}> 
            <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} {v.teacher_lname} </TableCell> 
            <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.subject_title} </TableCell> 
        </TableRow>
        )))
    } 

    useEffect(() => {
        getData()
    } , [])
 
    const Header = [
        { title : lang?.teachers_ } ,
        { title : lang?.subject_ } ,
    ]

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title:lang?.teachers_  }} />
            <DataTable {...{ Header , Data : <TableLoop /> }} />
        </Box>
    )
}

export default GuidanceTeachers