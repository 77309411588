export const Style = {
    Input : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1,
        mt:2
    } , 

    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2
    } ,

    iconBtn : {
        backgroundColor:'primary.orange',
        color:'white',
        '&:hover' : { 
            backgroundColor:'primary.blue' 
        },
        position:'absolute',
        right:0,
        bottom:0,
        mr:-2,
        mb:-2,
    }

}