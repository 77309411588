import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DataTable, TableHeader, TextInput } from '../../components';
import { useData } from '../../config/Provider';


const StudentPopupquiz = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const [ classroom , setClassroom ] = useState(0)
  const [ template , setTemplate ] = useState(0)
  const { lang , dir } = useData()

  const getData = async () => {
    await API.get( `/studnet-assigned-question/popup` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-studnet-assigned-question/popup/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 


  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.template_title} </TableCell> 
 
    
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton onClick={() => nav(`/questions/${v.aq_template_id}`)} aria-label="edit">
            <PsychologyAltIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
 

      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.title_ } ,
    { title : lang?.questions_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title: lang?.pop_quiz_   }} />
      <TextInput {...{set:setSearch,value:search,placeholder:lang?.search_}} />
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default StudentPopupquiz