import { Box, Button, CardMedia, InputBase, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TextEditor, TextInput } from '../../components';
import { useData } from '../../config/Provider';
import { Style } from './Style';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useParams } from 'react-router-dom';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';


const AssessmentQuestions = () => {
  
  const {id} = useParams()
  const {lang} = useData()
  const [question, setQuestion] = useState('')
  const [questions , setQuestions] = useState([])
  const [data , setData] = useState([])
  const [currentType, setCurrentType] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [savedStates, setSavedStates] = useState({});

  const getData = async () => {
    await API.get( `/template-info/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {

          var rows = []

          setData(response?.data?.data)
          setQuestions(response?.data?.questions)
          setParagraph(response?.data?.data?.template_paragraph)

          response?.data?.questions?.map(( item ) => {
            const options =  item?.question_options ? JSON.parse(item?.question_options) : []
            rows?.push({
              question_type : item?.question_type,
              question_score : item?.question_score,
              options : item?.question_type === 'option' ? options : [] , 
              match : item?.question_type === 'match' ? options : [], 
              blank : item?.question_type === 'fill' ? options : [] ,
              question_text : item?.question_text , 
              question_id : item?.question_id 
            })

          })

          setQuestions(rows)

        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getData()
  } , [])
 
  const handleChange = (event , question_id) => {
    const newType = event.target.value;
    setCurrentType(newType);
    const questionIndex = questions.findIndex((q) => q.question_id === question_id);
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_type = newType;
    setQuestions(newQuestions);
  };

  const addQuesrion = async () => {
    await API.post( `/add-question/` , { id:id } ,  Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setQuestions([...questions , {
            question_type : 'option',
            question_score : 0,
            options : [
              { text : '' , isTrue : false , id : 0 } ,
              { text : '' , isTrue : true , id : 1 } ,
              { text : '' , isTrue : false , id : 2 } ,
              { text : '' , isTrue : false , id : 3 } ,
            ] , 
            match : [
              { texta : '' , textb : '' , id : 0 } ,
              { texta : '' , textb : '' , id : 1 } ,
              { texta : '' , textb : '' , id : 3 } ,
              { texta : '' , textb : '' , id : 3 } ,
            ] , 
            blank : [
              { text : '' , id : 0 } ,
              { text : '' , id : 1 } ,
              { text : '' , id : 2 } ,
              { text : '' , id : 3 } ,
            ] ,
            question_text : '' , 
            question_id : response?.data?.id
          }])
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const deleteQuestion = async (question_id) => {
    await API.post( `/delete-question/` , { template_id : id , question_id : question_id } ,  Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          const filter = questions?.filter((i) => i.question_id != question_id)
          setQuestions(filter)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const questionTypesSelector = [
    { title : lang?.multiple_choice_ , value : 'option' },
    { title : lang?.matching_ , value : 'match' },
    { title : lang?.fill_the_blank_ , value : 'fill' },
    { title : lang?.short_answer_ , value : 'text' },
  ]
 
  const deleteOption = (option_id , question_id) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.question_id === question_id) {
          return {
            ...question,
            options: question.options.filter((option) => option.id !== option_id)
          };
        } else {
          return question;
        }
      });
    });
  };
  
  const deleteMatch = ( match_id , question_id ) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question?.question_id === question_id) {
          return {
            ...question,
            match: question.match.filter((match) => match.id !== match_id)
          };
        } else {
          return question;
        }
      });
    });
  };

  const deleteBlank = ( blank_id , question_id ) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question?.question_id === question_id) {
          return {
            ...question,
            blank: question.blank.filter((blank) => blank.id !== blank_id)
          };
        } else {
          return question;
        }
      });
    });
  };

  const isTrueOption = (key , id) => {
    setQuestions(prevQuestions => {
      const updatedQuestions = prevQuestions.map((question) => {
        if (question?.question_id === id) {
          const updatedOptions = question.options.map((option) => {
            if (option.id === key) {
              return { ...option, isTrue: !option.isTrue };
            }
            return option;
          });
          return { ...question, options: updatedOptions };
        }
        return question;
      });
      return updatedQuestions;
    });
  };

  const addOption = (id) => {
    setQuestions((prevState) => {
      const updatedQuestions = [...prevState];
      const questionIndex = updatedQuestions.findIndex((q) => q.question_id == id);
      const updatedOptions = [...updatedQuestions[questionIndex].options, {
        text : '' , isTrue : false , id :  updatedQuestions[questionIndex].options?.length 
      }];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: updatedOptions,
      };
      return updatedQuestions;
    });
  };

  const addMatch = (id) => {
    setQuestions((prevState) => {
      const updatedQuestions = [...prevState];
      const questionIndex = updatedQuestions.findIndex((q) => q.question_id === id);
      const updatedOptions = [...updatedQuestions[questionIndex].match, {
        texta : '' , textb : '' ,  id :  updatedQuestions[questionIndex].match?.length 
      }];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        match: updatedOptions,
      };
      return updatedQuestions;
    });
  };

  const addBlank = (id) => {
    setQuestions((prevState) => {
      const updatedQuestions = [...prevState];
      const questionIndex = updatedQuestions.findIndex((q) => q.question_id === id);
      const updatedOptions = [...updatedQuestions[questionIndex].blank, {
        text : '' ,  id :  updatedQuestions[questionIndex].blank?.length 
      }];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        blank: updatedOptions,
      };
      return updatedQuestions;
    });
  };

  const handleInputChange = (event, question_id, field_name, field_index) => {
    const value = event.target.value;
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question?.question_id === question_id) {
          const field = question[field_name];
          const updatedField = [...field];
          updatedField[field_index] = {
            ...field[field_index],
            text: value,
          };
          return {
            ...question,
            [field_name]: updatedField,
          };
        } else {
          return question;
        }
      });
    });
  }

  const handleMatchingInputChange = (event, question_id, field_name, field_index, sub_field_name) => {
    const value = event.target.value;
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question?.question_id === question_id) {
         
          const field = question[field_name];
          const updatedField = [...field];
          updatedField[field_index] = {
            ...field[field_index],
            [sub_field_name]: value,
          };
          return {
            ...question,
            [field_name]: updatedField,
          };
        } else {
          return question;
        }
      });
    });
  };

  const saveQuestion =  async (question_id) => {

    setSavedStates(prevSavedStates => ({
      ...prevSavedStates,
      [question_id]: true
    }));
    
    const question = questions?.find((i) => i.question_id == question_id )
    
    await API.post( `/save-question/` , { template_id : parseInt(id) , question_id : question_id , question : question } ,  Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {

          setTimeout(() => {
            setSavedStates(prevSavedStates => ({
              ...prevSavedStates,
              [question_id]: false
            }));
          }, 1500);
        
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  }

  const handleMainInputChange = (event, question_id) => {
    const value = event.target.value;

    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question?.question_id === question_id) {
          return {
            ...question,
            question_text: value,
          };
        } else {
          return question;
        }
      });
    });
  }

  const handleScoreInputChange = (event, question_id) => {
    const value = event.target.value;
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.question_id === question_id) {
          return {
            ...question,
            question_score: parseFloat(value),
          };
        } else {
          return question;
        }
      });
    });
  }

  const assessmentsTyps = [
    {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
    {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
    {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
    {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/dictonary.png'} , 
    {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/exam.png'} ,  
  ]

  const saveParagraph = async () => {
    await API.post( `/save-paragraph/` , { template_id : parseInt(id) , paragraph : paragraph } ,  Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

const typeTitle = assessmentsTyps?.find((i) => i.value === data?.template_type )?.title
const typeImg = assessmentsTyps?.find((i) => i.value === data?.template_type )?.image

  return (
    <Box mt={1} ml={5} mr={5} mb={2}>

      <Box sx={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        width:'100%',
      }}>

        <CardMedia component="img" image={typeImg} alt="exam logo title" sx={Style?.CardMedia}/>
        
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            ml:1,
            mr:1,
        }}>
          <Typography color='primary.text' fontSize={40}> {data?.template_title} </Typography>
          <Typography color='primary.text' fontSize={20}> {typeTitle} </Typography>

        </Box>
      </Box>
 
      {data?.template_type === 'comprehension' && (
        <Box sx={Style?.paragraphContainer} >
          <TextEditor {...{contnet:paragraph , setcontnet:setParagraph }}/>
          <Box sx={{height:50,mt:2,display:'flex',justifyContent:'flex-end',alignItems:'flex-end',width:'100%'}}>
              <Button sx={Style?.saveBtn} onClick={saveParagraph} >{lang?.save_}</Button>
          </Box>
        </Box>
      )}
 
      {questions?.map((question_item,key) => (

        <Box sx={Style?.questionsContainer} key={key}>

          <Box sx={Style?.questionHeader}>
            
            <Box sx={Style?.questionInputContainer}>

              <InputBase 
                type={'text'} 
                value={question_item?.question_text}   
                onChange={(event) => handleMainInputChange(event , question_item?.question_id )} 
                placeholder={lang?.question_} 
                sx={Style?.questionInput} 
                variant="outlined" 
                autoComplete='off'
              />
            
            </Box>

            <Box sx={Style?.selectOptionContainer}>
              <Select value={question_item?.question_type} sx={Style?.Selector} 
                onChange={(e) => handleChange( e , question_item?.question_id)} >
                {questionTypesSelector?.map((select_item,key) => (
                  <MenuItem key={key} value={questionTypesSelector?.find((i) => i?.value == select_item?.value )?.value}> 
                    {questionTypesSelector?.find((i) => i?.value == select_item?.value )?.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>

          </Box>

          {question_item?.question_type === 'match' && (

            <Box sx={{width:'100%',}}>

              {question_item?.match?.map((match_item,match_key) => (

                <Box key={match_key} sx={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center',
                  alignContent:'center',
                  mt:3
                }}>

                  <Box sx={{width:'30%'}}>
                    
                    <InputBase 
                      type={'text'} 
                      value={match_item?.texta}   
                      onChange={(event) => handleMatchingInputChange(event, question_item?.question_id, 'match', match_key, 'texta')} 
                      placeholder={lang?.answer_} 
                      sx={Style?.answersInput} 
                      variant="outlined" 
                      autoComplete='off'
                    />
                    
                  </Box>

                  <Box sx={{width:'30%'}}>

                    <InputBase 
                      type={'text'} 
                      value={match_item?.textb}   
                      onChange={(event) => handleMatchingInputChange(event, question_item?.question_id, 'match', match_key, 'textb')} 
                      placeholder={lang?.answer_} 
                      sx={Style?.answersInput} 
                      variant="outlined" 
                      autoComplete='off'
                    />

                  </Box>

                  <Box sx={{width:'30%'}}>
                    <ClearIcon onClick={() => deleteMatch(match_item?.id , question_item?.question_id)} sx={{fontSize:35,ml:2,mr:2,color:'red'}} />
                  </Box>
                  
                </Box>

              ))}

              <Box mt={2}>
                <Button onClick={ () => addMatch(question_item?.question_id) } sx={Style?.saveBtn} >{lang?.add_}</Button>
              </Box>

            </Box>
          )}

          {question_item?.question_type === 'fill' && (
            <Box sx={{width:'100%'}}>

              <Typography color='primary.text' mt={2} mb={2}>
                {lang?.add_to_generate_blank_feild}
              </Typography>

              <Typography color='primary.text'>
                {lang?.correct_answer_}
              </Typography>
             
              {question_item?.blank?.map((blank_item,blank_key) => (
                
                <Box key={blank_key} sx={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center',
                  width:'100%',
                  mt:2
                }}>
                  <Box sx={{width:'30%'}}>
                    <InputBase 
                      type={'text'} 
                      value={blank_item?.text}   
                      onChange={(event) => handleInputChange(event, question_item?.question_id, 'blank' ,blank_key )} 
                      placeholder={lang?.answer_} 
                      sx={Style?.answersInput} 
                      variant="outlined" 
                      autoComplete='off'
                    />
                  </Box>
                  <Box sx={{width:'30%'}}>
                    <ClearIcon onClick={() => deleteBlank(blank_item?.id , question_item?.question_id)} sx={{fontSize:35,ml:2,mr:2,color:'red'}} />
                  </Box>
                </Box>

              ))}
              <Box mt={2}>
                <Button onClick={ () => addBlank(question_item?.question_id) } sx={Style?.saveBtn} >{lang?.add_}</Button>
              </Box>
            </Box>
          )}

          {question_item?.question_type === 'option' && (

            <Box mt={3} sx={{width:'100%',}}>

              <Box sx={{
                display:'flex',
                flexDirection:'row',
                mt:2,
              }}>

                <Box sx={{
                  display:'flex',
                  flexDirection:'column',
                  width:'100%'
                }}>
                  
                  <Typography ml={'22%'} color='primary.text'> {lang?.correct_answer_} </Typography>

                  {question_item?.options?.map((option,option_key) => (
                    
                    <Box key={option_key} mt={1} sx={{
                      display:'flex',
                      flexDirection:'row',
                      alignItems:'center'
                    }}>

                      <CircleOutlinedIcon sx={{fontSize:25,ml:2,mr:2,color:'primary.orange'}} />

                      <Box>
                      
                        <InputBase 
                          type={'text'} 
                          value={option?.text}   
                          onChange={(event) => handleInputChange(event, question_item?.question_id, 'options' ,option_key )} 
                          placeholder={lang?.answer_} 
                          sx={Style?.answersInput} 
                          variant="outlined" 
                          autoComplete='off'
                        />
                      </Box>

                      <Box>
                        <ClearIcon onClick={() => deleteOption(option?.id , question_item?.question_id)} sx={{fontSize:35,ml:2,mr:2,color:'red'}} />
                      </Box>

                      <Box onClick={() => isTrueOption(option_key,question_item?.question_id) } sx={{
                        cursor:'pointer'
                      }}>

                        {option?.isTrue ? <CheckCircleOutlineOutlinedIcon  sx={{color:'primary.blue',fontSize:25}} /> :<CircleOutlinedIcon sx={{color:'primary.orange',fontSize:25}}/>}
                      
                      </Box>

                    </Box>
                  ))}

                  <Box mt={2}>
                    <Button onClick={ () => addOption(question_item?.question_id) } sx={Style?.saveBtn} >{lang?.add_}</Button>
                  </Box>

                </Box>

              </Box>

            </Box>
          )}

          {question_item?.question_type === 'text' && (

            <Box mt={3}>
              <Box></Box>
            </Box>
          )}

          <Box sx={Style?.footerContainer}>

            <Box>
              <Button sx={Style?.saveBtn} onClick={() => deleteQuestion(question_item?.question_id)}>{lang?.delete_}</Button>
            </Box>

            <Box sx={Style?.InnserFooterContainer}>
              <Typography color='primary.text'> {lang?.score_} </Typography>
              <Box sx={{width:80}}>
              
              <InputBase 
                type={'text'} 
                value={question_item?.question_score}   
                onChange={(event) => handleScoreInputChange(event, question_item?.question_id )} 
                placeholder={lang?.answer_} 
                sx={Style?.scoreInput} 
                variant="outlined" 
                autoComplete='off'
              />
              </Box>
              <Typography mr={5} ml={5} color='primary.text'> {lang?.out_of_total_exam_score_} {data?.template_score} </Typography>
            </Box>

            <Box sx={{height:50}}>
              <Button sx={Style?.saveBtn} onClick={() => saveQuestion(question_item?.question_id)} >{savedStates[question_item?.question_id] ? lang?.saved_ : lang?.save_}</Button>
            </Box>

          </Box>

        </Box>

      ))}

      <Box sx={Style?.Conatiner} onClick={addQuesrion}>
        <AddCircleOutlineIcon sx={{color:'#ffffff',fontSize:150}} />
      </Box>

    </Box>
  )
}

export default AssessmentQuestions