import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useData } from '../../config/Provider';

const DialogWindow = ({open,setOpen , Data,title,func}) => {
  const{lang}=useData()
  const handleClose = () => {
    setOpen(false);
  };

  const Style = {
    Button : {
      backgroundColor:'primary.blue' ,
      color:'white' ,
      '&:hover' :  {
          backgroundColor:'primary.orange' ,
          color:'white'
      } ,
      marginLeft:3
    } ,
    theme : {backgroundColor:'primary.main' , color : 'primary.text'}
  }

  return (
    <div>
   
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={Style?.theme} id="alert-dialog-title">
        {title}
        </DialogTitle>
        <DialogContent sx={Style?.theme}>
            <Data />
        </DialogContent>
        <DialogActions sx={Style?.theme}>
          <Button sx={Style?.Button} onClick={handleClose}>{lang?.cancel_}</Button>
          <Button sx={Style?.Button} onClick={func} autoFocus>
            {lang?.add_}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogWindow