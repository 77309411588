import { Avatar, Grid, InputBase, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Btn, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useData } from '../../config/Provider'
import { useParams } from 'react-router-dom'

const AddSubject = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ description , setDescription ] = useState('')
  const {lang} = useData()
  const {id} = useParams()
  const [evType, setEvType] = useState('')
  const [evSelected, setEvSelected] = useState(false)
  const [selectedEv, setselectedEv] = useState([])
  const [student_id, setstudent_id] = useState(0)

  const PTypes = [
    { title : lang?.on_task_ , value : 'on task'} ,
    { title : lang?.participating_ , value : 'participating' } ,
    { title : lang?.cooperative_ , value : 'cooperative' } ,
    { title : lang?.well_done_  , value : 'well done'} ,
    { title : lang?.respectful_  , value : 'respectful'} ,
  ]

  const NTypes = [
    { title : lang?.not_organized_  , value : 'not organized'} ,
    { title : lang?.hard_to_stay_on_task_  , value : 'hard to stay on task'} ,
    { title : lang?.uncooperative_  , value : 'uncooperative'} ,
    { title : lang?.disruptive_  , value : 'disruptive'} ,
    { title : lang?.disrespectful_  , value : 'disrespectful'} ,
  ]

  const getData = async () => {
    await API.get( `/get-evaluation/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setDescription(response?.data?.data?.e_description)
        setstudent_id(response?.data?.data?.e_student_id)
        setselectedEv(response?.data?.data?.e_types?JSON.parse(response?.data?.data?.e_types):[])
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }
 
  const SelectEvType = (e) => {
    setEvType(e)
    setEvSelected(true)
    setselectedEv([])
  }

  const getSelectEvType = (e) => {
    setEvType(e)
    setEvSelected(true)
  }

  useEffect(() => {
    getData()
    PTypes.forEach((item) => {
      getSelectEvType(selectedEv.some((i) => i?.value === item?.value) ? lang?.nigative_ : lang?.positive_)
    })
  } , [id])
 
  const AddEv = async () => {
    await API.post( `/save-evaluation/` , {  description : description , student_id : student_id , info: JSON.stringify(selectedEv) , id : id} , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/evaluations/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const selectEv = (e) => {
    const a = selectedEv?.find((item) => item.title === e)
    if (a) {
      const b = selectedEv?.filter((item) => item.title !== e)
      setselectedEv(b)
    } else {
      setselectedEv([...selectedEv , {title : e}])
    }
  } 

  useEffect(() => {
  }, [selectedEv?.length])
  
  return (
    <Box sx={Style?.Container}>

        <Box p={2}>

        <TableHeader {...{ title:lang?.edit_evaluation_ , buttonIcon : '' , buttonTitle:lang?.back_ ,page: () => nav('/evaluations/')  }} />

          <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
            
            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
              <Box sx={{width:'100%',}}>
                <Box sx={[Style?.Tag , {borderColor:evType===lang?.nigative_?'primary.orange':'primary.border'}]} onClick={() => SelectEvType(lang?.nigative_)}>
                    <Typography color='primary.text'> {lang?.nigative_} </Typography>
                </Box>
                <Box sx={[Style?.Tag , {borderColor:evType===lang?.positive_?'primary.orange':'primary.border'}]} onClick={() => SelectEvType(lang?.positive_)}>
                    <Typography color='primary.text'> {lang?.positive_} </Typography>
                </Box>
                {evSelected&& (
                  <>
                    {evType === lang?.positive_ && (
                      <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
                        {PTypes?.map((item,key) => 
                          <Box key={key} sx={[Style?.Tag , 
                            { borderColor : selectedEv?.find((v) => v.title === item.value ) ? 'primary.orange' : 'primary.border' }
                          ]} onClick={() => selectEv(item?.value) }>
                            <Typography color='primary.text'> {item?.title} </Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                    {evType === lang?.nigative_ && (
                      <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
                        {NTypes?.map((item,key) => 
                          <Box key={key} sx={[Style?.Tag , 
                            { borderColor : selectedEv?.find((v) => v.title === item.value ) ? 'primary.orange':'primary.border'}
                          ]} onClick={() => selectEv(item?.value)}>
                            <Typography color='primary.text'> {item?.title} </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box> 
              
              <InputBase 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
                placeholder={lang?.note_} 
                sx={Style?.Input} 
                multiline={true}
                rows={3}
                variant="outlined" autoComplete='off'
              />
              {selectedEv?.length > 0 && (
                <Btn {... { buttonTitle:lang?.save_ , func : AddEv }} />
              )}
            </Box>

            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

          </Box>

        </Box>

    </Box>
  )
}

export default AddSubject