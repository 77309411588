import React ,  {useState,useEffect }from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { TableHeader } from '../../components';
import { Box } from '@mui/system';
import { useData } from '../../config/Provider';

const TeacherAnnouncements = () => {

    const [data, setData ] = useState([])
    const {lang} = useData()
    const getData = async () => {
        await API.get( `/get-teacher-shcool-announcements/` , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
              setData(response?.data?.data)
            } else {
              console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
      }

    const Style = {
        root: {
            minWidth: 275,
            margin: '10px',
            backgroundColor:'primary.main'
        },
        title: {
            fontSize: 18,
            fontWeight: 'bold',
            color:'primary.text'
        },
        date: {
            fontSize: 14,
            color:'primary.text'
        },
        Container : {
          mr : 2 ,
          ml : 2 ,
          mt : 2 ,
          p:2,
          backgroundColor:'white',
          border:'2px solid #e4e6e5',
          borderRadius:2
      } ,
    }

    const PostCard = ({ item }) => {
      
        return (
          <Card sx={Style?.root}>
            <CardContent>
              <Typography sx={Style?.title} gutterBottom>
                {item?.a_title}
              </Typography>
              <Typography variant="body2" component="p" color='primary.text'>
                {item?.a_decription}
              </Typography>
              <Typography sx={Style?.date} gutterBottom  color='primary.text'>
                {CalculateTime(item?.a_date)}
              </Typography>
            </CardContent>
          </Card>
        );
      };

      useEffect(() => {
        getData()
      } , [])

  return (
    <Box sx={Style?.Container}>
        <TableHeader {...{title:lang?.school_announcements_}} />
        {data?.map((item,key) => ( <PostCard key={key} {...{item}} /> ))}
    </Box>
  )
}

export default TeacherAnnouncements