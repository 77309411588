import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useData } from '../../config/Provider';

const AlertsDialog = ({open,setOpen,func,btnTitle,alertText}) => {

    const { lang } = useData()

    const handleClose = () => {
        setOpen(false)
    }

    return (
    
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"
            sx={{backgroundColor:'primary.main',width:500}}
            >
            {/* {"Use Google's location service?"} */}
            </DialogTitle>
            <DialogContent
            sx={{backgroundColor:'primary.main'}}
            >
                {alertText}
            </DialogContent>
            <DialogActions 
            sx={{backgroundColor:'primary.main'}}
            >
            <Button sx={{color:'primary.text'}} onClick={handleClose}>{lang?.close_}</Button>
            {btnTitle && (
            <Button  sx={{color:'primary.text'}} onClick={func} autoFocus>
                {btnTitle}
            </Button>
            )}

            </DialogActions>
        </Dialog>

    )
}

export default AlertsDialog