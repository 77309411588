import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { Btn, TableHeader } from '../../components'
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { useParams } from 'react-router-dom'
import { API } from '../../config/functions';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useData } from '../../config/Provider';

const GuidanceEditDoc = ({nav}) => {

  const [ data , setData ] = useState([])
  const [ comment , setComment ] = useState('')
  const { id , type } = useParams()
  const {lang} = useData()
  const save = async () => {
    await API.post( `/save-guidance-docs-comment/` , { comment : comment , id : id  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav(`/${type}/`)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const get = async () => {
    await API.get( `/guidance-single-docs/${id}`, Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            setData(response?.data?.data[0])
            setComment(response?.data?.data[0]?.document_guidance_comment)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    get()
  } , [id])

  const handleDownload = (file) => {
    const downloadUrl = `${Vals?.M_API}/download/${data?.document_file_name}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const info = [
    { value :`${lang?.student_name_} : ${data?.student_fname} ${data?.student_lname}` } ,
    { value : `${lang?.parent_name_} : ${data?.parent_name}` } ,
    { value : `${lang?.file_} : ${data?.document_name} ` } ,
  ]

  return (
    <Box sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.document_    }} />

        <Box sx={Style?.Container}>
            <Box sx={Style?.midContainer}>
                {info?.map((item,key) => <Typography mt={2} fontSize={20} key={key} color='primary.text'> {item?.value} </Typography> )}
                <Box sx={{width:100,mt:2}}>
                    <Btn {...{func:handleDownload,buttonTitle:lang?.download_}} />
                </Box>
            </Box>
            <Box sx={Style?.midContainer}>
                <InputBase 
                    value={comment} 
                    onChange={(e) => setComment(e.target.value)} 
                    placeholder={lang?.comment_} 
                    sx={Style?.Input} 
                    variant="outlined" 
                    autoComplete='off'
                    multiline={true}
                    rows={5}/>
                <Box sx={{width:100,mt:2}}>
                    <Btn {...{func:save,buttonTitle:lang?.save_}} />
                </Box>
            </Box>
        </Box>


      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        {/* <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={title} onChange={(e) => setTitle(e.target.value) } placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box> */}

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default GuidanceEditDoc