import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Custombar({ colorA, colorB }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows us to set a custom height
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: '',
        color: 'white'
      },
    },
    scales: {
      y: {},
      x: {},
    },
  };

  const labels = ['SAT', 'SUN', 'MON', 'TUE'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Return users',
        data: [45, 40, 10, 35],
        backgroundColor: '#846eff',
      },
      {
        label: 'New users',
        data: [55, 34, 40, 60],
        backgroundColor: '#792585',
      },
    ],
  };

  return (
    <div style={{ height: '380px' }}> 
      <Bar options={options} data={data} />
    </div>
  );
}
