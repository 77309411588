import { Button } from '@mui/material'
import React from 'react'

const Btn = ({func,buttonTitle}) => {
    const Style = {
        Button : {
            backgroundColor:'primary.blue' ,
            color:'white' ,
            '&:hover' :  {
                backgroundColor:'primary.orange' ,
                color:'white'
            } ,
        } ,
    }

    return (
        <Button sx={[Style?.Button ]} variant="outlined"  onClick={func}>
            {buttonTitle}
        </Button>
    )
}

export default Btn