import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AddAnnouncement, AddClassroom, ParentTimeTable, AddDivision, AddEvaluation, AddExamTemplate, AddHomeworkTemplate, AddParent, AddSchool, AddStudent, AddSubject, AddTeacher, AssignedHomework, DismissedStudents, DismissedTeachers, EditAnnouncement, EditClassroom, EditExamTemplate, EditHomeworkTemplate, EditParent, EditSchoolSubject, EditStudent, EditTeacher, Exams, Login, Questions, QuestionsAnswers, School, SchoolAnnouncements, SchoolCalendar, SchoolClassrooms, SchoolDivisions, SchoolSettings, SchoolStudents, SchoolSubjects, SchoolTeacher, SchoolTimeTable, Student, StudentAnnouncements, StudentHomework, StudentQuestions, StudentTimeTable, TeacherAnnouncements, TeacherAnswer, TeacherEvaluation, StudnetChat, StudentGrades, ParentGrades, Langs, AddLanguage, LangsContent, ParentChat, TeacherReport, ParentReport, AssessmentQuestions, EditAssessment, StudentClasswork, StudentAssessments, ChatGroup, StudentChatGroup, EditEvaluation, TeacherGrades, ParentTeachers, ParentAssessments, ParentQuestions, StudentQuestionsAnswers, ParentQuestionsAnswer, StudentReport, TeacherListAnnouncements, AddTeacherAnnouncement, EditTeacherAnnouncement, ParentAnnouncements, SchoolChat, ParentMedicalDocs, ParentAddMedicalDoc, ParentStudentDocs, ParentAddStudentDoc, SchoolGuidances, SchoolDismissedGuidances, AddGuidance, EditGuidance, GuidanceTimeTable, GuidanceTeachers, GuidanceReport, GuidanceGrades, GuidanceMedicalDocs, GuidanceEditDoc, GuidancePersonalDocs, GuidanceSchoolAnnouncements, GuidanceChat, ParentGuidanceChat, Parent, BlockedSchools, EditSchool, TeacherCalendar, StudentCalendar, ParentCalendar, GuidanceCalendar, Videos, StudentProfile, Ztest } from '../pages/'
import { useLocalState } from "./functions";
import { useData } from "./Provider";
import { Admin, Assessments, Attendance, Chat, Classrooms , Guidance, Homework, Schools , Teacher, TimeTable } from "../pages"
import EditDivision from "../pages/editDivision/EditDivision";
import AssignedExam from "../pages/assignedExam/AssignedExam";
import StudentExam from "../pages/studentExam/StudentExam";
import AddAssessment from "../pages/addAssessment/AddAssessment";
import AssignedAssessment from "../pages/assignedAssessment/AssignedAssessment";
import StudentComprehension from "../pages/studentComprehension/StudentComprehension";
import StudentPopupquiz from "../pages/studentPopupquiz/StudentPopupquiz";
import SchoolReport from "../pages/schoolReport/SchoolReport";
import ParentSchoolChat from "../pages/parentSchoolChat/ParentSchoolChat";
import SchoolGrades from "../pages/schoolGrades/SchoolGrades";
 
const Router = () => {

  const nav = useNavigate() 
  const [ itmes , setItems ] = useState([])
  const { userType , logged } = useData()
  const [ Islogged , setIslogged ] = useLocalState("Islogged" );
  const [loading , setLoading ] = useState(true)

  const TEACHER_ROUTE = [
    { url : '/home/' , page : <Teacher {...{nav}} /> } ,
    { url : '/login/' , page : logged ?  <Teacher {...{nav}} /> :  <Login {...{nav}} />  } ,
    { url : '/general/' , page : <Teacher {...{nav}} /> } ,
    { url : '/' , page : <Teacher {...{nav}} /> } ,
    { url : '/chat/' , page : <Chat {...{nav}} /> } ,
    { url : '/classrooms/' , page : <Classrooms {...{nav}} /> } ,
    { url : '/guidance/' , page :<Guidance {...{nav}} /> } ,
    { url : '/homework/' , page : <Homework {...{nav}} /> } ,
    { url : '/attendance/' , page : <Attendance {...{nav}} /> } ,
    { url : '/timetable/' , page : <TimeTable {...{nav}} /> } ,
    { url : '/assessments/' , page : <Assessments {...{nav}} /> } ,
    { url : '/teacher-announcements/' , page : <TeacherAnnouncements {...{nav}} /> } ,
    { url : '/teacher-add-homework-template/' , page : <AddHomeworkTemplate {...{nav}} /> } ,
    { url : '/questions/:id' , page : <Questions {...{nav}} /> } ,
    { url : '/questions-answers/:id/:student' , page : <QuestionsAnswers {...{nav}} /> } ,
    { url : '/edit-homework-title/:id' , page : <EditHomeworkTemplate {...{nav}} /> } ,
    { url : '/edit-exam-title/:id' , page : <EditExamTemplate {...{nav}} /> } ,
    { url : '/assigned-homework/' , page : <AssignedHomework {...{nav}} /> } ,
    { url : '/assigned-exams/' , page : <AssignedExam {...{nav}} /> } ,
    { url : '/teacher-exams-templates/' , page : <Exams {...{nav}} /> } ,
    { url : '/teacher-add-exam-template/' , page : <AddExamTemplate {...{nav}} /> } ,
    { url : '/evaluations/' , page : <TeacherEvaluation {...{nav}} /> } ,
    { url : '/add-evaluation/' , page : <AddEvaluation {...{nav}} /> } ,
    { url : '/edit-evaluation/:id' , page : <EditEvaluation {...{nav}} /> } ,
    { url : '/teacher-answer/:id' , page : <TeacherAnswer {...{nav}} /> } ,
    { url : '/reports/' , page : <TeacherReport {...{nav}} /> } ,
    { url : '/add-assessment/' , page : <AddAssessment {...{nav}} /> } ,
    { url : '/assessment-questions/:id' , page : <AssessmentQuestions {...{nav}} /> } ,
    { url : '/edit-assessment/:id' , page : <EditAssessment {...{nav}} /> } ,
    { url : '/assigned-assessment/' , page : <AssignedAssessment {...{nav}} /> } ,
    { url : '/chat-group/' , page : <ChatGroup {...{nav}} /> } ,
    { url : '/teacher-grades/' , page : <TeacherGrades {...{nav}} /> } ,
    { url : '/my-announcements/' , page : <TeacherListAnnouncements {...{nav}} /> } ,
    { url : '/add-announcement/' , page : <AddTeacherAnnouncement {...{nav}} /> } ,
    { url : '/edit-announcement/:id' , page : <EditTeacherAnnouncement {...{nav}} /> } ,
    { url : '/calendar/' , page : <TeacherCalendar {...{nav}} /> } ,
  ]

  const ADMIN_ROUTE = [
      { url : '/home/' , page : <Schools {...{nav}} /> } ,
      { url : '/login/' , page : logged ?  <Schools {...{nav}} /> :  <Login {...{nav}} />  } ,
      { url : '/general/' , page : <Schools {...{nav}} /> } ,
      { url : '/' , page : <Schools {...{nav}} /> } ,
      { url : '/add-school/' , page : <AddSchool {...{nav}} /> } ,
      { url : '/schools/' , page : <Schools {...{nav}} /> } ,
      { url : '/edit-school/:id' , page : <EditSchool {...{nav}} /> } ,
      { url : '/blocked-schools/' , page : <BlockedSchools {...{nav}} /> } ,
      { url : '/languages/' , page : <Langs {...{nav}} /> } ,
      { url : '/add-language/' , page : <AddLanguage {...{nav}} /> } ,
      { url : '/lang-content/:id' , page : <LangsContent {...{nav}} /> } ,
      { url : '/videos/' , page : <Videos {...{nav}} /> } ,
  ]

  const SCHOOL_ROUTE = [
    { url : '/home/' , page : <School {...{nav}} /> } ,
    { url : '/general/' , page : <School {...{nav}} /> } ,
    { url : '/login/' , page : logged ?  <School {...{nav}} /> :  <Login {...{nav}} />  } ,
    { url : '/' , page : <School {...{nav}} /> } ,
    { url : '/add-student/' , page : <AddStudent {...{nav}} /> } ,
    { url : '/students/' , page : <SchoolStudents {...{nav}} /> } ,
    { url : '/classrooms/' , page : <SchoolClassrooms {...{nav}} /> } ,
    { url : '/add-classroom/' , page : <AddClassroom {...{nav}} /> } ,
    { url : '/teachers/' , page : <SchoolTeacher {...{nav}} /> } ,
    { url : '/add-teacher/' , page : <AddTeacher {...{nav}} /> } ,
    { url : '/subjects/' , page : <SchoolSubjects {...{nav}} /> } ,
    { url : '/add-subject/' , page : <AddSubject {...{nav}} /> } ,
    { url : '/settings/' , page : <SchoolSettings {...{nav}} /> } ,
    { url : '/school-timetable/' , page : <SchoolTimeTable {...{nav}} /> } ,
    { url : '/add-division/' , page : <AddDivision {...{nav}} /> } ,
    { url : '/divisions/' , page : <SchoolDivisions {...{nav}} /> } ,
    { url : '/school-calendar/' , page : <SchoolCalendar {...{nav}} /> } ,
    { url : '/edit-subject/:id' , page : <EditSchoolSubject {...{nav}} /> } ,
    { url : '/add-parent/:id' , page : <AddParent {...{nav}} /> } ,
    { url : '/edit-parent/:id' , page : <EditParent {...{nav}} /> } ,
    { url : '/dismissed-students/' , page : <DismissedStudents {...{nav}} /> } ,
    { url : '/edit-student/:id' , page : <EditStudent {...{nav}} /> } ,
    { url : '/edit-teacher/:id' , page : <EditTeacher {...{nav}} /> } ,
    { url : '/dismissed-teachers/' , page : <DismissedTeachers {...{nav}} /> } ,
    { url : '/edit-classroom/:id' , page : <EditClassroom {...{nav}} /> } ,
    { url : '/edit-division/:id' , page : <EditDivision {...{nav}} /> } ,
    { url : '/School-announcements/' , page : <SchoolAnnouncements {...{nav}} /> } ,
    { url : '/add-announcement/' , page : <AddAnnouncement {...{nav}} /> } ,
    { url : '/edit-announcement/:id' , page : <EditAnnouncement {...{nav}} /> } ,
    { url : '/reports/' , page : <SchoolReport {...{nav}} /> } ,
    { url : '/chat/' , page : <SchoolChat {...{nav}} /> } ,
    { url : '/grades/' , page : <SchoolGrades {...{nav}} /> } ,
    { url : '/guidances/' , page : <SchoolGuidances {...{nav}} /> } ,
    { url : '/dismissed-guidances/' , page : <SchoolDismissedGuidances {...{nav}} /> } ,
    { url : '/add-guidance/' , page : <AddGuidance {...{nav}} /> } ,
    { url : '/edit-guidance/:id' , page : <EditGuidance {...{nav}} /> } ,
    { url : '/test/' , page : <Ztest {...{nav}} /> } ,
  ]
  
  const STUDENT_ROUTE = [
    { url : '/home/' , page : <Student {...{nav}} /> } ,
    { url : '/general/' , page : <Student {...{nav}} /> } ,
    { url : '/login/' , page : logged ?  <Student {...{nav}} /> :  <Login {...{nav}} />  } ,
    { url : '/' , page : <Student {...{nav}} /> } ,
    { url : '/questions/:id' , page : <StudentQuestions {...{nav}} /> } ,
    { url : '/student-exams/' , page : <StudentExam {...{nav}} /> } ,
    { url : '/student-announcements/' , page : <StudentAnnouncements {...{nav}} /> } ,
    { url : '/timeTable/' , page : <StudentTimeTable {...{nav}} /> } ,
    { url : '/chat/' , page : <StudnetChat {...{nav}} /> } ,
    { url : '/student-grades/' , page : <StudentGrades {...{nav}} /> } ,
    { url : '/classwork/' , page : <StudentClasswork {...{nav}} /> } ,
    { url : '/comprehension/' , page : <StudentComprehension {...{nav}} /> } ,
    { url : '/assessments/' , page : <StudentAssessments {...{nav}} /> } ,
    { url : '/pop-up-quizes/' , page : <StudentPopupquiz {...{nav}} /> } ,
    { url : '/homework/' , page : <StudentHomework {...{nav}} /> } ,
    { url : '/chat-group/' , page : <StudentChatGroup {...{nav}} /> } ,
    { url : '/questions-answers/:id' , page : <StudentQuestionsAnswers {...{nav}} /> } ,
    { url : '/report/' , page : <StudentReport {...{nav}} /> } ,
    { url : '/calendar/' , page : <StudentCalendar {...{nav}} /> } ,
    { url : '/profile/' , page : <StudentProfile {...{nav}} /> } ,
  ]
  
  const PARENT_ROUTE = [
    { url : '/general/' , page : <Parent {...{nav}} /> } ,
    { url : '/home/' , page : <Parent {...{nav}} /> } ,
    { url : '/' , page : <Parent {...{nav}} /> } ,
    { url : '/login/' , page : logged ?  <Parent {...{nav}} /> :  <Login {...{nav}} />  } ,
    { url : '/chat/' , page : <ParentChat {...{nav}} /> } ,
    { url : '/student-grades/' , page : <ParentGrades {...{nav}} /> } ,
    { url : '/assessments/' , page : <ParentAssessments {...{nav}} /> } ,
    { url : '/timeTable/' , page : <ParentTimeTable {...{nav}} /> } ,
    { url : '/reports/' , page : <ParentReport {...{nav}} /> } ,
    { url : '/teachers/' , page : <ParentTeachers {...{nav}} /> } ,
    { url : '/questions/:id' , page : <ParentQuestions {...{nav}} /> } ,
    { url : '/questions-answers/:id' , page : <ParentQuestionsAnswer {...{nav}} /> } ,
    { url : '/student-announcements/' , page : <ParentAnnouncements {...{nav}} /> } ,
    { url : '/school-chat/' , page : <ParentSchoolChat {...{nav}} /> } ,
    { url : '/parent-medical-docs/' , page : <ParentMedicalDocs {...{nav}} /> } ,
    { url : '/add-medical-doc/' , page : <ParentAddMedicalDoc {...{nav}} /> } ,
    { url : '/student-docs/' , page : <ParentStudentDocs {...{nav}} /> } ,
    { url : '/parent-student-docs/' , page : <ParentAddStudentDoc {...{nav}} /> } ,
    { url : '/guidance-chat/' , page : <ParentGuidanceChat {...{nav}} /> } ,
    { url : '/calendar/' , page : <ParentCalendar {...{nav}} /> } ,
  ]

  const GUIDANCE_ROUTE = [
    { url : '/timeTable/' , page : <GuidanceTimeTable {...{nav}} /> } ,
    { url : '/home/' , page : <Guidance {...{nav}} /> } ,
    { url : '/login/' , page : logged ?  <Guidance {...{nav}} /> :  <Login {...{nav}} />  } ,
    { url : '/general/' , page : <Guidance {...{nav}} /> } ,
    { url : '/' , page : <Guidance {...{nav}} /> } ,
    { url : '/teachers/' , page : <GuidanceTeachers {...{nav}} /> } ,
    { url : '/reports/' , page : <GuidanceReport {...{nav}} /> } ,
    { url : '/student-grades/' , page : <GuidanceGrades {...{nav}} /> } ,
    { url : '/medical-docs/' , page : <GuidanceMedicalDocs {...{nav}} /> } ,
    { url : '/student-docs/' , page : <GuidancePersonalDocs {...{nav}} /> } ,
    { url : '/edit-docs/:id/:type' , page : <GuidanceEditDoc {...{nav}} /> } ,
    { url : '/chat/' , page : <GuidanceChat {...{nav}} /> } ,
    { url : '/student-announcements/' , page : <GuidanceSchoolAnnouncements {...{nav}} /> } ,
    { url : '/calendar/' , page : <GuidanceCalendar {...{nav}} /> } ,
  ]
  
    useEffect(() => {
        if (userType === 'master') {
            setItems(ADMIN_ROUTE)
          } else if (userType === 'teacher') {
            setItems(TEACHER_ROUTE)
          } else if (userType === 'student') {
            setItems(STUDENT_ROUTE)
          } else if ( userType === 'school' ) {
            setItems(SCHOOL_ROUTE)
          } else if (userType === 'guidance') {
            setItems(GUIDANCE_ROUTE)
          } else if (userType === 'parent') {
            setItems(PARENT_ROUTE)
          } else {
            setItems([{ url : '/login' , page : <Login {...{nav}} /> }])
          }
          setLoading(false)
    } , [])

    if (!loading)
    return   (
      <Routes >   
        <Route >
          {itmes?.map((item , key) => ( <Route key={key} path={item?.url} element={item?.page} {...{nav}} /> ))}
        </Route>
      </Routes>
    )  
}

export default Router

