import { Box, Button, CardMedia, Grid, InputBase, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useData } from '../../config/Provider'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import AddIcon from '@mui/icons-material/Add';
import { Style } from './Style';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';

const TextInput = ({value,set,placeholder,type,req}) => {

    const Style = {
        Input : {
            input : {color:'primary.text' , },
            borderWidth:1,
            borderStyle:'solid',
            borderColor:req ? 'red' : 'primary.border',
            backgroundColor:'primary.main' , 
            '&:hover' : {  backgroundColor : 'primary.main'},
            width:'100%' ,
            padding:1,
        } , 
    }
    
    return (
        <InputBase type={type} value={value}   onChange={(e) => set(e.target.value)} placeholder={placeholder} sx={Style?.Input} variant="outlined" autoComplete='off'/>
    )
}

const AddAssessment = ({nav}) => {
    
    const [title, setTitle] = useState('')
    const [titleReq, setTitleReq] = useState(false)
    const [score, setScore] = useState(null)
    const [scoreReq, setScoreReq] = useState(false)
    const [option, setOption] = useState('')
    const [optionReq, setOptionReq] = useState(false)
    const { lang } = useData()
 
    const options = [
        {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
        {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
        {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
        {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/dictonary.png'} , 
        {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/exam.png'} , 
    ]

    const submit = async () => {
        
        if ( !title?.trim() || !title || !score?.trim() || score == 0 || !option?.trim() ) {
            setTitleReq(!title?.trim() ? true : false )
            setScoreReq(!score?.trim() || score == 0  ? true : false )
            setOptionReq( !option?.trim() ? true : false )
            return false
        }

        await API.post( `/add-template/` , { score:score,title:title,option:option  } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                nav(`/assessment-questions/${response?.data?.id}`)
            } else {
                console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    return (
        <Box m={5} sx={Style?.Container}>
            <Box sx={Style?.inputsContainer}>
                <Box sx={{width:{xs:'100%',md:'50%'}}}>
                    <TextInput {...{value:title,set:setTitle,placeholder:lang?.title_,type:'text',req:titleReq}}/>
                </Box>
                <Box sx={{width:{xs:'100%',md:'50%' }}} mt={2}>
                    <TextInput {...{value:score,set:setScore,placeholder:lang?.score_,type:'number',req:scoreReq}}/>
                </Box>
            </Box>
     
            <Box sx={{width:'80%' }}>
                <Grid container mt={1}>
                    {options?.map((item,key) => (
                        <Grid item={true} xs={12} sm={12} md={2.4} lg={2.4} xl={2.4} sx={{borderBottom:optionReq ? '1px solid red' : ``}}>
                            <Box sx={Style?.OptionCard} onClick={() => setOption(item?.value)}>
                                <Box sx={{textAlign:'right'}}>
                                    {option === item?.value ? (<CheckCircleOutlineIcon sx={{color:'primary.blue'}} />):(<PanoramaFishEyeIcon sx={{color:'primary.orange'}} />)}
                                </Box>
                                <Box sx={Style?.OptionCardContainer}>
                                    <CardMedia component="img" image={item?.image} alt="LOUNX logo" sx={Style?.CardMedia}/>
                                    <Typography color='primary.text' fontSize={20}>{item?.title}</Typography>
                                </Box>
                            </Box>            
                        </Grid>
                    ))}
                </Grid>  
            </Box>

            <Box sx={{width:'80%' , mt:10 }}>
                <Button sx={Style?.CreateBtn} variant="contained" startIcon={<AddIcon />} onClick={submit}>
                    {lang?.create_}
                </Button>
            </Box>
  
        </Box>
    )
}

export default AddAssessment