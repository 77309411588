import React, { useEffect, useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import { useData } from '../../config/Provider'
import { DataTable, SelectClassroom, TableHeader, TextInput } from '../../components'
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
const Assessments = ({nav}) => {

  const [data , setData] = useState([])
  const [search , setSearch] = useState('')
  const {lang,socket,dir} = useData()
  const [ classroom , setClassroom ] = useState(0)
  const [ template , setTemplate ] = useState(0)
  const [ openDialog , setOpenDialog ] = useState(false)

  const getData = async () => {
    await API.get( `/templates-list/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getSearch = async () => {
    await API.get( `/search-templates-list/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (search?.length > 0) {
      getSearch()
    } else {
      getData()
    }
  } , [search?.length])

  const assessmentsTyps = [
    {title  : lang?.assessments_ , value : 'assessment' } , 
    {title  : lang?.class_work_ , value : 'classwork' } , 
    {title  : lang?.comprehension_, value : 'comprehension' } , 
    {title  : lang?.pop_quiz_  , value : 'popup' } , 
    {title  : lang?.homework_, value : 'homework' } , 
  ]

  const assIng = (e) => {
    setTemplate(e)
    setOpenDialog(true)
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]} > {v.template_title} </TableCell> 
        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]} > {v.template_score} </TableCell> 
        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]} >  {assessmentsTyps?.find((i) => i.value == v.template_type)?.title } </TableCell> 
        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]} > 
            <IconButton onClick={() => assIng(v.template_id)} aria-label="edit">
              <ArrowForwardIcon sx={{color:'primary.orange'}}/>
            </IconButton>
        </TableCell> 
    
        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]}> 
          <IconButton onClick={() => nav(`/assessment-questions/${v.template_id}`)} aria-label="edit">
            <PsychologyAltIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

        <TableCell sx={[Style?.Column,{textAlign:dir==='rtl'?'right':'left'}]}> 
          <IconButton onClick={() => nav(`/edit-assessment/${v.template_id}`)} aria-label="edit">
            <ModeEditOutlineIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

      </TableRow>
    )))
  } 

  const page = () => {
    nav('/add-assessment/')
  }

  const Header = [
    {title : lang?.title_},
    {title : lang?.score_},
    {title : lang?.type_},
    {title : lang?.assign_},
    {title : lang?.questions_},
    {title : lang?.edit_ },
  ]

  const assignClass = async () => {
 
    if ( classroom == 0 || template == 0 ) {
      return false
    }

    await API.post( `/assign-question-template/`, { classroom_id : classroom , template_id : template } , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setOpenDialog(false)
          const temInfo = data?.find((i) => i.template_id === template)
          if ( temInfo?.template_type === "popup" ) {
            socket?.emit( 'revPopupRequest' , { classroom_id : classroom , template_id : template } )
          }
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  } 
 
  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.assessments_ , buttonTitle :lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <SelectClassroom {...{setOpen:setOpenDialog,open:openDialog,classroom , setClassroom,func:assignClass}}/>
      <TextInput {...{set:setSearch,value:search,placeholder:lang?.search_}} />
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Assessments