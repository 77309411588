export const Style={
    Container : {
        height: {sm : 600 , xs:600 , md : 700, lg : 700, xl : 700}  ,
        backgroundColor:'primary.main' ,
        width:'90%',
        margin:'auto',
        marginTop:3 ,
        borderRadius:2,
        borderColor:'primary.border' ,
        borderWidth:0.5,
        borderStyle:'solid'
    } ,
    PageHeader : {
        width:'90%',
        margin:'auto',
        marginTop:3 ,
        display:'flex',
        justifyContent:'space-between',
        flexDirection:'row',
        alignItems:'center',
    } ,
    ChatSideBar : {
        height: {sm : 600 , xs:600 , md : 700, lg : 700, xl : 700}  ,
        width:'100%',
        padding:0,
        margin:0,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        alignItems:'center',
        borderColor:'primary.border' ,
        borderWidth:0,
        borderRightWidth:0.5,
        borderStyle:'solid'
    } ,
    userCard : {
        minHeight:70,
        width:'97%',
        backgroundColor:'primary.main',
        borderRadius:2,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'row',
        alignItems:'center',
        marginTop:0.8,
        borderColor:'primary.border' ,
        borderWidth:0.5,
        borderStyle:'solid'
    } , 
    userCardProfilePic : {
        bgcolor: 'primary.orange'
    } ,
    userCardInfo : {
        marginLeft:1 ,
    } ,
    userCardInfoUsername : {
        fontSize:15 ,
        color:'primary.text',
        textAlign:'left'
    } ,
    userCardInfoMsg : {
        fontSize:12 ,
        color:'primary.text',
        textAlign:'left'
    } ,
    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'95%' ,
        padding:1
    } , 
    InputContainer : {
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        alignItems:'center',
        width:'100%' , 
        marginTop:2 ,
        height:58
    },
    ChatItemsComtainer : {
        borderStyle:'solid',
        height:'auto',
        width:'100%',
        marginTop:2,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        alignItems:'center',
        overflow:'scroll',
        borderColor:'primary.border' ,
        borderWidth:0,
        borderTopWidth:1,
    } ,
    ChatRightBar : {
        height: {sm : 600 , xs:600 , md : 700, lg : 700, xl : 700}  ,
        width:'100%',
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        flexDirection:'column',
    },
    ChatHeader : {
        height:109,
        paddingLeft:5,
        width:'100%',
        borderColor:'primary.border' ,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'row',
        alignItems:'center',
    } ,
    ChatUsernameInfo : {
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        padding:2
    } ,
    ChatUsername : {
        fontSize:20,
        color:'primary.text'
    } ,
    ChatUserType : {
        color:'primary.text',
        fontSize:10
    } , 
    ChatTexts : {
        height:'100%',
        width:'100%',
        borderColor:'primary.border' ,
        borderWidth:0,
        borderStyle:'solid',
        borderTopWidth:1,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection:'column',
        alignItems:'center',
        overflow:'scroll',
    } , 
    textMsgContainer : {
        width:'100%',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        
    },
    textMsgInnerContainer : {
        borderColor:'primary.border' ,
        borderWidth:0,
        borderStyle:'solid',
        borderWidth:1,
        padding:1,
        borderRadius:2,
        marginTop:1,
        maxWidth:'50%'
    } , 
    myText : {
        justifyContent : 'flex-end' ,
        marginRight:3
        
    } ,
    NotMyText : {
        justifyContent : 'flex-start' ,
        marginLeft:3
    } ,
    userCardInfoDate : {
        fontSize:9 ,
        color:'primary.text'
    } ,
    TypeInputSectionContainer : {
        borderColor:'primary.border' ,
        borderWidth:0,
        borderStyle:'solid',
        borderTopWidth:1,
        display:'flex',
        justifyContent:'space-around',
        flexDirection:'row',
        alignItems:'center',
        margin:1,
        padding:1,
        width:'98%',
    },
    TypeInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'95%' ,
        padding:1
    }

}
