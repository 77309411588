import { Avatar, Backdrop, Card, CircularProgress, Grid, IconButton, InputBase, ListItemButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Style } from './Style';
import { API, CalculateTime, HandleCreate64ForState, downloadFile } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
import { AlertsDialog, Btn, MediaDialog } from '../../components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from 'axios';

const SchoolChat = () => {

    const [ open , setOpen ] = useState(false)
    const [ isParent , setIsParent ] = useState(false)
    const [ users , setUsers ] = useState([])
    const [ user , setUser ] = useState(0)
    const [ msgs , setMsgs ] = useState([])
    const [ msg , setMsg ] = useState([])
    const [ search , setSearch ] = useState([])
    const [ file , setFile ] = useState(null)
    const [ show , setShow ] = useState(false)
    const [ loading , setLoading ] = useState(false)
    const chatContainerRef = useRef(null)

    const [ active , setActive ] = useState({
        name : '',
        type : '',
        pic : '',
        parent : null
    })

    const {socket,info,lang} = useData()

    const UserCard = ({item},key) => (
        <ListItemButton key={key} sx={[Style?.userCard , { borderColor:  active?.name === item?.parent_name? 'primary.orange' :'primary.border' }] } elevation={3} onClick={() => start(item?.student_id , item)}>
            <Avatar src={item?.student_profile_pic} sx={Style?.userCardProfilePic}>{item?.parent_name?.substr(0,1)}</Avatar>
            <Box sx={Style?.userCardInfo}>
                <Typography sx={Style?.userCardInfoUsername} > 
                    {item?.parent_name} {item?.student_lname} 
                </Typography>
                {/* <Typography sx={Style?.userCardInfoMsg}> {item?.msg}... </Typography> */}
            </Box>
        </ListItemButton>
    )

    const ChatText = ({item},key) => (
        <Box key={key} sx={[Style?.textMsgContainer , item?.chat_sender_id === info?.id ? Style?.myText : Style?.NotMyText ] } elevation={3}>
           <Box sx={[Style?.textMsgInnerContainer ,  item?.chat_sender_id === info?.id ? {borderBottomRightRadius : 0} : {borderBottomLeftRadius : 0} , { overflowWrap:'break-word' } ] } elevation={3}>
                {item?.chat_type === 'text' ? 
                    <>
                        <Typography sx={Style?.userCardInfoUsername}> {item?.chat_contnet} </Typography>
                        <Typography sx={Style?.userCardInfoDate}> {item?.date === lang?.now_ ? lang?.now_ :CalculateTime(item?.chate_date)} </Typography>
                    </>
                
                : 
                
                    <>
                        <Box sx={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            justifyContent:'space-between',
                            wordBreak:'break-word',
                            width:200 ,
                            cursor:'pointer'
                        }} 
                            onClick={() => handleDownload(item?.chat_contnet)} >
                            <Typography color='primary.text' > {item?.chat_contnet} </Typography>
                            <GetAppIcon sx={{fontSize:30,color:'primary.orange'}} />
                        </Box>
                    </>
                
                }
                

           </Box>
        </Box>
    )

    const getUsers = async () => {
        await API.get( `/school-chat-parents/` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setUsers(response?.data?.data)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    const getSearch = async () => {
        await API.get( `/search-teacher-chat-users/${search}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setUsers(response?.data?.data)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        if (search?.length > 0) {
            getSearch()
        } else {
            getUsers()
        }
    }, [search?.length])
 
    
    const back = () => {
        setOpen(false)
    }

    const start = async (id,item) => {
        setUser(id)
        await API.get( `/get-chat-school-parent/${id}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setMsgs(response?.data?.data)
            setOpen(true)
            setActive({
                name : `${item?.parent_name}`,
                type : lang?.parent_,
                id : item?.parent_id,
                parent : response?.data?.check 
            })
            setIsParent(false)
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )

    }
 
    const Upload = async () => {
        var data = new FormData();
        data.append(`file` , file?.blob ,  file?.name )
        await API.post(`/school-upload-chat-file/`, data , Vals?.POST_UPLOAD_HEADER )
        .then(function (response) {
            
            console.log(response)

            if (response?.data?.status) {
                
                socket?.emit('SEmessageFile', { 
                    msg : response?.data?.file , 
                    id : info?.id , 
                    school : info?.id , 
                    getter : user  ,
                    type : 'user' ,
                    chat_type : 'file' 
                })
        
                setFile(null)
                setShow(false)
                setLoading(false)

            } 


        }).catch(function (error) {
            console.log(error)
        });
    }


    const handleDownload = (file) => {
        const downloadUrl = `${Vals?.M_API}/download/${file}`;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sendMsg =   () => {
        if (typeof msg !== 'string' || !msg.trim()) {
            return false;
        }
        socket?.emit('SEmessage', { 
            msg : msg , 
            id : info?.id , 
            school : info?.id , 
            getter : user  ,
            type : 'user' ,
            chat_type : 'text' 
        })
        setMsg('')
    }

    useEffect(() => {
        socket?.on('RCmessage', (req) => {

            console.log(req)
            
            setMsgs((e) => {
                return[...e , {
                    chat_contnet : req?.chat_contnet ,
                    chat_sender_id : req?.chat_sender_id ,
                    chat_date:req?.chat_date ,
                    getter : req?.getter ,
                    chat_type : req?.chat_type 
                }]
            })

            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
    
        })
    } , [])

    const selectFileUpload = HandleCreate64ForState(setFile,setShow)

    const AlertBox = () => (
        <Box sx={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-around',
            wordBreak:'break-word',
            width:400
        }}>
            <Typography color='primary.text' > {file?.name} </Typography>
            <FileCopyIcon sx={{fontSize:50,color:'primary.text'}} />

        </Box>
    )

    const handleClose = () => {
        setFile(null)
        setShow(false)
    }


    return (
        <>

<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={loading}
//   onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>

        <MediaDialog {...{
            open:show,
            setOpen:setShow,
            // func:sendFile,
            func:Upload,
            alertContent : <AlertBox /> ,
            handleClose : handleClose
        }} />
            <Box sx={[Style?.PageHeader ]} elevation={0} spacing={0}>

                <Box>
                    <Typography sx={{fontSize:18,color:'primary.text'}}> {lang?.messenger_} </Typography>
                    <Typography sx={{fontSize:30,color:'primary.text'}}> {lang?.chat_app_} ( {lang?.parents_} ) </Typography>
                    <Box>
                        {open && (
                            <IconButton onClick={back} sx={{display:{ md:'none' } , }} >
                                <CloseOutlinedIcon sx={{color:'primary.text'}} />
                            </IconButton>
                        )}
                    </Box>
                </Box>


                
            </Box>
        
            <Card sx={[Style?.Container ]} elevation={0} spacing={0}>
                
                <Grid container>
            
                    <Grid item xs={12} sm={12} md={2.2} lg={2.2} xl={2.2} sx={{display:{ xs: open ? 'none' : 'flex' ,sm: open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                        <Box sx={Style?.ChatSideBar}>
                            <Box sx={Style?.InputContainer}>
                                <InputBase onChange={(e) => setSearch(e.target.value)} value={search} 
                                    placeholder={lang?.search_} sx={Style?.SearchInput} variant="outlined" autoComplete='off'/>
                            </Box>
                            <Box sx={Style?.ChatItemsComtainer}>
                                {users?.map((item,key) => (<UserCard key={key} {...{item,key}} />))}
                            </Box>
                        </Box>
                    </Grid> 

                    <Grid item xs={12} sm={12} md={9.8} lg={9.8} xl={9.8}  sx={{display:{ xs: !open ? 'none' : 'flex' ,sm: !open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                        
                        <Box sx={Style?.ChatRightBar}>

                            <Box sx={Style?.ChatHeader}>
                            {active?.name && (
                                 
                                <Box sx={Style?.ChatUsernameInfo}>
                                    <Typography sx={Style?.ChatUsername}> {active?.name} </Typography>
                                    
                                    <Box sx={{
                                        display:'flex',
                                        flexDirection:'row',
                                    }}>
                                        <Typography sx={Style?.ChatUserType}> 
                                            {isParent   ?  lang?.parent_ : active?.type}
                                        </Typography>
                                    
                                    </Box>
                                
                                </Box> 
 
                            )}

        
                            </Box>

                            <Box sx={Style?.ChatTexts}>
                                {msgs?.map((item , key) => ( <ChatText key={key} {...{item,key}} /> ))}
                                <div ref={chatContainerRef}></div>
                            </Box>

                            <Box sx={Style?.TypeInputSectionContainer}>
                                    
                                {active?.name && (
                                    <>
                                        <InputBase onChange={(e)=>setMsg(e.target.value)} value={msg} placeholder={`${lang?.type_}...`}  sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>

                                        <IconButton aria-label="file"component="label" >
                                            <input type='file' hidden={true}  onChange={ selectFileUpload }/>
                                            <AttachFileIcon  sx={{color:"primary.text"}}/>
                                        </IconButton> 

                                        <IconButton aria-label="send" onClick={ sendMsg }>
                                            <SendOutlinedIcon  sx={{color:"primary.text"}}/>
                                        </IconButton>      
                                    </>
                                )}


                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
  )
}

export default SchoolChat