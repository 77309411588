import React from 'react';
import * as XLSX from 'xlsx'; 
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import { useData } from '../../config/Provider';
import Btn from '../btn/Btn';

const GenerateExcelFile = ({headerTitles}) => {

    const { lang } = useData() 
    
    const data = [
        { 
            [headerTitles[0]]: 'adnana@email.com', 
            [headerTitles[1]]: 'first_name1',
            [headerTitles[2]]: 'last_name1',
            [headerTitles[3]]: '05385128915',
            [headerTitles[4]]: '12345678910',
        } ,

        { 
            [headerTitles[0]]: 'lounx@email.com', 
            [headerTitles[1]]: 'first_name2',
            [headerTitles[2]]: 'last_name2',
            [headerTitles[3]]: '05385128915',
            [headerTitles[4]]: '12345678910',
        } ,
    ];
    const generateExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet( data , { header: headerTitles });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'data.xlsx');
    };

    return (
        <Btn {...{func:generateExcel,buttonTitle:lang?.download_excel_}} />
    );
};

export default GenerateExcelFile;
