import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PieController } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useData } from '../../config/Provider';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PieController);

export default function ChartPie({info}) {
  
  const { lang } = useData()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: '',
        color: 'white'
      },
    },
  };

  const data = {
    labels: [ 
              lang?.on_task_ , 
              lang?.participating_ , 
              lang?.cooperative_ , 
              lang?.well_done_ , 
              lang?.respectful_ , 
              lang?.not_organized_ , 
              lang?.hard_to_stay_on_task_ ,
              lang?.uncooperative_ ,
              lang?.disruptive_ , 
              lang?.disrespectful_
            ],
    datasets: [{
      data : [               
        info?.on_task_ , 
        info?.participating_ , 
        info?.cooperative_ , 
        info?.well_done_ , 
        info?.respectful_ , 
        info?.not_organized_ , 
        info?.hard_to_stay_on_task_ ,
        info?.uncooperative_ ,
        info?.disruptive_ , 
        info?.disrespectful_
      ],
      backgroundColor : [ '#007BA7' , '#50C878' , '#87CEEB' ,'#00FF00' , '#40E0D0' , '#E34234' , '#DC143C' , '#8B0000' , '#FF4500' , '#B22222' ],
    }],
  };

  return <Pie options={options} data={data} />;
}
