import React from 'react'
import {Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2'; 
  
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export const data = {
    labels: [ 'Assignments' , 'Exams', 'Quiz', 'Classrooms'],
    datasets: [
        {
            label: '# of Votes',
            data: [20, 18, 5, 13],
            backgroundColor: [
              '#e9a282',
              '#1a76bf',
              '#60b6fb',
              'lightgreen',
            ],
            borderWidth: 0,
            pointHoverRadius: 50,
        },
    ],
};

const ChartPolarArea = () => {
  return (
    <PolarArea data={data} />
  )
}

export default ChartPolarArea