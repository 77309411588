import { Avatar, Grid, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { MultiSelector, TableHeader } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'
import { useData } from '../../config/Provider'

const SchoolSettings = () => {

  const [ data , setData ] = useState([])
  const [ selected , setSelected ] = useState([])
  const { lang } = useData()

  const getData = async () => {
    await API.get( `/working-days/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const setDay = async (id) => {
    await API.post( `/set-day/` , { id : id  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.msg === 'remove') {

          const updatedArray = data.map(item => {
            if (item.wd_id === id) {
              return { ...item, IsOn: "n" };
            }
            return item;
          });

          setData(updatedArray)
        
        } 
        
        if (response?.data?.msg === 'add') {

          const updatedArray = data.map(item => {
            if (item.wd_id === id) {
              return { ...item, IsOn: "y" };
            }
            return item;
          });

          setData(updatedArray)

        }

    })
    .catch((e) => console.log(e) )
  }

  useEffect(()=> {
    getData()
  } , [])
 
  return (
    <Box sx={Style?.Container}>

      <Box sx={Style?.ItemContainer} mt={2}>
   
        <TableHeader {...{ title:lang?.working_days_  }} />

        <Box sx={{ width:'100%' , display:'flex' , justifyContent:'space-around' , alignItems:'center' , flexDirection:'row' }}>

          {data?.map((v,k) => 
            <Box key={k} sx={[Style?.DayContainer , { borderColor : v.IsOn === 'y' ? 'primary.orange' : 'primary.border' }]} onClick={ () => setDay(v.wd_id) } >
              <Typography variant='h5' color="primary.text"> {v.wd_title} </Typography>
            </Box>
          )}

        </Box>
         
      </Box>

    </Box>
  )
}

export default SchoolSettings