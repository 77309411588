export const Style = {
    Container : {
        height:'100%',
        width:'100%',
        
        backgroundColor:'primary.main',
        position:'fixed',
        zIndex:100,
        overflow:'scroll'
    } ,

    AvatarCon : {
        width:'80%',
        borderWidth:1,
        borderColor:'primary.border',
        borderStyle:'solid',
        p:1,
        borderRadius:1,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        cursor:'pointer',
     } ,

     AvatarMCon : {
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
     } ,

    ListItem : {
        borderRadius : 20 , 
        pt:0.7,
        pb:0.7,
        mt:1 ,
        mb:1 ,
    } ,
    ItemColor : {
        color:'primary.text' ,
        textAlign:'center',
        alignItems:'center',
        justifyContent : 'center' ,
        display:'flex',
    } ,
    mainBtn : {
        display:'flex',
        flexDirection : 'row' ,
        justifyContent : 'center' ,
        alignItems:'center',
        // backgroundColor : '#c1c1c1' ,
        // mt:0.5,
        textAlign:'center',
        width:'90%'
    }
}