import React, { useEffect, useState } from 'react'
import { Style } from './Style'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Box, Grid, Typography } from '@mui/material'
import { useData } from '../../config/Provider'
import { TableHeader, TextInput } from '../../components'
const Videos = () => {

    const {lang} = useData()
    
    const [guidanceVideo, setGuidanceVideo] = useState('')
    const [teacherVideo, setTeacherVideo] = useState('')
    const [studentVideo, setStudentVideo] = useState('')
    const [schoolVideo, setSchoolVideo] = useState('')
    const [parentVideo, setParentVideo] = useState('')
    const [channel, setChannel] = useState('')

    const inputs = [
        { val : guidanceVideo , set : setGuidanceVideo , type : 'text' , placeholder : lang?.url_ , title : lang?.guidance_} ,
        { val : teacherVideo , set : setTeacherVideo , type : 'text' , placeholder : lang?.url_ , title : lang?.teacher_ } ,
        { val : studentVideo , set : setStudentVideo , type : 'text' , placeholder : lang?.url_ , title : lang?.student_ } ,
        { val : schoolVideo , set : setSchoolVideo , type : 'text' , placeholder : lang?.url_ , title : lang?.school_ } ,
        { val : parentVideo , set : setParentVideo , type : 'text' , placeholder : lang?.url_ , title : lang?.parent_ } ,
        { val : channel , set : setChannel , type : 'text' , placeholder : lang?.url_ , title : lang?.channel_ } ,
    ] 
 
    const getData = async () => {
        await API.get( `/get-videos/` , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                setSchoolVideo( response?.data?.data?.find((v) => v.setting_key === 'school_video' )?.setting_value )
                setStudentVideo(response?.data?.data?.find((v) => v.setting_key === 'student_video' )?.setting_value)
                setTeacherVideo(response?.data?.data?.find((v) => v.setting_key === 'teacher_video' )?.setting_value)
                setGuidanceVideo(response?.data?.data?.find((v) => v.setting_key === 'guidance_video' )?.setting_value)
                setParentVideo(response?.data?.data?.find((v) => v.setting_key === 'parent_video' )?.setting_value)
                setChannel(response?.data?.data?.find((v) => v.setting_key === 'channel' )?.setting_value)
            } else {
            console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    const save = async () => {
        await API.post( `/save-videos/` , { 
            guidanceVideo : guidanceVideo , 
            teacherVideo : teacherVideo , 
            studentVideo : studentVideo , 
            parentVideo : parentVideo , 
            schoolVideo : schoolVideo ,
            channel : channel
        } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {

            } else {
                console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        getData()
    } , [])
  
  return (
    <Box sx={Style?.Container}>
        <Grid container>
            <TableHeader {...{title:lang?.videos_ , buttonTitle : lang?.save_ , page : save }} />
            <Grid iten={true} xs={12} sm={12} md={6} lg={6} xl={6} >
                {inputs?.map((item,key) => (
                    <Box mt={1} key={key}>
                        <Typography> {item?.title} </Typography>
                        <TextInput {...{value:item?.val,set:item?.set,placeholder:item?.placeholder,type:item?.type}}/>
                    </Box>
                ))}
            </Grid>
        </Grid>
    </Box>
  )
}

export default Videos