export const Style = {
    ImageContainer : {
        display:'flex',
        // backgroundColor:'#fafafa',
        justifyContent:'center',
        alignItems:'center' ,
        flexDirection : 'column',
        height:'92%',
    } ,
    FormContainer : {
        display:'flex',
        alignItems:'center' ,
        justifyContent:'center',
        padding:5,
        height:'92%',
        flexDirection : 'column',
        borderStyle:'solid',
        borderColor:'#e7e7e7',
        borderWidth:0,
        borderLeftWidth:1,
    } ,
 
    TypeInput : {
        mt:2,
        input : {color:'#676767' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'#e7e7e7',
        backgroundColor:'#fafafa' , 
        '&:hover' : {  backgroundColor : '#fafafa'},
        width:'95%' ,
        padding:1,
        borderRadius:2
    },

    alertStyle : {
        width:'87%' ,
    } , 

    Button : {
        width:'100%',        
        alignItems:'center' ,
        justifyContent:'center',
        display:'flex',
        mt:3,        
    } ,

    logo : {
        width:800 ,
    }
 
}