import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';
import { Box, Typography } from '@mui/material';
import { useData } from '../../config/Provider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const  ChooseLangs = ({open , set}) => {
    const {setTriggerLang,triggerLang,setLangKey,setLangDiloag} = useData()
    const [data, setData] = React.useState([])
    const{lang}=useData()
    const getData = async () => {
        await API.get( `/available-languages/` , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                setData(response?.data?.data)
            } else {
                console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
    }

    React.useEffect(() => {
        getData()
    } , [])

    const handleClose = () => {
        set(false);
    };

    const Style = {
        tag : {
            p:1,
            borderRadius:1,
            mr:1,
            ml:1,
            float:'left',
            borderStyle:'solid',
            borderWidth:1,
            borderColor:'primary.orange',
            cursor:'pointer'
        }
    }

    const [ savedlangKey , setSavedLangKey ] = useLocalState("langKey");

    const changeLanguage = (id) => {
        setSavedLangKey(id)
        setLangKey(id)
        setTriggerLang(!triggerLang)
        setLangDiloag(false)
        window.location.reload(false);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle>{"Select Language"}</DialogTitle>
            <DialogContent sx={{width:700}}>
                    
                {data?.map((item,key) => (
                    <Box 
                        sx={Style?.tag} 
                        key={key} 
                        onClick={() => changeLanguage(item?.lang_id)}>
                            <Typography>{item?.lang_title}</Typography>
                    </Box>
                ))}      
                    
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{lang?.close_}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChooseLangs