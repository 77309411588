import {  InputBase  } from '@mui/material'
import { Box } from '@mui/system'
import React  from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const AddClassroom = ({nav}) => {
  const [ title , setTitle ] = useState('')
  const [ count , setCount ] = useState()
  const { lang} = useData()
  const page = async () => {
    await API.post( `/add-classroom/` , { title : title , count : count  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/classrooms/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  return (
    <Box p={2} sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.add_classroom_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={title} onChange={(e) => setTitle(e.target.value)} placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase type='number' value={count}  onChange={(e) => setCount(e.target.value)} placeholder={lang?.subjects_per_day_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default AddClassroom