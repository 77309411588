import React from 'react'
import ReactQuill from 'react-quill'
import './style.css'

const TextEditor = ({contnet , setcontnet }) => {

  const  handleChange = (value) => {
    setcontnet(value);
  }
  return (
    <ReactQuill className='width' value={contnet} onChange={handleChange} />
  )
}

export default TextEditor