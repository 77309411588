import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import html2canvas from 'html2canvas/dist/html2canvas';
import jsPDF from 'jspdf/dist/jspdf.es';

export const ExportPdf = ({ title, teacherName, schoolName, questions }) => {
  const pdfRef = React.useRef(null);

  React.useEffect(() => {
    const doc = new jsPDF('portrait', 'pt', 'a4');

    html2canvas(pdfRef.current, { scale:  0.7 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 0, 0);
      doc.save('sample.pdf');
    });

  }, [title, teacherName, schoolName, questions]);

  const styles = {
    container: {
      display:'flex',
      flexDirection: 'column',
      padding: 10,
      fontFamily: 'Helvetica',
      fontSize: 12,
      width:'595px',
    },
    title: {
      textAlign: 'left',
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 20,
      width:'595px',
      alignItems:'center',
    },
    header: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems:'center',
      marginBottom: 10,
    },
    left: {
      textAlign: 'left',
    },
    right: {
      textAlign: 'left',
    },
    question: {
      marginBottom: 5,
    },
    page: {
      position: 'absolute',
      bottom: 10,
      right: 10,
    },
  };

  return (
    <div style={{ width: '595px', height: '842px' }}>
      <Box sx={styles.container} ref={pdfRef}>
        <Box sx={{mb:7}} >
          <Typography style={styles.title}>{title}</Typography>
          <Typography sx={styles.left}>{teacherName}</Typography>
          <Typography sx={styles.right}>{schoolName}</Typography>
        </Box>
        {questions?.map((v, index) => (
          <Box sx={styles.question} key={index}>
            <Typography fontSize={19}>{index+1}- {v?.question}</Typography>
            <Typography sx={styles.page}>{index + 1}</Typography>
          </Box>
        ))}
      </Box>
    </div>
  );
};
