export const Style = {
  Container : {
    mr : 2 ,
    ml : 2 ,
    mt : 2 ,
    p:2,
    backgroundColor:'white',
    border:'2px solid #e4e6e5',
    borderRadius:2,
    float:'left',
    width:'95%'
} ,
    ItemContainer : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      borderRadius:2,
      padding:2,
      height:'auto'
    },
    StudentCard : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      borderRadius:2,
      padding:2,
      height:'auto',
      justifyContnet:'space-between',
      display:'flex',
      alignItems:'center',
      flexDirection:'column',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer'
      },
      margin:2,
      width:'90%'
    } ,
    avatar : {
      height:80,
      width:80,
      bgcolor: 'primary.orange'
    } ,
    Tag : {
      borderRadius:15,
      pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
      float:'left',
      alignItem:'canter',
      display:'flex',
      cursor:'pointer',
      borderWidth:0,
      borderWidth:1,
      borderStyle:'solid',
    } ,
  }