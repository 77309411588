export const Style = {
    SearchInput : {
        input : {color:'primary.text'  },
        borderWidth:1,
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    } ,
    Input : {
        input : {color:'primary.text' , },
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
     } , 

    inputCon : {
        height:50,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-around',
        border:'1px solid lightgray',
        borderRadius:3,
        pr:1,
        pl:1,
    } ,

    DropBtn : {
        border:'1px solid #f892a8',
        color:'#f892a8',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        p:1,
        borderRadius:3,
        cursor:'pointer'
    } ,

    Avatar : {
        borderRadius:3,
        cursor:'pointer'
    } ,

    AvatarCon : {
        // width:220,
        borderWidth:1,
        borderColor:'primary.border',
        borderStyle:'solid',
        p:1,
        borderRadius:1,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        cursor:'pointer',
     } ,

     AvatarMCon : {
        // width:100,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
     } ,

}