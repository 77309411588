import { Avatar, Grid, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useState } from 'react'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useData } from '../../config/Provider'
import ClearIcon from '@mui/icons-material/Clear';

const Attendance = () => {
  const [ data , setData ] = useState([])
  const [ classroomData , setClassroomData ] = useState([])
  const [ classroom , setClassroom ] = useState(0)
  const [ title , setTitle ] = useState('')
  const [ search , setSearch ] = useState('')
  const {lang} = useData()
  const getData = async () => {
    await API.get( `/teacher-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroom = async ()  => {
    await API.get( `/teacher-classroom-attend/${classroom}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getSearch = async () => {
    await API.get( `/teacher-search-classrooms/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (classroom > 0) {
      getClassroom()
    }
  } , [classroom])

  useEffect(() => {
    if (search?.length > 0 ) {
      getSearch()
    } else {
      getData()
    }
  }, [search?.length])

  const ClassRoomData = () => {

    const StudentCard = ({values}) => {

      const [ check , setCheck ] = useState(values?.IsAttend==='yy'?true:false)

      const checked = async () => {
        await API.post( `/attend-student/` , { id : values?.student_id , classroom_id : classroom } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
              setCheck(response?.data?.val)
              console.log(response?.data?.val)
            } else {
              console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
      }
      return(
        <ListItem sx={[Style?.StudentCard,{cruser:'pointer'}]} onClick={checked}>
          <Avatar size="larg" sx={Style?.avatar}>{values?.student_fname?.substr(0,1)}</Avatar>
          <Box mt={2} sx={{justifyContent:'space-between',display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
            <Typography color='primary.text' variant='p'>{values?.student_fname} {values?.student_lname}</Typography>
            {check ? <ClearIcon  sx={{color:'primary.blue',fontSize:25}} /> :<CheckCircleOutlineOutlinedIcon  sx={{color:'primary.orange',fontSize:25}}/>}
          </Box>
        </ListItem>
      )
    }
    return (
      <>
        <TableHeader  {...{title:title, buttonIcon : '' , buttonTitle:lang?.back_ , page:() => setClassroom(0)}}/>
          <Grid container>
            {classroomData?.length > 0 ? classroomData?.map((values,k) => ( 
                <Grid item={true} key={k} xs={12} sm={12} md={4} lg={3} xl={3}>
                  <StudentCard  {...{values}} />
                </Grid>
            )) : <Typography color='primary.text'> {lang?.no_students_found_} </Typography>}
          </Grid>
      </>
    )
  }
 
  const Tag = ({item}) => (
    <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
       onClick={() => Select(item?.division_id , `${item?.cr_title} / ${item?.division_title}`)} >
        <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
    </Box>
  )

  const Select = (classroom , title) => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    const currentTime = currentDate.toLocaleTimeString('en-US', { timeStyle: 'short' });
    
    setClassroom(classroom)
    setTitle(`${lang?.attendance_for_} ${title} ${lang?.in_} ${dayOfWeek}, ${currentDate.toDateString()} ${currentTime}`)
  }

  return (
    <Box sx={Style?.Container}>

      {classroom !== 0 ? 
        <ClassRoomData />
      : 
        <>
          <TextInput {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
          <Box mt={3}>
            {data?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
          </Box>
        </>
      }

    </Box>
  )
}

export default Attendance