import axios from 'axios';
import moment from 'moment/moment';
import { useState,useEffect, useCallback } from 'react'
import Vals from './Vars'
axios.defaults.withCredentials = true

export const useLocalState = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      const storedValue = localStorage.getItem(key);
      return storedValue === null ? defaultValue : JSON.parse(storedValue);
    });
  
    useEffect(() => {
      const listener = (e) => {
        if (e.storageArea === localStorage && e.key === key) {
          setValue(JSON.parse(e.newValue));
        }
      };
      window.addEventListener("storage", listener);
      return () => {
        window.removeEventListener("storage", listener);
      };
    }, [key]);
  
    const setValueInLocalStorage = (newValue) => {
      setValue((currentValue) => {
        const result =
          typeof newValue === "function" ? newValue(currentValue) : newValue;
        localStorage.setItem(key, JSON.stringify(result));
        return result;
      });
    };
    return [value, setValueInLocalStorage];
};

export const API = axios.create({
  baseURL: Vals?.API,
  timeout: 60000 , 
});

export const validateEmail = (email) => {
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  return emailPattern.test(email);
};

export const generatePassword = (email) => {
  const numbers = '123456789';
  const e = email.substr(0, 4)
  const reversedEmail = e.split('').reverse().join('');
  
  let interleavedString = '';
  for (let i = 0; i < reversedEmail.length; i++) {
    interleavedString += reversedEmail[i];
    if (i < numbers.length) {
      interleavedString += numbers[numbers.length - 1 - i];
    }
  }

  return interleavedString
}

export async function downloadFile(url) {
  try {
    const response = await axios.get( url, {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream',
      },
      withCredentials: true, 
    });

    if (response.status !== 200) {
      throw new Error('File download failed');
    }

    return response.data;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

export const CalculateTime = (datetimeValue) => {
  const currentTime = moment();
  const providedTime = moment(datetimeValue);

  if (!providedTime.isValid()) {
    return 'Invalid date'; // Handle invalid date format
  }

  const yearsDiff = currentTime.year() - providedTime.year();
  const monthsDiff = currentTime.month() - providedTime.month();
  const daysDiff = currentTime.date() - providedTime.date();
  const hoursDiff = currentTime.hour() - providedTime.hour();
  const minutesDiff = currentTime.minute() - providedTime.minute();
  const secondsDiff = currentTime.second() - providedTime.second();

  if (yearsDiff > 0) {
    return `${yearsDiff > 1 ? yearsDiff + ' years ago' : '1 year ago'}`;
  } else if (monthsDiff > 0) {
    return `${monthsDiff > 1 ? monthsDiff + ' months ago' : '1 month ago'}`;
  } else if (daysDiff > 0) {
    return `${daysDiff > 1 ? daysDiff + ' days ago' : '1 day ago'}`;
  } else if (hoursDiff > 0) {
    return `${hoursDiff > 1 ? hoursDiff + ' hours ago' : '1 hour ago'}`;
  } else if (minutesDiff > 0) {
    return `${minutesDiff > 1 ? minutesDiff + ' minutes ago' : '1 minute ago'}`;
  } else if (secondsDiff >= 0) {
    return `${secondsDiff > 1 ? secondsDiff + ' seconds ago' : 'Just now'}`;
  }

  return '';
};

export const dater = (dateStr) => {
  const date = new Date(dateStr);

  const monthNames = [ "January" , "February" , "March" , "April" , "May" , "June" ,
    "July" , "August" , "September" , "October" , "November" , "December"
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const formattedDate = `${month} ${day}, ${year} ${hours}:${minutes}:${date.getSeconds()} ${ampm}`;
  
  return formattedDate
}

export const HandleCreate64ForState = ( setStateFunction ) => {
  return useCallback(async (e) => {
      const file = e.target.files[0]
      const base64 = await ToBase64(file)
      const blob = dataURItoBlob(base64)
      setStateFunction({blob:blob,base64:base64,name:file?.name})
      e.target.value = ''
  },[setStateFunction])
}

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const ToBase64 = (file) => {
  return new Promise( (resolve,reject) => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
    }

    fileReader.onerror = (error) => {
      reject(error)
    }

  })
}