import React from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';
import { Line } from "react-chartjs-2";
import { useData } from '../../../config/Provider';

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);
 
export default function MultiLine({colorA,colorB})  {
  const {lang} = useData()
  const PTypes = [
    { title : lang?.on_task_ } ,
    { title : lang?.participating_ } ,
    { title : lang?.cooperative_ } ,
    { title : lang?.well_done_ } ,
    { title : lang?.respectful_ } ,
  ]
 
    const data = {
        
        labels:  ['January', 'February', 'March', 'April', 'May', 'June' , 'July' , 'August', 'September', 'October', 'November', 'December' ],
        datasets: [
          {
            label : lang?.not_organized_,
            data: [1, 2, 3, 4, 5, 6, 7, 6, 5, 4, 5, 7],
            backgroundColor: '#869ee4',
            borderColor:'#869ee4'
          },
          {
            label :lang?.hard_to_stay_on_task_,
            data: [3, 4, 3, 7, 10, 12, 14, 16, 20, 22, 10, 12],
            backgroundColor: '#3a539d',
            borderColor:'#3a539d'
          },
          {
            label : lang?.uncooperative_,
            data: [13, 1, 2, 41, 22, 11, 14, 1, 2, 3, 4, 5],
            backgroundColor: '#37817f',
            borderColor:'#37817f'
          },
          {
            label : lang?.disruptive_,
            data: [2, 4, 6, 8, 10, 12, 14, 16, 20, 22, 10, 12],
            backgroundColor: '#87649e',
            borderColor:'#87649e'
          },
          {
            label : lang?.disrespectful_,
            data: [11, 22, 2, 3, 33, 4, 41, 1, 2, 4, 5, 1],
            backgroundColor: '#2250ff',
            borderColor:'#2250ff'
          },
          {
            label : lang?.on_task_,
            data: [22, 33, 44, 55, 66, 11, 22, 33, 44, 55, 11, 22],
            backgroundColor: '#2f914b',
            borderColor:'#2f914b'
          },
          {
            label : lang?.not_organized_,
            data: [44, 22, 55, 33, 66, 44, 88, 77, 100, 90, 120, 110],
            backgroundColor: '#1caab2',
            borderColor:'#1caab2'
          },
          {
            label : lang?.participating_,
            data: [2, 4, 6, 8, 10, 12, 14, 16, 20, 22, 10, 12],
            backgroundColor: '#be7b20',
            borderColor:'#be7b20'
          },
          {
            label : lang?.cooperative_,
            data: [33, 11, 22, 44, 33, 66, 55, 44, 100, 90, 120, 110],
            backgroundColor: '#7020be',
            borderColor:'#7020be'
          },
          {
            label : lang?.well_done_,
            data: [2, 4, 6, 8, 10, 12, 14, 16, 20, 22, 10, 12],
            backgroundColor: '#1a76bf',
            borderColor:'#1a76bf'
          },
          {
            label : lang?.respectful_,
            data: [3, 1, 5, 6, 12, 33, 55, 1, 2, 3, 4, 5],
            backgroundColor: '#ffb450',
            borderColor:'#ffb450'
          },
        ]
      };
 
      const options = {
        responsive: true,
        
        plugins: {
            legend: { display: true },
        },
 
        scales: { 
            
            y: {
                ticks: {display: true,},
                grid: {display: true,drawBorder: true},
            }, 
            
            x: {
                ticks: {display: true},
                grid: {display: true,drawBorder: true}
            } 
        } 

    };

  return (
    <Line data={data} options={options} />
  )
}

 