 



import { FormControl, IconButton, InputBase, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader } from '../../components'
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, generatePassword, validateEmail } from '../../config/functions';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useData } from '../../config/Provider';

const EditStudent = ({nav}) => {
  const [ email , setEmail ] = useState('')
  const [ emailValid , setEmailValid ] = useState(true)
  const [ password , setPassword ] = useState('')
  const [ fname , setFname ] = useState('')
  const [ lname , setlname ] = useState('')
  const [ classrooms , setClassrooms ] = useState([])
  const [ data , setData ] = useState([])
  const [ classroom , setClassroom ] = useState('empty')
  const { id } = useParams()
  const {lang} = useData()
  const [ mobile , setMobile ] = useState('')
  const [ number , setNumber ] = useState('')


  const handleChange = (event) => {
    setClassroom(event.target.value);
  };

  const page = async () => {
    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }

    const data = {
      id : id , 
      classroom : classroom , 
      fname : fname , 
      lname : lname , 
      email : email , 
      password : password ,  
      mobile : mobile , 
      number : number 
    }

    await API.post( `/save-studnet/` , data , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/students/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getClassrooms = async () => {
    await API.get( `/school-divisions/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setClassrooms(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() =>{
  } , [])


  const get = async () => {
    await API.get( `/get-student/${id}`, Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            const e = generatePassword(response?.data?.data?.user_email)
            setPassword(e)
            setData(response?.data?.data)
            setFname(response?.data?.data?.student_fname)
            setEmail(response?.data?.data?.user_email)
            setlname(response?.data?.data?.student_lname)
            setNumber(response?.data?.data?.student_number)
            setMobile(response?.data?.data?.student_mobile)
            setClassroom(response?.data?.data?.student_classroom_id)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getClassrooms()
    get()
  } , [id])

  return (
    <Box sx={Style?.Container}>

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' , flexDirection:'column' }}>

        <TableHeader {...{ title:lang?.edit_student_, buttonTitle : lang?.save_  , buttonIcon : <CheckRoundedIcon /> , page : page }} />

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} >
            <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
            <InputBase disabled value={password} onChange={(e) => setPassword(e.target.value)} placeholder={lang?.password_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={fname} onChange={(e) => setFname(e.target.value)} placeholder={lang?.first_name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={lname} onChange={(e) => setlname(e.target.value)} placeholder={lang?.last_name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={number} onChange={(e) => setNumber(e.target.value)} placeholder={lang?.number_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder={lang?.mobile_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <Select labelId="label" id="label" label="Classroom" value={classroom} sx={Style?.Selector} placeholder={lang?.classroom_} onChange={handleChange}>
              <MenuItem value='empty' selected>{lang?.select_classroom_}</MenuItem>
              { classrooms?.map((v,k) => ( <MenuItem k={k} value={v.division_id}>{lang?.classroom_}:{v.cr_title}| {lang?.division_}:{v.division_title}</MenuItem> ))}
            </Select>
        </Box>
 

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditStudent