import {   InputBase, MenuItem, Select  } from '@mui/material'
import { Box } from '@mui/system'
import React , {useEffect} from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const AddDivision = ({nav}) => {
  const [ title , setTitle ] = useState('')
  const [ count , setCount ] = useState()
  const [ classrooms , setClassrooms ] = useState([])
  const [ classroom , setClassroom ] = useState('empty')
  const {lang} = useData()
  const page = async () => {
    await API.post( `/add-division/` , { title : title , classroom_id : classroom  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/divisions/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getClassrooms = async () => {
    await API.get( `/school-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setClassrooms(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() =>{
    getClassrooms()
  } , [])

  const handleChange = (event) => {
    setClassroom(event.target.value);
  };

  return (
    <Box p={2} sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.add_division_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={title} onChange={(e) => setTitle(e.target.value)} placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <Select labelId="label" id="label" label={lang?.classroom_} value={classroom} sx={Style?.Selector} placeholder='Classroom' onChange={handleChange}>
              <MenuItem value='empty' selected>{lang?.select_classroom_}</MenuItem>
              { classrooms?.map((v,k) => ( <MenuItem k={k} value={v.cr_id}>{v.cr_title}</MenuItem> ))}
            </Select>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default AddDivision