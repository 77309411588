 
import { IconButton, InputBase, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { TableHeader, TextArea, TextInput } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useData } from '../../config/Provider';

const EditExamTemplate = ({nav}) => {

  const [ title , setTitle ] = useState('')
  const [ score , setScore ] = useState('')
  const {id} = useParams()
  const {lang} = useData()
  const page = async () => {
    await API.post( `/save-homework-template/` , { title : title , score : score , id:id  } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/teacher-exams-templates/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getData = async () => {
    await API.get( `/get-homework-template/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setTitle(response?.data?.data?.title)
          setScore(response?.data?.data?.score)
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getData()
  }, [id])
  
  return (
    <Box p={2} sx={Style?.Container}>
      
      <TableHeader {...{ title:lang?.edit_exam_template_ , buttonTitle : lang?.save_ , buttonIcon : <CheckIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
          <TextInput {...{set:setTitle,value:title,placeholder:lang?.title_}} />
          <TextInput {...{set:setScore,value:score,placeholder:lang?.score_}} />
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

      </Box>

    </Box>
  )
}

export default EditExamTemplate