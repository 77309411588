 

import React, { useState , useEffect } from "react";
// import BigCalendar from "react-big-calendar";
// import BigCalendar from 'react-big-calendar';
import {TextField , Button ,DialogTitle , Dialog ,DialogContent , DialogActions, InputBase, Typography, Box} from "@mui/material";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useData } from "../../config/Provider";
import { API } from "../../config/functions";
import Vals from "../../config/Vars";
import { AlertsDialog } from "../../components";



const SchoolCalendar = () => {

 
    // BigCalendar.momentLocalizer(moment);
 
    const localizer = momentLocalizer(moment)
  const styles = {
    calendar: {
      height: 500,
      width: "100%",
    },
  }

  const {lang} = useData()
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [title, setTitle] = useState("");
  const [ description , setDescription] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const get = async () => {
    await API.get( `/view-calendar/`, Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          var rows = []
            response?.data?.data?.map((item) => {
              const from = new Date(item?.calendar_from).toLocaleString('en-US', {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'GMT',
                timeZoneName: 'short'
              });

              const to = new Date(item?.calendar_to).toLocaleString('en-US', {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'GMT',
                timeZoneName: 'short'
              });

              rows?.push({
                title:item?.calendar_title,
                description:item?.calendar_description,
                start : from , 
                end : to ,
                id : item?.calendar_id
              })
           
            })

            setEventsData(rows)

        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    get()
  } , [])

  const deleteCalendar = async () => {
    await API.post( `/delete-calendar/`, { id : deleteId } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setDeleteAlert(false)
          const filter = eventsData?.filter((i) => i.id != deleteId)
          setEventsData(filter)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const DeleteInfo = () => (
    <Box sx={{display:'flex',flexDirection:'column'}}>
      <Typography sx={{color:'primary.text'}}> {title} </Typography>
      <Typography sx={{color:'primary.text'}}> {description} </Typography>
    </Box>
  )

  const handleOpen = (item) => {
    setTitle(item?.title)
    setDescription(item?.description)
    setDeleteId(item?.id)
    setDeleteAlert(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    await API.post( `/add-calendar/`, { title : title , des : description , from : start , to : end } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            setEventsData([ ...eventsData ,  {
              title:title ,
              description : description ,
              start : start , 
              end : end ,
              id : response?.data?.id
            }])
            setStart('')
            setEnd('')
            setTitle('')
            setDescription('')
            setOpen(false);
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  };

  const handleSelect = ({ start, end }) => {
    setOpen(true)
    setStart(start)
    setEnd(end)
    setTitle('')
    setDescription('')
  }
 
  const handleChange = (event) => {
    if (event.target.name === "title") {
      setTitle(event.target.value);
    } else if (event.target.name === "start") {
      setStart(event.target.value);
    } else if (event.target.name === "description") {
      setDescription(event.target.value);
    } else if (event.target.name === "end") {
      setEnd(event.target.value);
    }
  };
 

  const Style = {
    Input : {
      input : {color:'primary.text' , },
      textarea : {color:'primary.text' , },
      borderWidth:1,
      borderStyle:'solid',
      borderColor:'primary.border',
      backgroundColor:'primary.bg' , 
      '&:hover' : {  backgroundColor : 'primary.bg'},
      width:'100%' ,
      padding:1,
      mt:2
  } , 
  }

  return (
    <div style={{padding:50,backgroundColor:'#fafafa'}}>
      
      <AlertsDialog {...{
        open : deleteAlert,
        setOpen : setDeleteAlert,
        func : deleteCalendar,
        btnTitle : lang?.delete_,
        alertText : <DeleteInfo /> 
      }} />

      <Calendar
        style={styles.calendar}
        localizer={localizer}
        selectable
        events={eventsData}
        onSelectSlot={handleSelect}
        onSelectEvent={handleOpen}
        defaultDate={new Date()}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"  >
        {/* <DialogTitle id="form-dialog-title">Select Day and Hours</DialogTitle> */}
        <DialogContent 
        sx={{backgroundColor:'primary.main'}}
        >
 
          <InputBase name="title" value={title} onChange={handleChange} placeholder={lang?.title_} sx={Style?.Input} variant="outlined" autoComplete='off'/>

          <InputBase name="description" rows={5} multiline={true} value={description} onChange={handleChange} placeholder={lang?.description_} sx={Style?.Input} variant="outlined" autoComplete='off'/>

          <InputBase name="start" value={start} onChange={handleChange} placeholder={lang?.start_} sx={[Style?.Input,{display:'none'}]} variant="outlined" autoComplete='off'/>
          
          <InputBase name="end" value={end} onChange={handleChange} placeholder={lang?.end_} sx={[Style?.Input,{display:'none'}]} variant="outlined" autoComplete='off'/>

        </DialogContent>
        <DialogActions 
        sx={{backgroundColor:'primary.main'}}
        
        >
          <Button onClick={handleClose} sx={{color:'primary.text'}}>
            {lang?.cancel_}
          </Button>
          <Button onClick={handleSave}  sx={{color:'primary.text'}}>
            {lang?.save_}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SchoolCalendar;