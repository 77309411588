import { Avatar, Grid, ListItem, TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React , { useState , useEffect } from 'react'
import { DataTable, MultiLine, PieChart, TableHeader, TextInput } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'
import { useData } from '../../config/Provider'

const SchoolGrades = () => {

  const [ search , setSearch ] = useState('')
  const [ title , setTitle ] = useState('')
  const [ select , setSelect ] = useState(0)
  const [ classroom , setClassroom ] = useState(0)
  const [ student , setStudent ] = useState(0)
  const [ data , setData ] = useState([])
  const [ classroomData , setClassroomData ] = useState([])
  const {lang , dir } = useData()
  const [ exams , setExams ] = useState([])
  const [ studentsExams , setStudentsExams ] = useState([])
  const [evSelected, setEvSelected] = useState('today')

  const filterTypes = [
    { title : 'Today' , value : 'today' } ,
    { title : 'Last Week' , value : 'lastweek' } ,
    { title : 'Last month' , value : 'lastmonth' } ,
    { title : 'Last 3 months' , value : 'last3months' } ,
    { title : 'Last 6 months' , value : 'last6months' } ,
    { title : 'All' , value : 'all' } ,
  ]

  const Select = (classroom , title) => {
    setClassroom(classroom)
    console.log(classroom)
    setTitle(title)
  }

  const getSearch = async () => {
    await API.get( `/school-search-classrooms/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getData = async () => { 
    await API.get( `/school-divisions/` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroom = async ()  => {
    await API.get( `/school-classroom-grades/${classroom}/${evSelected}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.students)
        setExams(response?.data?.exams)
        setStudentsExams(response?.data?.studentsAssigned)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (search?.length > 0 ) {
      getSearch()
    } else {
      getData()
    }
  }, [search?.length])

  useEffect(() => {
    if (classroom > 0) {
      getClassroom()
    }
  } , [classroom,evSelected])
 
  const Tag = ({item}) => (
    <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
       onClick={() => Select(item?.division_id , `${item?.cr_title} / ${item?.division_title}`)} >
        <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
    </Box>
  )

  const FilterGrades = (exam_id , studentsAssignedArr) => {
    const check = studentsAssignedArr?.find((i) => i?.aa_template_id == exam_id )
    return check ? check?.score : '--'
  } 

  const DataConvert = (e) => {
    const date = new Date(e);
    const formate = date.toISOString().slice(0, 10);
    return formate
  }

  const ClassRoomData = () => {

    const TableLoop = () => {

      return ( 
        <>  
          {filterTypes?.map((item,key) => 
              <Box key={key} sx={[Style?.Tag , 
                { borderColor : evSelected === item.value  ? 'primary.orange' : 'primary.border' }
              ]} onClick={() => setEvSelected(item?.value) }>
                <Typography color='primary.text'> {item?.title} </Typography>
              </Box>
            )}
          <TableRow sx={Style?.Row}> 
            <TableCell sx={{color:'primary.text'}} >  {lang?.students_} </TableCell>
            {exams?.map((item,k) => <TableCell sx={{color:'primary.text',textAlign:'center'}} key={`${k}x`}> {item?.template_type} <br /> {item?.template_title} <br /> {DataConvert(item?.aq_date)} <br /> {item?.template_score} {lang?.points_}  </TableCell> )}
          </TableRow>
          {classroomData?.map( ( v , k ) => 
            <TableRow key={k} sx={Style?.Row}> 
              <TableCell sx={{color:'primary.text'}} >{v?.student_fname} {v?.student_lname}</TableCell> 
              {exams?.map((item,k) => <TableCell sx={{color:'primary.text',textAlign:'center'}} key={`${k}x`}> { FilterGrades(item?.aq_template_id,[studentsExams?.find((i) => i?.aa_student_id == v?.student_id)]) } </TableCell> )} 
            </TableRow>
          )}
        </>
      )
    } 
 
    return (
      <>
        <TableHeader  {...{title:title, buttonIcon : '' , buttonTitle:lang?.back_ , page:() => setClassroom(0)}}/>
        <Grid container>
          <DataTable {...{ Header:[] , Data : <TableLoop /> }} />
        </Grid>
      </>
    )

  }
 
  return (
    <Box sx={Style?.Container}>
      <Box sx={Style?.ItemContainer}>

        {classroom === 0 ? 
          <>
            <TextInput {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
            <Box mt={3}>
              {data?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
            </Box>
          </>
        : 
          <ClassRoomData/> 
        }
        
      </Box>
    </Box>
  )
}

export default SchoolGrades