export const Style = {
  Container : {
    mr : 2 ,
    ml : 2 ,
    mt : 2 ,
    p:2,
    backgroundColor:'white',
    border:'2px solid #e4e6e5',
    borderRadius:2
} ,
    ItemContainer : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      borderRadius:2,
      padding:2,
      width:'95%',
      height:'auto',
    },
 
    inputContainer : {
      borderWidth:1,
      borderColor:'primary.border',
      borderStyle:'solid',
      mt:2,
      mb:2
    } ,
    DayContainer : {
      borderStyle:'solid',
      pb:1,
      pt:1,
      mr:1,
      ml:1,
      width:'100%' , 
      borderWidth:1,
      borderRadius:2,
      display:'flex' , 
      justifyContent:'space-around' , 
      alignItems:'center' , 
      flexDirection:'row' ,
      cursor:'pointer'
    }
  }