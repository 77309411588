import React from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);

export default function LineChart ({LineColor}) {

    const data = {
        
        labels:  ['January', 'February', 'March', 'April', 'May', 'June' ],
        datasets: [
          {
            data: [33, 66, 44, 88, 66, 99],
            backgroundColor: '#1a76bf',
            borderColor:'#1a76bf'
          }
        ]
      };
 
      const options = {
        responsive: true,
        
        plugins: {
            legend: { display: false },
        },
 
        scales: { 
            
            y: {
                ticks: {display: false,},
                grid: {display: false,drawBorder: false},
            }, 
            
            x: {
                ticks: {display: false},
                grid: {display: false,drawBorder: false}
            } 
        } 

    };
 
  return (
    <Line data={data} options={options} />
  )
}

 