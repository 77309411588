
export const Style = {
    
    Container : {
        paddingRight : {xs:1,md:5} ,
        paddingLeft : {xs:1,md:5} ,
        paddingTop : {xs:1,md:5},
        paddingBottom : {xs:1,md:0}
    } ,

    sideContainerMain : {
        width:'100%',
        display:'flex',
        flexDirection:{xs:'column',md:'row'},
        justifyContent:'space-between',
        alignItems:'flex-start'
    } ,
    BG : {
        backgroundImage:`url(./images/announcement-bg.png)`  , backgroundPosition:'center' , backgroundSize:'contain' , backgroundRepeat:'no-repeat' , p:0,m:0
    } ,

    sideContainer : {
        mt:2,
        width:{xs:'100%',md:'49.5%'},
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'center',
    } ,

    InnerContainer : {
        justifyContent : 'space-around' , 
        display:'flex',
        alignItems:'center',
        height :150 , 
        width: '100%' ,
        borderRadius: 3 ,
        width:'100%',
    } ,
    
    WhiteContainer : {
        width:'100%',
        p:3,
    } ,

    WhiteInnerContainer : {
        justifyContent : 'space-around' , 
        display:'flex',
        alignItems:'center',
        width: '100%' ,
        borderRadius: 3 ,
        boxShadow:'none',
        border:'1px solid #e4e6e5',
    } ,

    InnerContainerBg : {
        justifyContent : 'space-around' , 
        display:'flex',
        alignItems:'center',
        borderRadius: 3 ,
        boxShadow:'none',
        border:'1px solid #e4e6e5', 
        p : 2 , 
        backgroundColor:'transparent' , 
        borderWidth:0 ,
        height: '100%',
        width: '100%',
        backgroundImage: `url(./images/announcement-bg.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat:'no-repeat' ,
     } ,

    ChatItem : {
        alignItems:'center' , 
        minHeight:40 , 
        cursor:'pointer', 
        height:'auto',
        backgroundColor : 'primary.secondary' , 
        justifyContent : 'space-between' , 
        padding : 3 , 
        margin : 1 , 
        borderRadius : 3 , 
        display : 'flex' , 
        flexDirection : 'row' 
    },

    icon : {
        fontSize:60,color:'white' , transition:'0.5s'  , '&:hover' : { color:'white'   } 
    } ,

    Row : { 
        borderColor:'red' , 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        }
    } ,

    SearchInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderColor:'red',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        color : 'white' ,
        width:'100%' ,
    } ,

    StudentCard : {
        display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center' ,mt:2 ,cursor:'pointer' 
    } ,

    StudentCardInfo : {
        display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'flex-start'
    } ,

    announcementsCon : {
        height:50,
        p:2,
        mt:1,
        display:'flex',
        flexDirection:'row' ,
        alignItems:'center',
        justifyContent:'space-between',
        border:'1px solid lightgray' ,
        width:'95%', 
        borderRadius:2,
        backgroundColor:'#ffffff'
    } ,
    bgInnerCon : {
        justifyContent : 'space-around' , 
        display:'flex',
        // alignItems:'center',
        flexDirection:'column',
        width:'90%',
        p:2,
    } ,

    BGCon : {
        justifyContent : 'space-around' , 
        display:'flex',
        alignItems:'center',
        width:'100%',
        pt:2,
        pb:2,
        backgroundImage:`url(./images/announcement-bg.png)`,
        backgroundSize:'cover' ,
        borderRadius:1 ,
        backgroundPosition: 'center',
      }

}

