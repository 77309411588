export const Style = {
    Table : {
        width:'100%' , 
        color : 'primary.text',
    } ,

    TableRow : {
        "& th": { 
            borderColor:'primary.main'
        }
    }
}