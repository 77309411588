import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useData } from '../../config/Provider';

const MediaDialog = ({open,setOpen,func,btnTitle,alertContent,handleClose}) => {

    const { lang } = useData()

    return (
    
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent sx={{backgroundColor:'primary.main',width:400,height:100,display:'flex',justifyContent:'center',alignItems:'center',}}>
                <DialogContentText id="alert-dialog-description">
                    {alertContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions  sx={{backgroundColor:'primary.main'}}>
            <Button  sx={{backgroundColor:'primary.blue',color:'white'}} onClick={handleClose}>{lang?.close_}</Button>
            <Button  sx={{backgroundColor:'primary.orange',color:'white'}} onClick={func} autoFocus>
                {lang?.send_}
            </Button>
            </DialogActions>
        </Dialog>

    )
}

export default MediaDialog