import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader, TextInput  } from '../../components'
import { Style } from './Style'
import {  TableCell, TableRow  } from '@mui/material';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useParams } from 'react-router-dom';
import { useData } from '../../config/Provider';

const LangsContent = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const {id} = useParams()
  const [ updatedData, setUpdatedData ] = useState([])
  const{ lang , dir } = useData()

  const getData = async () => {
    await API.get(`/lang-content/${id}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {

          setUpdatedData(prevState => {
            return response?.data?.data?.map((item) => ({
              key: item?.lv_id,
              value: item?.lv_value,
            }))
          })

          setData(response?.data?.data);
        } else {
          console.log(response?.data?.msg);
        }
      })
      .catch((e) => console.log(e));
  };

  const searchData = async () => {
    await API.get(`/search-lang-content/${id}/${search}`, Vals?.GET_HEADER)
      .then(function (response) {
        if (response?.data?.status === true) {

          setUpdatedData(prevState => {
            return response?.data?.data?.map((item) => ({
              key: item?.lv_id,
              value: item?.lv_value,
            }))
          })

          setData(response?.data?.data);
        } else {
          console.log(response?.data?.msg);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if(search?.length > 0) {
      searchData()
    } else {
      getData();
    }
  }, [search?.length]);

  const handleSave = async () => {
    await API.post( `/save-lang-content/` , { data : updatedData } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            console.log('saved')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const Header = [
    { title : lang?.key_ } ,
    { title : lang?.translate_ } ,
  ]

  const handleContentEditableChange = (e, key) => {
    const value = e.target.textContent;
    setUpdatedData((prevState) => {
      const itemIndex = prevState.findIndex((item) => item.key === key);
      if (itemIndex === -1) {
        // add new item with empty value
        return [...prevState, { key, value: '' }];
      } else {
        prevState[itemIndex].value = value;
        return [...prevState];
      }
    });
  };

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell width={'50%'} sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.lk_key} </TableCell> 
        <TableCell width={'50%'}
            sx={{ color: 'primary.text' , textAlign:dir==='rtl'?'right':'left' }}
            onBlur={(e) => handleContentEditableChange(e, v.lv_id)}
            contentEditable
        >{updatedData.find((item) => item.key === v.lv_id)?.value || v.lv_value}</TableCell>
      </TableRow>
    )))
  } 

    return (
        <Box sx={Style?.Container}>
            <TableHeader {...{ title:lang?.languages_ , buttonTitle : lang?.save_  , page : handleSave  }} />
            <TextInput  {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
            <DataTable {...{ Header , Data : <TableLoop /> }} />
        </Box>
    )
}

export default LangsContent