 

import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { AlertsDialog, DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SchoolAnnouncements = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const [ open , setOpen ] = useState(false)
  const [ deleteId , setDeleteId ] = useState(0)
  const { lang , dir } = useData()
  const page = () => {
    nav('/add-announcement/')
  }

  const getData = async () => {
    await API.get( `/get-school-announcements/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
  const getSearch = async () => {
    await API.get( `/search-school-announcement/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (search?.length > 0) {
      getSearch()
    } else {
      getData()
    }
  } , [search?.length])


  const types = [
    { title : lang?.teachers_ , value : 'teacher'} , 
    { title : lang?.students_ , value : 'student' } , 
    { title : lang?.parents_ , value : 'parent' } , 
    { title : lang?.all_ , value : 'all' } , 
  ]
 
  const TableLoop = () => {

    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.a_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.a_decription} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > { types?.find((i) => i.value === v.show_type )?.title } </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/edit-announcement/${v.a_id}`)} >
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton aria-label="delete" onClick={() => ShowDialog(v.a_id)}>
            <DeleteOutlineOutlinedIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell>  
      </TableRow>
    )))
  } 

  useEffect(() => {
    getData()
  } , [])
 
  const Delete = async () => {
    await API.post( `/delete-school-announcement/` , { id : deleteId } , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          const filtered = data?.filter((i) => i.a_id != deleteId )
          setData(filtered)
          setOpen(false)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const Header = [
    { title : lang?.title_ } ,
    { title : lang?.description_ } ,
    { title : lang?.view_ } ,
    { title : lang?.edit_ } ,
    { title : lang?.delete_ } ,
  ]

  const ShowDialog = (id) => {
    setDeleteId(id)
    setOpen(true)
  }

  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{ open:open,setOpen:setOpen,func:Delete,btnTitle:lang?.delete_,alertText : lang?.are_you_sure_you_want_to_delete_ } }/>
      <TableHeader {...{ title:lang?.announcements_ , buttonTitle :lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase value={search} onChange={(e) => setSearch(e.target.value)} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default SchoolAnnouncements