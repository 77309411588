import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArticleIcon from '@mui/icons-material/Article';
import { useData } from '../../config/Provider';

const AssignedAssessment = ({nav}) => {
  
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const {lang,dir} = useData()
  const page = () => {
    nav('add-subject')
  }

  const getData = async () => {
    await API.get( `/assigned-template/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-assigned-template/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
         
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.template_title} </TableCell> 
        
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/assessment-questions/${v.aq_template_id}`)}>
            <ArticleIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 

        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.cr_title}  {v.division_title} </TableCell> 

        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/teacher-answer/${v.aq_template_id}`)}>
            <RemoveRedEyeIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
     
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.template_ } ,
    { title : lang?.view_ } ,
    { title : lang?.classroom_ } ,
    { title : lang?.students_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.assigned_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default AssignedAssessment