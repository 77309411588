import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useData } from '../../config/Provider';
import { Box, CardMedia, InputBase  } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useParams } from 'react-router-dom';
import AlertsDialog from '../alertsDialog/AlertsDialog';
import Btn from '../btn/Btn'
import TextEditor from '../textEditor/TextEditor'
import { Style } from './Style';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog({nav}) {
    
    const { popup , setPopup  } = useData()
    
    const handleClickOpen = () => {
        setPopup(true);
    };

    const handleClose = () => {
        setPopup(false);
    };

    const { id } = useParams()
    const { lang , dir } = useData()
    const [ questions , setQuestions ] = useState([])
    const [ data , setData ] = useState([])
    const [ answers , setAnswers ] = useState([])
    const [ paragraph , setParagraph ] = useState('');
    const [ showAlert , setShowAlert ] = useState(false);
    const [ loader , setSLoader ] = useState(true);
    const [ draggedItem , setDraggedItem ] = useState(null);
  
    const handleDrop = (event, item, question_id) => {
      
      if (item?.id == draggedItem?.id) {
        const matchingAnswer = answers.find(answer => answer.question_id === question_id && answer.type === 'match');
        const oldValue = matchingAnswer ? [...matchingAnswer.value] : [];
        const newValue = [...oldValue, {item1: draggedItem, item2: item}];
        const newAnswer = {
          question_id: question_id,
          type: 'match',
          value: newValue
        };
        const answersCopy = matchingAnswer ? [...answers] : [...answers, newAnswer];
        if (matchingAnswer) {
          const matchingAnswerIndex = answers.findIndex(answer => answer.question_id === question_id && answer.type === 'match');
          answersCopy[matchingAnswerIndex] = newAnswer;
        }
        setAnswers(answersCopy);
      }
      setDraggedItem(null);
    };
  
    const handleDragOver = (event) => {
      event.preventDefault()
    }
  
    const deleteMatch = (key,question_id) => {
        const answersCopy = [...answers];
        const matchingAnswerIndex = answersCopy.findIndex(answer => answer.question_id === question_id && answer.type === 'match');
        if (matchingAnswerIndex > -1) {
          const matchingAnswerCopy = {...answersCopy[matchingAnswerIndex]};
          const oldValue = matchingAnswerCopy.value ? [...matchingAnswerCopy.value] : [];
          const newValue = oldValue.filter((value, index) => index !== key);
          matchingAnswerCopy.value = newValue;
          answersCopy[matchingAnswerIndex] = matchingAnswerCopy;
          setAnswers(answersCopy);
        }
    }
  
    const  shuffleAnswers = (array) => {
      let textbValues = array.map(obj => obj.textb);
      for (let i = textbValues.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [textbValues[i], textbValues[j]] = [textbValues[j], textbValues[i]];
      }
      
      array.forEach((obj, i) => {
          obj.textb = textbValues[i];
      });
      return array
    }
   
    const getData = async () => {
      await API.get( `/get-student-questions/${popup?.id}` , Vals?.GET_HEADER )
      .then( function ( response ) {
          if (response?.data?.status === true) {
  
            var rows = []
            var answersRows = []
  
            setData(response?.data?.info)
            setQuestions(response?.data?.questions)
            setParagraph(response?.data?.info?.template_paragraph)
  
            response?.data?.data?.map(( item ) => {
              const options =  item?.question_options ? JSON.parse(item?.question_options) : []
              
              if (item?.question_type === 'match') {
                const randomize = options
                console.log(shuffleAnswers(randomize))
              }
  
              rows?.push({
                question_type : item?.question_type,
                question_score : item?.question_score,
                options : item?.question_type === 'option' ? options : [] , 
                match : item?.question_type === 'match' ? options : [], 
                blank : item?.question_type === 'fill' ? options : [] ,
                question_text : item?.question_text , 
                question_id : item?.question_id 
              })
              if (item?.answer_text) {
                answersRows?.push({
                  question_id : item?.question_id ,
                  value : item?.question_type !== 'text' ? JSON.parse( item?.answer_text  ) : item?.answer_text  , 
                  type : item?.question_type ,
                })
              }
  
            })
  
            setQuestions(rows)
            setAnswers(answersRows)
          } else {
            console.log(response?.data?.msg)
          }
      })
      .catch((e) => console.log(e) )
    }
  
    useEffect(() => {
      setTimeout(() => {
        getData()
        setSLoader(false)
      }, 1500);
    } , [])
   
    const questionTypesSelector = [
      { title : lang?.multiple_choice_ , value : 'option' },
      { title : lang?.matching_ , value : 'match' },
      { title : lang?.fill_the_blank_ , value : 'fill' },
      { title : lang?.short_answer_ , value : 'text' },
    ]
  
    const chooseOption = (value,question_id) => {
      
      if (data?.aa_status === 'end') {
        return false
      }
      
      const check = answers?.find((v) => v.question_id == question_id ) 
      if ( check?.value === value && check?.question_id == question_id ) {
        console.log('1')
        const filter = answers?.filter((v) => v.question_id != question_id ) 
        setAnswers(filter)
      } else if (check?.value !== value && check?.question_id == question_id) {
        console.log('2')
        const updatedAnswers = answers?.map((v) => {
          if (v.question_id == question_id) {
            return { ...v, value: value };
          } else {
            return v;
          }
        });
        setAnswers(updatedAnswers);
      } else if (!check) {
        console.log('3')
        setAnswers([ ...answers , {
          question_id : question_id ,
          type : 'option',
          value : value
        }])
      }
    }
  
    useEffect(() => {
      // console.log(answers)
    } , [answers])
   
    const saveQuestion =  async (question_id , type) => {
      
      const answer = answers?.find((i) => i.question_id == question_id )
      
      const question_data = {
        value : answer ? type === 'text' ? answer?.value : JSON.stringify(answer?.value) : type === 'text' ? '' : [] ,
        question_id : question_id ,
        id : popup?.id
      }
  
      await API.post( `/save-answer/` , question_data,  Vals?.POST_HEADER )
      .then( function ( response ) {
          if (response?.data?.status === true) {
          } else {
            console.log(response?.data?.msg)
          }
      })
      .catch((e) => console.log(e) )
  
    }
  
    const startAnswering = async () => {
      
      await API.post( `/start-answer/` ,  {template_id : popup?.id} ,  Vals?.POST_HEADER )
      .then( function ( response ) {
          if (response?.data?.status === true) {
          } else {
            console.log(response?.data?.msg)
          }
      })
      .catch((e) => console.log(e) )
    }
  
    useEffect(() =>{
      startAnswering()
    } , [])
  
    const handleMainInputChange = (event, question_id) => {
      const value = event.target.value;
      setAnswers((prevAnswers) => {
        return prevAnswers.map((question) => {
          if (question?.question_id === question_id) {
            return {
              ...question,
              value: value,
            };
          } else {
            return question;
          }
        }).concat({
          question_id: question_id,
          type: 'text',
          value: value,
        });
      });
    }
  
    const assessmentsTyps = [
      {title  : lang?.assessments_ , value : 'assessment' , image : './images/mortarboard.png'} , 
      {title  : lang?.class_work_ , value : 'classwork' , image : './images/mathSubject.png'} , 
      {title  : lang?.homework_ , value : 'homework' , image : './images/whiteboard.png'} , 
      {title  : lang?.pop_quiz_ , value : 'popup' , image : './images/dictonary.png'} , 
      {title  : lang?.comprehension_ , value : 'comprehension' , image : './images/exam.png'} ,  
    ]
    
    const fillBlank = (text, question_id ) => {
      const parts = text?.split('---');
      const values = answers?.find((v) => v?.question_id == question_id )?.value ? answers?.find((v) => v?.question_id == question_id )?.value : {}
      return parts?.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts?.length - 1 && (
            <span
              style={{ borderBottom: '1px dashed #e9a282', paddingRight: 50, paddingLeft: 50 }}
              contentEditable={data?.aa_status === 'end'?false:true}
              suppressContentEditableWarning
              onBlur={(event) => handleInputChange(event, question_id, index )}
            >
              {values[index]?values[index]:''}
            </span>
          )}
        </React.Fragment>
      ));
      
    };
    
    const handleInputChange = (event, question_id, field_index ) => {
      const value = event.currentTarget.textContent;
      setAnswers((prevQuestions) => {
        let questionFound = false;
        const updatedQuestions = prevQuestions?.map((question) => {
          if (question.question_id == question_id) {
            questionFound = true;
            const updatedField = question[field_index]?.map((input, index) => {
              if (index == field_index) {
                return {
                  ...input,
                  question_id: question_id,
                  type: 'fill',
                  value: value,
                };
              } else {
                return input;
              }
            });
            const newQuestionValue = {
              ...question.value,
              [field_index]: value,
            };
            return {
              ...question,
              value: newQuestionValue,
              [field_index]: updatedField,
            };
          } else {
            return question;
          }
        });
        if (!questionFound) {
          const newQuestion = {
            question_id: question_id,
            type: 'fill',
            value: {
              [field_index]: value,
            },
          };
          updatedQuestions.push(newQuestion);
        }
        return updatedQuestions;
      });
    }
  
    const handleDragStart = (event, item , question_id) => {
      setDraggedItem(item , question_id)
    }
  
    const assign = async () => {
      await API.post( `/assign-answers/` , { template_id : popup?.id } , Vals?.POST_HEADER )
      .then( function ( response ) {
          if ( response?.data?.status === true ) {
            setPopup(false)
            setShowAlert(false)
            nav(`/${data?.template_type}/`)
          } else {
            console.log( response?.data?.msg )
          }
      })
      .catch( ( e ) => console.log( e ) )
    }
  
    const typeTitle = assessmentsTyps?.find((i) => i.value === data?.template_type )?.title
    const typeImg = assessmentsTyps?.find((i) => i.value === data?.template_type )?.image

    if (!loader)
    return (
        <Dialog
            fullScreen
            open={popup?.show}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{ backgroundColor:'primary.main'}}>
         
            <Box sx={{height:'100vh', backgroundColor:'primary.main'}}>
                <Box mt={1} ml={5} mr={5} mb={2}>

                <AlertsDialog {...{
                    open:showAlert,
                    setOpen:setShowAlert,
                    func:assign,
                    btnTitle:lang?.assign_,
                    alertText:lang?.are_you_sure_you_want_to_assign_
                }} />

                <Box sx={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    alignItems:'center',
                    width:'100%',
                }}>
                    <Box sx={{
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    width:'100%',
                    }}>
                    <CardMedia component="img" image={typeImg} alt="exam logo title" sx={Style?.CardMedia}/>
                    
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        ml:1,
                        mr:1,
                    }}>
                        <Typography color='primary.text' fontSize={40}> {data?.template_title} </Typography>
                        <Typography color='primary.text' fontSize={20}> {typeTitle} </Typography>

                    </Box>

                    </Box>

                    <Box>
                    {data?.aa_status === 'start' && (
                        <Btn {...{ func:() => setShowAlert(true) , buttonTitle:lang?.assign_ } }/>
                    )}
                    </Box>

                </Box>
            
                {data?.template_type === 'comprehension' && (
                    <Box sx={Style?.paragraphContainer} >
                    <TextEditor {...{contnet:paragraph , setcontnet:setParagraph }}/>
                    </Box>
                )}
            
                {questions?.map((question_item,key) => {

                    const op =  answers?.find((i) => i.question_id == question_item?.question_id)?.value ?.length>0
                    ?[answers?.find((i) => i.question_id == question_item?.question_id)?.value]:[]

                    return(

                    <Box sx={Style?.questionsContainer} key={key}>

                        <Box sx={Style?.questionHeader}>
            
                        <Box sx={Style?.questionInputContainer}>
                            {question_item?.question_type==='fill'?
                            <Typography color='primary.text' fontSize={25} > 
                                {key+1} - { fillBlank(question_item?.question_text,question_item?.question_id ) }  
                            </Typography>
                            : 
                            <Typography color='primary.text' fontSize={25}>
                                {key+1} - {question_item?.question_text} 
                            </Typography>
                            }
                        </Box>

                        <Box sx={[Style?.selectOptionContainer,{textAlign:dir==='ltr'?'right':'left'}]}>
                            <Typography color='primary.text' fontSize={20}> {questionTypesSelector?.find((i) => i?.value == question_item?.question_type )?.title} </Typography>
                        </Box>

                        </Box>

                        {question_item?.question_type === 'match' && (
                        
                        <Box sx={{ width:'100%', display: 'flex', flexDirection: 'column' , alignItems:'flex-start', justifyContent:'flex-start'  }}>
                            <Typography color='primary.text'> { lang?.drag_from_a_to_b_ } </Typography>
                            
                            <Box >

                            {op[0]?.map((match, index) => (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3 }}>
                                <Box sx={{ ...Style?.box, mr: 3 }}>{match?.item1?.texta}</Box>
                                <ArrowForwardIcon sx={{color:'primary.orange',fontSize:30}}/>
                                <Box sx={{ ...Style?.box, ml: 3 }}>{match?.item2?.textb}</Box>
                                {data?.aa_status === 'start' && (
                                    <ClearIcon 
                                    onClick={() => deleteMatch(index,question_item?.question_id)} 
                                    sx={{color:'primary.red',fontSize:35,ml:2,mr:2}}
                                    />
                                )}
                                </Box>
                            ))}

                            </Box>

                            <Box sx={{ width: '50%' }}>

                            {question_item?.match?.map((match_item, match_key) => {
                                
                                return(
                                <Box
                                key={match_key}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    mt: 3,
                                    width:'50%'
                                }}
                                >
                                    <Typography sx={{color:'red',fontSize:35}}>
                                    </Typography>
                                    <Box sx={{width:'50%'}}>

                                    { !op[0]?.find( ( v ) => v?.item1?.texta == match_item?.texta) && (
                                    <Box
                                    sx={{ ...Style?.box }}
                                    draggable
                                    onDragStart={(event) => handleDragStart(event, { ida: match_key , texta : match_item?.texta } , question_item?.question_id )}
                                    >
                                        <Typography color='primary.text'>{match_item?.texta}</Typography>
                                    </Box>
                                    ) }  
                                </Box>
                                <Box sx={{width:'50%'}}>
                                    {!op[0]?.find( ( v ) => v?.item2?.textb == match_item?.textb)  && (
                                    <Box
                                        sx={{ ...Style?.box, ml: 3 }}
                                        onDragOver={(event) => handleDragOver(event)}
                                        onDrop={(event) => handleDrop(event, { idb: match_key, textb : match_item?.textb } , question_item?.question_id)}
                                    >
                                        <Typography color='primary.text'>{match_item?.textb}</Typography>
                                    </Box>
                                    )}
                                </Box> 
                                </Box>
                            )})}

                            </Box>
                        </Box>
                        )}

                        {question_item?.question_type === 'fill' && (
                        <Box sx={{width:'100%'}}>

                        </Box>
                        )}

                        {question_item?.question_type === 'option' && (

                        <Box mt={3} sx={{width:'100%',}}>

                            <Box sx={{
                            display:'flex',
                            flexDirection:'row',
                            mt:2,
                            }}>

                            <Box sx={{
                                display:'flex',
                                flexDirection:'column',
                                width:'100%'
                            }}>
                                

                                {question_item?.options?.map((option,option_key) => (
                                
                                <Box key={option_key} mt={1} sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center'
                                }}>

                                    <Typography color='primary.text' sx={{mr:dir==='rtl'?0:1,ml:dir==='ltr'?0:1}} fontSize={20} > {option_key+1} - </Typography>
                                    
                                    <Box onClick={() => chooseOption(option?.text,question_item?.question_id) } 
                                    sx={{cursor:'pointer',mr:dir==='rtl'?0:1,ml:dir==='ltr'?1:0}}>
                                    {answers?.find((v) => v.question_id == question_item?.question_id)?.value === option?.text ?
                                    <CheckCircleOutlineOutlinedIcon  sx={{color:'primary.blue',fontSize:25}} />: 
                                    <CircleOutlinedIcon sx={{color:'primary.orange',fontSize:25}}/> 
                                    }
                                    
                                    </Box>

                                    <Box sx={{
                                    borderColor:'primary.border',
                                    borderStyle:'solid',
                                    borderWidth:1,
                                    width:'auto',
                                    pt:1,pb:1,pr:3,pl:3,
                                    maxWidth:'auto',
                                    borderRadius:2
                                    }}>
                                    <Typography color='primary.text'>{option?.text} </Typography>
                                    </Box>



                                </Box>
                                ))}


                            </Box>

                            </Box>

                        </Box>
                        )}

                        {question_item?.question_type === 'text' && (

                        <Box mt={3} sx={{width:'100%',}}>
                            <Box sx={{
                            width:'100%',
                            display:'flex'
                            }}>
                            {data?.aa_status === 'end' ? (
                                <Typography color='primary.text'> {answers?.find((v) => v.question_id == question_item?.question_id)?.value} </Typography>
                            ) : (
                                <InputBase 
                                type={'text'} 
                                value={ answers?.find((v) => v.question_id == question_item?.question_id)?.value  }   
                                onChange={(event) => handleMainInputChange(event , question_item?.question_id )} 
                                placeholder={lang?.answer_} 
                                sx={Style?.questionInput} 
                                variant="outlined" 
                                autoComplete='off'
                                multiline={true}
                                rows={3}
                                />
                            )}
                            </Box>
                        </Box>
                        )}

                        <Box sx={Style?.footerContainer}>

                        <Box>
                            <Typography color='primary.text'> {lang?.score_} {question_item?.question_score} </Typography>
                        </Box>

                        {data?.aa_status === 'end' ? (
                            <Box>
                            <Typography sx={{fontSize:20,color:'primary.text'}}>
                                {lang?.edit_not_allowed_}
                            </Typography>
                            </Box>
                        ) : (
                            <Box >
                            <Button 
                                sx={Style?.saveBtn} 
                                onClick={() => saveQuestion(question_item?.question_id,question_item?.question_type)} >
                                {lang?.save_}
                            </Button>
                            </Box>
                        )}

                        </Box>

                    </Box>

                    )}
                
                )}

                </Box>
            </Box>
        </Dialog>
    )

}