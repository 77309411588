import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArticleIcon from '@mui/icons-material/Article';
import { useParams } from 'react-router-dom';
import { useData } from '../../config/Provider';

const TeacherAnswer = ({nav}) => {
  
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const {id} = useParams()
  const {lang} = useData()
 
  const getData = async () => {
    await API.get( `/teacher-assigned-answers/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-subject/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        
        <TableCell sx={{color:'primary.text'}} > {v.template_title} </TableCell> 
        
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/questions-answers/${v.aa_template_id}/${v.student_id}`)}>
            <ArticleIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          {v.student_fname} {v.student_lname}
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.title_ } ,
    { title : lang?.view_ } ,
    { title : lang?.students_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.students_answers_ }} />
      <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default TeacherAnswer