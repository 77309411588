export const Style = {
    Conatiner : {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        height:250,
        width:'80%',
        borderRadius:1,
        borderWidth:2,
        borderStyle:'solid',
        borderColor:'#ffffff',
        cursor:'pointer',
        mt:3
    } , 
      
    questionsContainer : {
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        backgroundColor:'primary.main',
        alignItems:'center',
        width:'77%',
        borderRadius:1,
        borderWidth:2,
        borderStyle:'solid',
        borderColor:'primary.border',
        p:3,
        mt:3
    } , 

    paragraphContainer : {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        backgroundColor:'primary.main',
        alignItems:'center',
        width:'77%',
        borderRadius:1,
        borderWidth:2,
        borderStyle:'solid',
        borderColor:'primary.border',
        p:3,
        mt:3
    } , 
  
    Selector :{
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        width:'100%' ,
        color:'primary.text',
        label : { color : 'red' },
        height:50
    } ,
  
    questionHeader : {
        display:'flex',
        flexDirection:'row',
        width:'100%',
        justifyContent:'space-between'
    } ,
  
    selectOptionContainer : {
        width:'25%'
    } ,
  
    questionInputContainer : { 
        width:'70%'
    } ,

    footerContainer : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        mt:3,
    } ,

    InnserFooterContainer : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
    } ,

    saveBtn:{
        backgroundColor:'primary.blue',
        color:'white',
        '&:hover':{
            backgroundColor:'primary.orange'
        }
    } ,

    answersInput : {
        input : {color:'primary.text' , },
        borderWidth:0,
        borderBottomWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1,
    } ,

    scoreInput : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1,
        ml:1,mr:1
    } ,

    questionInput: {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1,
        ml:1,mr:1
    } ,

    CardMedia : {
        width:150,
        height:"auto",
    },

    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2
    } ,
  
}