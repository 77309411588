import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const DismissedTeachers = ({nav}) => {
  
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const { lang , dir } = useData()
  const page = () => {
    nav('add-teacher')
  }

  const getData = async () => {
    await API.get( `/school-dismissed-teachers/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-dismissed-teachers/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const undismiss = async (id) => {
    await API.post( `/undismiss-teacher/` , {id:id} , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            const a = data.filter(t => t.teacher_id !== id);
            setData(a)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_lname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.user_email} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={()=>nav(`/edit-teacher/${v.teacher_id}`)}>
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton aria-label="dismiss"  onClick={ () => undismiss(v.teacher_id) }>
            <BlockIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.first_name_ } ,
    { title : lang?.last_name_ } ,
    { title : lang?.email_ } ,
    { title : lang?.edit_ } ,
    { title : lang?.undismiss_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.dismissed_teachers_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase value={search} onChange={(e) => setSearch(e.target.value)} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default DismissedTeachers