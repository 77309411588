
export const Style = {
    
    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2,
    } ,
    Row : { 
        borderColor:'red' , 
        transition:'0.2s' , 
        cursor : 'pointer' , 
        '&:hover' : { 
            backgroundColor : 'primary.main'
        } ,
        "& td": { 
            borderColor:'primary.main'
        }

    } ,

    Input : {
        input : {color:'primary.text' , },
        borderWidth:1,
        borderStyle:'solid',
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.main'},
        width:'100%' ,
        padding:1
    } , 

}

