import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField, Typography } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const TeacherEvaluation = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const { lang , dir } = useData()
  const page = () => {
    nav('add-evaluation')
  }

  const getData = async () => {
    await API.get( `/evaluations/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const searchData = async () => {
    await API.get( `/search-evaluation/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {JSON.parse(v.e_types)?.map((item,key) => <Typography key={key} color='primary.text'> {key+1}-{item?.title} </Typography>)} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.e_description} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.student_fname}  {v.student_lname} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/edit-evaluation/${v.e_id }`)}>
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        {/* <TableCell sx={{color:'primary.text'}} > 
          <IconButton aria-label="delete">
            <BlockIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell>  */}
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      searchData()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.evaluation_ } ,
    { title : lang?.note_ } ,
    { title : lang?.student_ } ,
    { title : lang?.edit_ } ,
    // { title : 'DELETE' } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.evaluations_ , buttonTitle : lang?.add_  , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default TeacherEvaluation