
const Vals = {
    // LOCALHOST  : 'http://localhost:3000' , 
    LOCALHOST  : 'https://classenda.app' , 
    // API  : 'http://localhost:3001' , 
    API  : 'https://api.classenda.app' , 
    // M_API  : 'http://localhost:3001' , 
    M_API  : 'https://api.classenda.app' , 
    
    GET_HEADER : {  
        mode:'cors',
        method : 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    } ,

    DWONLOAD_HEADER : {  
        mode:'cors',
        method : 'GET',
        responseType: 'blob',
        headers: {
            'Accept' : 'blob',
            'Content-Type': 'blob'
        }
    } ,
    
    POST_HEADER : {  
        mode:'cors',
        method : 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    } ,

    POST_UPLOAD_HEADER : {  
        mode:'cors',
        method : 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    } ,
 
}

export default Vals