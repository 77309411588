import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useData } from '../../config/Provider';
 
 

function Selector({ apiUrl }) {
  const [options, setOptions] = useState([{option:'yes'}]);
  const [selectedOption, setSelectedOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const {lang} = useData()
//   useEffect(() => {
//     axios.get(apiUrl)
//       .then(res => {
//         setOptions(res.data);
//       })
//       .catch(err => {
//         console.error(err);
//       });
//   }, [apiUrl]);

  const handleChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSearchTermChange = event => {
    setSearchTerm(event.target.value);
  };

//   const filteredOptions = options.filter(option => option?.includes(searchTerm));

  return (
    <div>
      <TextField
        label="Search"
        value={searchTerm}
        onChange={handleSearchTermChange}
        margin="normal"
      />
      <FormControl sx={{  margin: 1,minWidth: 120,}}>
        <InputLabel id="demo-simple-select-label">{lang?.options_}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption}
          onChange={handleChange}
          autoComplete='false'
        >
          {options?.map(v => (
            <MenuItem key={v?.option} value={v?.option}>{v?.option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default Selector;