import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useData } from '../../config/Provider';

const Langs = ({nav}) => {
  const [ data , setData ] = useState([])
  const { lang , dir } = useData()
  const page = () => {
    nav('add-language')
  }

  const getData = async () => {
    await API.get( `/get-langs/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const isRtl = async (e , id) => {
    await API.post( `/change-dir/` , { dir : e , id : id } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          getData()
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const isHide = async (e , id) => {
    await API.post( `/hide-lang/` , { hide : e , id : id } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          getData()
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {

    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={[Style?.Row]}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.lang_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <Button sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}}  aria-label="edit" onClick={() => isRtl(v?.lang_dir === 'rtl' ? 'ltr' : 'rtl',v?.lang_id)}>
            {v?.lang_dir === 'rtl' ? 'YES' : 'NO'}
          </Button>
        </TableCell>
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={()=>nav(`/lang-content/${v?.lang_id}`)}>
            <GTranslateIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell>
        {/* <TableCell sx={{color:'primary.text'}} > 
          <IconButton  aria-label="edit">
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell>  */}
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton aria-label="delete" onClick={() => isHide(v?.lang_hidden === 'n'?'y':'n' , v?.lang_id )}>
            {v?.lang_hidden === 'n' ? <VisibilityIcon sx={{color:'primary.orange'}} /> :<VisibilityOffIcon sx={{color:'primary.orange'}} />  }
          </IconButton>
        </TableCell> 
        {/* <TableCell sx={{color:'primary.text'}} > 
          <IconButton aria-label="delete">
            <DeleteTwoToneIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell>   */}
      </TableRow>
    )))
  } 

  useEffect(() => {
    getData()
  } , [])
 
  const Header = [
    { title : lang?.languages_ } ,
    { title : lang?.right_to_left_ } ,
    { title : lang?.translation_} ,
    // { title : lang?.edit_ } ,
    { title : lang?.active_ } ,
    // { title : lang?.delete_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.languages_ , buttonTitle : lang?.add_  , buttonIcon : <AddIcon /> , page : page }} />
      {/* <InputBase placeholder='Search' sx={Style?.Input} variant="outlined" autoComplete='off'/> */}
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Langs