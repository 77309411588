import React from 'react';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import Router from './Router';
import { FullScreenDialog, MobileNav, SideBar, TopBar } from '../components';
import { useData } from './Provider';
import { useEffect } from 'react';
import { API, useLocalState } from './functions';
import Vals from './Vars';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChooseLangs from '../components/chooseLangs/ChooseLangs';
import { io } from 'socket.io-client';

const SK = io(Vals?.API)

const Main = () => {
    
    const nav = useNavigate() 
    const { setSocket , socket , langDiloag , setLangDiloag , logged , setlogged , userType, showSidebar , setUserType  , setload , info, setInfo , popup , setPopup , setUserInfo } = useData()
    const [loading , setLoading ] = useState(true)
    const [load , setLoad ] = useState(true)
    const [ Islogged , setIslogged ] = useLocalState("Islogged" );

    const check = async () => {

        try {

            await API.get(`/check/`, Vals?.GET_HEADER)
    
            .then(function (response) {
                if (response.data.status === 'n') {
                    setIslogged(false)
                    setlogged(false) 
                    setUserType('')
                } 
        
                if (response.data.status === 'y') {
                    setSocket(SK)
                    SK?.emit('storeSocket', { user_id : response?.data?.id , socketId: SK?.id });
                    setlogged(true)
                    setIslogged(true)
                    setUserType(response.data.type) 
                    setInfo(response.data)
                    setUserInfo(response.data)
                    if (response.data.type === 'student') {
                        if (response?.data?.checkPop) {
                            setPopup({ show : response?.data?.checkPop , id  : response?.data?.popupQuiz })
                        } else {
                            setPopup({ show : response?.data?.checkPop , id  : 0 })
                        }
                    }
                } 

                if (response?.data?.status === false ) {
                    setlogged(false)
                    setIslogged(false)
                    setUserType('') 
                    nav('/login/')
                    // window.location.href = '/login/'
                } 

                setLoading(false)

            })
      
        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {
        check()
    } , [logged])

    useEffect(() => {
        SK?.on('popupRequest' , async (req) => {
            setPopup({ id : req?.template_id , show: true })
        })
        return () => SK?.disconnect()
    } , [])

 
    if ( logged && !loading ) {
        return ( 
            <Grid container sx={{  flexDirection:'row' , width:'100%' , backgroundColor:'primary.bg'}}>

                <Grid item={true} sx={{width:'100%'}} >
                { popup?.show && ( <FullScreenDialog /> ) }
                </Grid>
                <ChooseLangs {...{open : langDiloag , set : setLangDiloag }} />
                <Grid item={true} sx={{ width:{ xs:'0%',sm:'0%',md:'0%', lg:showSidebar?'15%':'0%',xl:showSidebar?'15%':'0%'}}} >
                    <SideBar />
                </Grid>
                <Grid item={true} sx={{ minHeight: '100vh' , height:'100%' , width: { xs:'100%',sm:'100%',md:showSidebar?'85%':'100%'} }}  >
                    <TopBar />
                    <MobileNav />
                    <Router />
                </Grid>
            </Grid>
        )
    }

    if (!logged && !loading)  return( <Router /> )   
  
}

export default Main;