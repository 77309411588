import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { DataTable, DialogWindow, Selector, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import BlockIcon from '@mui/icons-material/Block';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useData } from '../../config/Provider';

const SchoolTimeTable = ({nav}) => {
  
  const [ days , setDays ] = useState([])
  const [ subjects , setSubjects ] = useState([])
  const [ divisions , setDivisions ] = useState([])
  const [ Header , setHeader ] = useState([])
  const [ openDialog , setOpenDialog ] = useState(false)
  const [ subject , setSubject ] = useState(0)
  const [ day , setDay ] = useState(0)
  const [ dayStr , setDayStr ] = useState('')
  const [ teacher , setTeacher ] = useState(0)
  const [teacherSubject ,setTeacherSubject ] = useState([])
  const [checkTeacher ,setCheckTeacher ] = useState(0)
  const [classroomId ,setClassroomId ] = useState(0)
  const [classroomTitle ,setClassroomTitle ] = useState('')
  const [oldTeacherName ,setOldTeacherName ] = useState('')
  const {lang} = useData()

  const getSub  = (day_id,subject_num) => {
    var val = '--' 
    var t_id = 0
    for ( var i = 0 ; i < teacherSubject?.length ; i++ ) {
      if (subject_num == teacherSubject[i]?.tb_sort && day_id == teacherSubject[i]?.tb_day_id ) {
        val = `${teacherSubject[i]?.teacher_fname} ${teacherSubject[i]?.teacher_lname} / ${teacherSubject[i]?.subject_title}`
        t_id = teacherSubject[i]?.teacher_id
      }
    }

    return {name : val,id : t_id}
  }

  const getData = async (id) => {
    await API.get( `/get-table/${id}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setDays(response?.data?.days)
          setDivisions(response?.data?.division)
          setTeacherSubject(response?.data?.subjects)
          var rows = []
          var Headerrows = []
          
          Headerrows?.push({
            title : 'Days'
          })

          for (var i = 0 ; i < response?.data?.division[0]?.cr_count ; i++) {
            
            rows?.push({ 
              title : '--',
              num : i+1,
            })

            Headerrows?.push({
              title : i+1 + ' ' + lang?.subject_
            })
        
          }

          setHeader(Headerrows)
          setSubjects(rows)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  }

  const postData = async () => {

    if ( day == 0 || teacher == 0 || subject == 0 || classroomId == 0 ) {
      return false
    }

    await API.post( `/add-timetable/` , { day:day,teacher:teacher,subject:subject , id : classroomId  } , Vals?.POST_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        getData(classroomId)
        setOpenDialog(false)
        setTeacher(0)
      } else {
        console.log('Something went wrong !!')
      }
    })
    .catch((e) => console.log(e) )
  }

  const setInfo = (num,day,str,t_id,oldTeacher) => {
    setOldTeacherName(oldTeacher)
    setSubject(num) 
    setDay(day)
    setDayStr(str)
    setOpenDialog(true)
    setCheckTeacher(t_id)
  }

  const TableLoop = () => {
    return ( days?.map( ( v , k ) => 
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.wd_title} </TableCell> 
        {subjects?.map((item,k) => (
              <TableCell sx={{color:'primary.text'}} key={`${k}x`} onClick={() => setInfo(item.num , v.wd_id , v.wd_title , getSub( v.wd_id  , item.num)?.id , getSub( v.wd_id  , item.num)?.name )} > { getSub( v.wd_id  , item.num)?.name }</TableCell>
        ))}
      </TableRow>
    ))
  } 
 
  const Close = () => {
    setClassroomId(0)
  }
 
  const Table = () => (
    <>
      <TableHeader {...{ title:lang?.timetable_  , buttonIcon : <ArrowBackIcon /> , buttonTitle: lang?.back_ , page: Close   }} />
      <Typography color='primary.text' ml={1}>{lang?.classroom_} : {classroomTitle}</Typography>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </>
  )
 
  const Teachers = () => {

    const [ search , setSearch ] = useState('')
    const [ teachers , setTeachers ] = useState([])

    const getTeacher = async () => {
      await API.get( `/select-teachers/${checkTeacher}/${day}/${subject}` , Vals?.GET_HEADER )
      .then( ( response )  => setTeachers(response?.data?.data) )
      .catch((e) => console.log(e) )
    }

    const getSearch = async () => {
      await API.get( `/select-teachers-search/${search}/${checkTeacher}/${day}/${subject}` , Vals?.GET_HEADER )
      .then( ( response )  => setTeachers(response?.data?.data) )
      .catch((e) => console.log(e) )
    }

    const DeleteTeacher = async () => {
      await API.post( `/delete-teacher-timetable/`,{
        teacher_id:checkTeacher,
        day_id:day,
        subject_number:subject,
        classroom_id:classroomId
      }, Vals?.POST_HEADER )
      .then( ( response )  => {
        if (response?.data?.status === true) {
          getData(classroomId)
          setOldTeacherName('--')
          setTeacher(0)
        } else {
          console.log('something went wrong!!')
        }
      })
      .catch((e) => console.log(e) )
    }

    useEffect(() => {
      getTeacher()
    } , [])

    useEffect(() => {
      if (search?.length > 0) {
        getSearch()
      } else {
        getTeacher()
      }
    } , [search?.length])

    const Tag = ({item}) => (
        <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : teacher === item?.teacher_id ? 'primary.orange' : 'primary.border' }]} onClick={() => setTeacher(item?.teacher_id)}>
    
            <Typography color='primary.text'>{item?.teacher_fname} {item?.teacher_lname} {item?.teacher_fname}  / {item?.subject_title}</Typography>
        </Box>
    )

    return (
      <Box sx={[Style?.TagContainer , {  mt:1 }]}>
        {oldTeacherName === '--' ? 
        <>
          <Typography variant='h6'> Select Teacher </Typography>
          <TextInput {...{value:search,set:setSearch,placeholder:'Search',type:'text'}} />
          <Box sx={[Style?.TagInnerContainer , { borderColor:'black' }]}>
              {teachers?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
          </Box>
        </>

        : 
        
        <>
        
        <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : 'primary.orange' }]} >
            <Typography mr={1} color='primary.text'>{oldTeacherName}  </Typography>
            <ClearRoundedIcon  onClick={DeleteTeacher} color='primary.text'/>
        </Box>
        
        </>
        
        }

      </Box>
    )

  }

  const ClassRoomsSelect = () => {

    const [ search , setSearch ] = useState('')
    const [ classrooms , setClassrooms ] = useState([])
    const [ searchClassroomWord , setSearchClassroomWord ] = useState('')
    
    const getClassrooms = async () => {
      await API.get( `/school-divisions/` , Vals?.GET_HEADER )
      .then( ( response )  => setClassrooms(response?.data?.data) )
      .catch((e) => console.log(e) )
    }

    const searchClassrooms = async () => {
      await API.get( `/search-school-divisions/${searchClassroomWord}` , Vals?.GET_HEADER )
      .then( ( response )  => setClassrooms(response?.data?.data) )
      .catch((e) => console.log(e) )
    }
 
    useEffect(() => {
      if (searchClassroomWord?.length > 0) {
        searchClassrooms()
      } else {
        getClassrooms()
      }
    } , [searchClassroomWord?.length])

    const Select = (id,e) => {
      setClassroomId(id)
      getData(id)
      setClassroomTitle(e)
    }

    const Tag = ({item}) => (
        <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : teacher === item?.teacher_id ? 'primary.orange' : 'primary.border' }]}
           onClick={() => Select(item?.division_id , `${item?.cr_title}  / ${item?.division_title}`)} >
            <Typography color='primary.text'> {item?.cr_title}  / {item?.division_title}</Typography>
        </Box>
    )

    return (
      <Box sx={[Style?.TagContainer , {  mt:1 }]}>
          <Typography variant='h6' color='primary.text'> {lang?.select_classroom_} </Typography>
          <TextInput {...{value:searchClassroomWord,set:setSearchClassroomWord,placeholder:lang?.search_,type:'text'}} />
          <Box sx={[Style?.TagInnerContainer , { borderColor:'black' }]}>
              {classrooms?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
          </Box>
      </Box>
    )
  }

  const DialogData = () => (
    <Box sx={{height:'auto' , width:500}}>
      <Teachers />
    </Box>
  )
 
  return (
    <Box sx={Style?.Container}>

      {classroomId == 0 ? 
      
      <ClassRoomsSelect />
      
      :  
      <>
        <DialogWindow {...{ 
          open:openDialog , 
          setOpen:setOpenDialog,
          Data:DialogData,
          title:oldTeacherName === '--' ?`${lang?.set_the_} ${subject} ${lang?.subject_for_} ${classroomTitle} ${lang?.classroom_in_} ${dayStr}`: lang?.delete_current_teacher_to_add_new_one_,
          func:postData
         }}/>
        <Table />
      </>

      
      }

    </Box>
  )
}

export default SchoolTimeTable