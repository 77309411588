export const Style = {
    Container : {
        backgroundColor:'primary.main',
        top:0,
        bottom:0,
        height:'100%',
        width:{xs:'100%',sm:'100%',md:'15%',lg:'15%',xl:'15%'},
        display:{xs:'none' , md : 'flex'} ,
        flexDirection : 'column' ,
        justifyContent : 'flex-start' ,
        position: { 
            md:'fixed'
        },
        zIndex:1,
        overflow: 'scroll',
        boxShadow:'3',
        alignItems:'center'
    } ,

    AvatarCon : {
        width:'80%',
        borderWidth:1,
        borderColor:'primary.border',
        borderStyle:'solid',
        p:1,
        borderRadius:1,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        cursor:'pointer',
     } ,

     AvatarMCon : {
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
     } ,

    ListItem : {
        borderRadius : 20 , 
        pt:0.7,
        pb:0.7,
        mt:1 ,
    } ,
    ItemColor : {
        color:'primary.text' ,
        textAlign:'center',
        alignItems:'center',
        justifyContent : 'center' ,
        display:'flex',
    } ,
    mainBtn : {
        display:'flex',
        flexDirection : 'row' ,
        justifyContent : 'center' ,
        alignItems:'center',
        // backgroundColor : '#c1c1c1' ,
        // mt:0.5,
        textAlign:'center',
        width:'90%'
    }
}