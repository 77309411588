import {  Avatar, IconButton, InputBase  } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback } from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, HandleCreate64ForState, validateEmail } from '../../config/functions';
import { useData } from '../../config/Provider';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import DeleteIcon from '@mui/icons-material/Delete';

const AddSchool = ({nav}) => {
  const [ image , setImage ] = useState(null)
  const [ email , setEmail ] = useState('')
  const [ emailValid , setEmailValid ] = useState(true)
  const [ name , setName ] = useState('')
  const [ schoolName , setSchoolName ] = useState('')
  const [ password , setPassword ] = useState('')
  const [ num , setNum ] = useState('')
  const {lang} = useData()
 
  // const page = async () => {
    
  //   if (num == 0 || !num) {
  //     return false
  //   }

  //   await API.post( `/add-school/` , { name : name , number : num , email : email , password : password } , Vals?.POST_HEADER )
  //   .then( function ( response ) {
  //       if (response?.data?.status === true) {
  //           nav('/schools/')
  //       } else {
  //           console.log(response?.data?.msg)
  //       }
  //   })
  //   .catch((e) => console.log(e) )

  // }

  const page = async () => {

    if (!validateEmail(email)) {
      setEmailValid(validateEmail(email))
      return false
    } else {
      setEmailValid(validateEmail(email))
    }
    
    if (num == 0 || !num) {
      return false
    }

    try {

      var data = new FormData();
      
      data.append('name', name);
      data.append('number', num);
      data.append('email', email);
      data.append('password', password);
      data.append('schoolName', schoolName);
      
      if ( image?.base64 ) {
        data.append( `image` , image?.blob , image?.name)
      }  

      await API.post(`/add-school/`, data , Vals?.POST_UPLOAD_HEADER )
      .then(function (response) {
        if (response?.data?.status === true ){ 
            nav('/schools/')
        } else {
          console.log(  response?.data?.msg )
        }
      }).catch(function (error) {
        console.log(error)
      });
    } catch (e) {
      console.log(e)
    }
  }

  const selectImg = HandleCreate64ForState(setImage)

  const deleteImg = () => {
    setImage(null)
  }

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.add_school_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />

      <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
            <InputBase value={email} onChange={(e) => setEmail(e.target.value)} placeholder={lang?.email_} sx={[Style?.Input , { borderColor : emailValid ? 'primary.border' : 'red' } ]} variant="outlined" autoComplete='off'/>
            <InputBase value={name} onChange={(e) => setName(e.target.value)} placeholder={lang?.name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={password} onChange={(e) => setPassword(e.target.value)} placeholder={lang?.password_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={schoolName} onChange={(e) => setSchoolName(e.target.value)} placeholder={lang?.school_name_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
            <InputBase value={num} onChange={(e) => setNum(e.target.value)} placeholder={lang?.student_number_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
        </Box>

        <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
          <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' , flexDirection:'column'}}>
            <Box sx={{height:140,width:140,position:'relative'}}>
              <Avatar src={image?.base64} sx={{height:140,width:140}} variant='rounded' />
              { image?.base64 ? 
                <IconButton onClick={deleteImg} 
                            color="white" 
                            sx={Style?.iconBtn} 
                          >
                  <DeleteIcon />
                </IconButton>  
              : 
                <IconButton color="white" sx={Style?.iconBtn} aria-label="upload picture" component="label">
                  <input  onChange={selectImg}  hidden accept="image/*" type="file" />
                  <PhotoCameraBackIcon />
                </IconButton>
              }
            </Box>
          </Box>
        </Box>

      </Box>

    </Box>
  )
}

export default AddSchool