import React from 'react';
import { Box, Typography } from '@mui/material';
import { useData } from '../../config/Provider';

const getCurrentDate = () => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];

  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  return `${month}, ${year}`;
}

const getWeekDaysArray = (currentDate) => {
  const daysArray = [];
  const currentDay = currentDate.getDay();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDay);

  for (let i = 0; i < 9; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    daysArray.push(date);
  }

  return daysArray;
};

const CalendarCurrentDate = () => {
  const currentDate = new Date();
  const weekDaysArray = getWeekDaysArray(currentDate);

  const getWeekdayShortName = (date) => {
    return date.toLocaleString('en-us', { weekday: 'short' }).toUpperCase();
  };

  const { lang } = useData();
  const currentDateMonth = getCurrentDate();

  const Style = {
    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    weekRow: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      p: 2,
    },
    dayCell: {
      width: '50px',
      height: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    currentDayCell: {
      width: '50px',
      height: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:'#2685c9',
      borderRadius:5
    },
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
        <Typography fontWeight={'bold'} fontSize={16} color='primary.text'> {lang?.date_} </Typography>
        <Typography fontSize={16} color='primary.text'> {currentDateMonth} </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5effd', borderRadius: 3 }}>
        <Box sx={Style?.weekRow}>
          {weekDaysArray.map((date) => (
            <Box key={date} sx={date.getDate() === currentDate.getDate() ? Style?.currentDayCell : Style?.dayCell}>
              <Typography variant="subtitle1" sx={{ color:date.getDate() === currentDate.getDate() ? 'white' : '#a29caa' , fontSize: 13 }}>
                {getWeekdayShortName(date)}
              </Typography>
              <Typography variant="h6" sx={{ color: date.getDate() === currentDate.getDate() ? 'white' : '#3e3940' , fontSize: 25, fontWeight: 'bold' }}>{date.getDate()}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarCurrentDate;
