import React from 'react'
import { InputBase  } from '@mui/material'

const QuestionTextInput = ({value,func,placeholder,type,name}) => {

    const Style = {
        Input : {
            input : {color:'primary.text' , },
            borderWidth:1,
            borderStyle:'solid',
            borderColor:'primary.border',
            backgroundColor:'primary.main' , 
            '&:hover' : {  backgroundColor : 'primary.main'},
            width:'100%' ,
            padding:1,
            mt:2
        } , 
    }
    
    return (
        <InputBase type='text' value={value} onChange={func} name={name} placeholder={placeholder} sx={Style?.Input} variant="outlined" autoComplete='off'/>
    )
}

export default QuestionTextInput