import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import DialogWindow from '../dialogWindow/DialogWindow'
import { useData } from '../../config/Provider'

const SelectClassroom = ({setOpen,open,classroom , setClassroom , func}) => {
    const {lang}=useData()
    const Style = {
        Tag : {
            borderRadius:15,
            pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
            float:'left',
            alignItem:'canter',
            display:'flex',
            cursor:'pointer',
            borderWidth:0,
            borderWidth:1,
            borderStyle:'solid',
        } ,
    }
    
    const [ data , setData ] = useState([])
    const [ classroomData , setClassroomData ] = useState([])
 
    const [ title , setTitle ] = useState('')
    const [ search , setSearch ] = useState('')

    useEffect(() => {
        setClassroom(0)
    } , [open])

    const getData = async () => {
        await API.get( `/teacher-classrooms/` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setData(response?.data?.data)
          } else {
            alert(response?.data?.msg)
          }
        })
        .catch((e) => alert(e) )
      }
    
      const getClassroom = async ()  => {
        await API.get( `/teacher-classroom-attend/${classroom}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setClassroomData(response?.data?.data)
          } else {
            alert(response?.data?.msg)
          }
        })
        .catch((e) => alert(e) )
      }

      const getSearch = async () => {
        await API.get( `/teacher-search-classrooms/${search}` , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
              setData(response?.data?.data)
            } else {
              alert(response?.data?.msg)
            }
        })
        .catch((e) => alert(e) )
      }


    
      useEffect(() => {
        
        if (search?.length > 0 ) {
          getSearch()
        } else {
          getData()
        }
      }, [search?.length])

    const Tags = ({items}) => {  

        const select = (e) => {
            setClassroom(e)
        }
      
       return (
        <>
        {items?.map(( item,key ) => (
            <Box key={key} sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
                onClick={() => select(item?.division_id)} >
                <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
            </Box>
        ))}
  
        </>
      )
    }
      const DialogData = () => (
        <Box sx={{height:'auto' , width:500}}>
          <Tags items={data} />
        </Box>
      )

  return (
    <DialogWindow {...{ 
        open:open , 
        setOpen:() => setOpen(!open),
        Data:DialogData,
        title:lang?.select_classroom_,
        func: func
    }}/>
  )
}

export default SelectClassroom