import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { DataTable, DialogWindow, Selector, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import BlockIcon from '@mui/icons-material/Block';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useData } from '../../config/Provider';

const StudentTimeTable = ({nav}) => {
  const [ days , setDays ] = useState([])
  const [ subjects , setSubjects ] = useState([])
  const [ divisions , setDivisions ] = useState([])
  const [ Header , setHeader ] = useState([])
  const [ openDialog , setOpenDialog ] = useState(false)
  const [ subject , setSubject ] = useState(0)
  const [ day , setDay ] = useState(0)
  const [ dayStr , setDayStr ] = useState('')
  const [ teacher , setTeacher ] = useState(0)
  const [teacherSubject ,setTeacherSubject ] = useState([])
  const [checkTeacher ,setCheckTeacher ] = useState(0)
  const [classroomId ,setClassroomId ] = useState(0)
  const [classroomTitle ,setClassroomTitle ] = useState('')
  const [oldTeacherName ,setOldTeacherName ] = useState('')
  const {lang} = useData()
  const getSub  = (day_id,subject_num) => {
    var val = '--' 
    var t_id = 0
    for ( var i = 0 ; i < teacherSubject?.length ; i++ ) {
      if (subject_num == teacherSubject[i]?.tb_sort && day_id == teacherSubject[i]?.tb_day_id ) {
        val = `${teacherSubject[i]?.subject_title} / ${teacherSubject[i]?.teacher_fname} ${teacherSubject[i]?.teacher_lname} `
      }
    }

    return {name : val,id : t_id}
  }

  const getData = async () => {
    await API.get( `/get-student-table/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setDays(response?.data?.days)
          setDivisions(response?.data?.division)
          setTeacherSubject(response?.data?.subjects)
          var rows = []
          var Headerrows = []
          
          Headerrows?.push({
            title : lang?.days_ 
          })

          for (var i = 0 ; i < response?.data?.subjects[0]?.cr_count ; i++) {
            
            rows?.push({ 
              title : '--',
              num : i+1,
            })

            Headerrows?.push({
              title : i+1 + ' ' + lang?.subject_
            })
        
          }

          setHeader(Headerrows)
          setSubjects(rows)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )

  }

  useEffect(() => {
    getData()
  } , [])
 
  const TableLoop = () => {
    return ( days?.map( ( v , k ) => 
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.wd_title} </TableCell> 
        {subjects?.map((item,k) => (
          <TableCell sx={{color:'primary.text'}} key={`${k}x`} > { getSub( v.wd_id  , item.num)?.name } </TableCell>
        ))}
      </TableRow>
    ))
  } 
 
  const Table = () => (
    <>
      <TableHeader {...{ title:lang?.timetable_}} />
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </>
  )
 
  return (
    <Box sx={Style?.Container}>
      <Table />
    </Box>
  )
}

export default StudentTimeTable