import { React, useState  }  from 'react'
import { List, ListItemIcon, ListItemText, ListSubheader,ListItemButton,Collapse, CardMedia, Box,Button, Avatar, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { Style } from './Style';
import { useData } from '../../config/Provider';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';
import { Logout } from '@mui/icons-material';
import { useEffect } from 'react';
import AlarmOnTwoToneIcon from '@mui/icons-material/AlarmOnTwoTone';
import WbCloudyIcon from '@mui/icons-material/WbCloudy';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import EventAvailableTwoToneIcon from '@mui/icons-material/EventAvailableTwoTone';
import Person3TwoToneIcon from '@mui/icons-material/Person3TwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import SmsIcon from '@mui/icons-material/Sms';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BedroomChildTwoToneIcon from '@mui/icons-material/BedroomChildTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import TableRestaurantTwoToneIcon from '@mui/icons-material/TableRestaurantTwoTone';
import ArticleIcon from '@mui/icons-material/Article';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GradingIcon from '@mui/icons-material/Grading';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CopyrightIcon from '@mui/icons-material/Copyright';

const SideBar = ({toggleMobile,toggleMobileClose}) => {
    
    const { page, setPage , logged , setlogged , userType , dir , lang , info } = useData()
    const [ Islogged , setIslogged ] = useLocalState("Islogged" ); 
    const [ itmes , setItems ] = useState([])
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();
    const navigatePage = (page,title) => {
        setPage(title)
        navigate(page)
    }
 
    const MasterLeftbarItems = [
        {

            title : lang?.admin_dashboard_,
            
            list : [
                {
                    title : lang?.general_ ,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },

                {
                    title : lang?.blocked_schools_ ,
                    dropdown : [],
                    icon : <BlockIcon sx={{color:'common'}} />,
                    link : '/blocked-schools/',
                },

                {
                    title : lang?.videos_ ,
                    dropdown : [],
                    icon : <OndemandVideoIcon sx={{color:'common'}} />,
                    link : '/videos/',
                },

                {
                    title : lang?.languages_,
                    dropdown : [
                        { title : lang?.lists_ , link:'/languages/' } , 
                        { title : lang?.add_new_ , link:'/add-language/' } , 
                    ],
                    icon : <GTranslateIcon sx={{color:'common'}} />,
                    link : '',
                },
    
    
    
            ],
        }, 
    ]

    const StudentLeftbarItems = [
        {

            title : lang?.student_dashboard_ ,
            
            list : [
                {
                    title : lang?.general_ ,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },

                {
                    title : lang?.chat_ ,
                    icon : <SmsIcon  />,
                    dropdown : [ 
                        { title : lang?.chat_ , link : '/chat/'  } , 
                        { title : lang?.group_ , link : '/chat-group/'  } , 
                    ],
                    link : '',
                },
                {
                    title : lang?.timetable_,
                    icon : <AlarmOnTwoToneIcon  />,
                    dropdown : [],
                    link : '/timetable/',
                },

                {
                    title : lang?.calendar_,
                    icon : <CalendarMonthIcon  />,
                    dropdown : [],
                    link : '/calendar/',
                },

                {
                    title : lang?.assessments_,
                    icon : <ArticleIcon  />,
                    dropdown : [ ] ,
                    link : '/assessments/',
                },

                {
                    title : lang?.class_work_,
                    icon : <PeopleOutlineIcon  />,
                    dropdown : [  ] ,
                    link : '/classwork/',
                },
                

                {
                    title : lang?.homework_,
                    icon : <NoteAltIcon  />,
                    dropdown : [  ] ,
                    link : '/homework/',
                },

                {
                    title : lang?.comprehension_,
                    icon : <ReceiptIcon  />,
                    dropdown : [  ] ,
                    link : '/comprehension/',
                },

                {
                    title : lang?.pop_quiz_,
                    icon : <ScreenshotMonitorIcon  />,
                    dropdown : [ ] ,
                    link : '/pop-up-quizes/',
                },

                {
                    title : lang?.grades_,
                    icon : <GradingIcon  />,
                    dropdown : [ ] ,
                    link : '/student-grades/',
                },

                {
                    title : lang?.reports_,
                    dropdown : [],
                    icon : <EqualizerIcon sx={{color:'common'}} />,
                    link : '/report/',
                },

                {
                    title : lang?.announcements_,
                    icon : <CampaignTwoToneIcon  />,
                    dropdown : [] ,
                    link : '/student-announcements/',
                },
    
            ],
        }, 
    ]

    const ParentLeftbarItems = [
        {
            title : lang?.parent_dashboard_ ,
            list : [
                {
                    title : lang?.general_ ,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },
                {
                    title : lang?.timetable_,
                    icon : <AlarmOnTwoToneIcon  />,
                    dropdown : [],
                    link : '/timetable/',
                },

                {
                    title : lang?.teachers_,
                    icon : <PeopleAltIcon  />,
                    dropdown : [],
                    link : '/teachers/',
                },

                {
                    title : lang?.reports_,
                    dropdown : [],
                    icon : <EqualizerIcon sx={{color:'common'}} />,
                    link : '/reports/',
                },

                {
                    title : lang?.calendar_,
                    icon : <CalendarMonthIcon  />,
                    dropdown : [],
                    link : '/calendar/',
                },

                {
                    title : lang?.chat_,
                    icon : <SmsIcon  />,
                    dropdown : [ 
                        { title : lang?.teachers_ , link : '/chat/' }, 
                        { title : lang?.school_ , link : 'school-chat' }, 
                        { title : lang?.guidance_ , link : '/guidance-chat/' }, 
                    ],
                    link : '',
                },
                {
                    title : lang?.assessments_,
                    icon : <DescriptionIcon  />,
                    dropdown : [ ] ,
                    link : '/assessments/',
                },
                {
                    title : lang?.medical_documents_,
                    icon : <SummarizeIcon  />,
                    dropdown : [ ] ,
                    link : '/parent-medical-docs/',
                },

                {
                    title : lang?.student_documents_,
                    icon : <FolderSharedTwoToneIcon  />,
                    dropdown : [ ] ,
                    link : '/student-docs/',
                },

                {
                    title : lang?.grades_,
                    icon : <ArticleIcon  />,
                    dropdown : [ ] ,
                    link : '/student-grades/',
                },
                {
                    title : lang?.announcements_,
                    icon : <CampaignTwoToneIcon  />,
                    dropdown : [ ] ,
                    link : '/student-announcements/',
                },
            ],
        }, 
    ]

    const SchoolLeftbarItems = [
        {

            title : lang?.main_menu_ ,
            
            list : [
                {
                    title : lang?.general_,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },
                
                
                {
                    title : lang?.subjects_,
                    dropdown : [],
                    icon : <StyleTwoToneIcon sx={{color:'common'}} />,
                    link : '/subjects/',
                },

                {
                    title : lang?.divisions_,
                    icon : <TableRestaurantTwoToneIcon  />,
                    dropdown : [
                        { title : lang?.lists_ , link : '/divisions/' } ,
                        { title : lang?.add_new_ , link : '/add-division/' } ,
                    ],
                    link : '',
                },

                {
                    title : lang?.classrooms_,
                    icon : <BedroomChildTwoToneIcon  />,
                    dropdown : [
                        { title : lang?.lists_ , link : '/classrooms/' } ,
                        { title : lang?.add_new_ , link : '/add-classroom/' } ,
                    ],
                    link : '',
                },



                {
                    title : lang?.timetable_,
                    icon : <MenuBookTwoToneIcon  />,
                    dropdown : [],
                    link : '/school-timetable/',
                },

                {
                    title : lang?.calendar_,
                    icon : <CalendarMonthIcon  />,
                    dropdown : [],
                    link : '/school-calendar/',
                },

                {
                    title : lang?.grades_,
                    icon : <ArticleIcon  />,
                    dropdown : [ ] ,
                    link : '/grades/',
                },

                {
                    title : lang?.students_,
                    dropdown : [
                        {
                            link : '/students/' ,
                            title : lang?.active_
                        } , 
                        {
                            link :'/dismissed-students/',
                            title:lang?.dismissed_
                        }

                    ],
                    icon : <PeopleIcon sx={{color:'common'}} />,
                    link : '',
                },
                {
                    title : lang?.teachers_,
                    dropdown : [
                        
                        {
                            link : '/teachers/' ,
                            title : lang?.active_
                        } , 
                        {
                            link :'/dismissed-teachers/',
                            title:lang?.dismissed_
                        }
                    ],
                    icon : <SwitchAccountIcon sx={{color:'common'}} />,
                    link : '',
                },
                {
                    title : lang?.reports_,
                    dropdown : [],
                    icon : <EqualizerIcon sx={{color:'common'}} />,
                    link : '/reports/',
                },

                {
                    title : lang?.chat_,
                    icon : <SmsIcon  />,
                    dropdown : [ ],
                    link : '/chat/',
                },

            ],


        }, 

        {

            // title : lang?.add_ ,
            
            list : [

                {
                    title : lang?.announcements_,
                    dropdown : [],
                    icon : <CampaignTwoToneIcon sx={{color:'common'}} />,
                    link : '/school-announcements/',
                },

                {
                    title : lang?.guidances_,
                    dropdown : [
                        {
                            link : '/guidances/' ,
                            title : lang?.active_
                        } , 
                        {
                            link :'/dismissed-guidances/',
                            title:lang?.dismissed_
                        }
                    ],
                    icon : <PeopleAltIcon sx={{color:'common'}} />,
                    link : '',
                },


                {
                    title : lang?.settings_,
                    dropdown : [],
                    icon : <SettingsIcon sx={{color:'common'}} />,
                    link : '/settings/',
                },
    
    
            ],


        }
    ]

    const TecherLeftbarItems =  [

        {

            title : lang?.teacher_dashboard_,
            
            list : [
                {
                    title : lang?.general_,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },

                {
                    title : lang?.timetable_,
                    icon : <AlarmOnTwoToneIcon  />,
                    dropdown : [],
                    link : '/timetable/',
                },

                {
                    title : lang?.announcements_,
                    dropdown : [
                        { title : lang?.my_announcements_  , link : '/my-announcements/' } ,
                        { title : lang?.announcements_  , link : '/teacher-announcements/' } ,
                        { title : lang?.add_new_  , link : '/add-announcement/' } ,
                    ],
                    icon : <CampaignTwoToneIcon sx={{color:'common'}} />,
                    link : '',
                },

                {
                    title : lang?.calendar_,
                    icon : <CalendarMonthIcon  />,
                    dropdown : [],
                    link : '/calendar/',
                },

                {
                    title : lang?.grades_,
                    dropdown : [],
                    icon : <GradingIcon sx={{color:'common'}} />,
                    link : '/teacher-grades/',
                },
                
                {
                    title : lang?.assessments_,
                    dropdown : [
                        {title: lang?.lists_ ,link:'/assessments/'},
                        {title: lang?.add_ ,link:'/add-assessment/'},
                        {title: lang?.assigned_ ,link:'/assigned-assessment/'},
                    ],
                    icon : <ArticleIcon sx={{color:'common'}} />,
                    link : '',
                },
                

                {
                    title : lang?.reports_,
                    dropdown : [],
                    icon : <EqualizerIcon sx={{color:'common'}} />,
                    link : '/reports/',
                },
                
                {
                    title : lang?.classrooms_,
                    icon : <ShoppingBagTwoToneIcon  />,
                    dropdown : [ ],
                    link : '/classrooms/',
                },

                {
                    title : lang?.chat_,
                    icon : <SmsIcon  />,
                    dropdown : [ 
                        { title : lang?.chat_ , link : '/chat/'  } , 
                        { title : lang?.group_ , link : '/chat-group/'  } , 
                     ],
                    link : '',
                },
 

                {
                    title : lang?.attendance_,
                    icon : <EventAvailableTwoToneIcon  />,
                    dropdown : [ ] ,
                    link : '/attendance/',
                },

                {
                    title : lang?.evaluation_,
                    dropdown : [],
                    icon : <PsychologyAltTwoToneIcon />,
                    link : '/evaluations/',
                },
    
            ],
        },
    
    ]

    const GuidanceLeftbarItems = [
        {

            title : lang?.guidance_dashboard_,
 
            list : [
                {
                    title : lang?.general_ ,
                    dropdown : [],
                    icon : <WbCloudyIcon sx={{color:'common'}} />,
                    link : '/general/',
                },
                {
                    title : lang?.timetable_,
                    icon : <AlarmOnTwoToneIcon  />,
                    dropdown : [],
                    link : '/timetable/',
                },

                {
                    title : lang?.teachers_,
                    icon : <PeopleAltIcon  />,
                    dropdown : [],
                    link : '/teachers/',
                },

                {
                    title : lang?.calendar_,
                    icon : <CalendarMonthIcon  />,
                    dropdown : [],
                    link : '/calendar/',
                },
                
                {
                    title : lang?.reports_,
                    dropdown : [],
                    icon : <EqualizerIcon sx={{color:'common'}} />,
                    link : '/reports/',
                },
                {
                    title : lang?.chat_,
                    icon : <SmsIcon  />,
                    dropdown : [ ],
                    link : '/chat/',
                },
    
                {
                    title : lang?.medical_documents_,
                    icon : <SummarizeIcon  />,
                    dropdown : [ ] ,
                    link : '/medical-docs/',
                },

                {
                    title : lang?.student_documents_,
                    icon : <FolderSharedTwoToneIcon  />,
                    dropdown : [ ] ,
                    link : '/student-docs/',
                },

                {
                    title : lang?.grades_,
                    icon : <ArticleIcon  />,
                    dropdown : [ ] ,
                    link : '/student-grades/',
                },
                {
                    title : lang?.announcements_,
                    icon : <CampaignTwoToneIcon  />,
                    dropdown : [ ] ,
                    link : '/student-announcements/',
                },
            ],
        }, 
    ]
 
    const logout = async () => {
        try {
            await API.post(`/logout/`, Vals?.POST_HEADER)
            .then(function (response) {
                if (response?.data?.status === 'ok') {
                    setlogged(false)
                    setIslogged(false)
                    navigate('/login/')
                    // window.location.href='/#/login'
                } else {
                    alert(response?.data?.msg)
                }
            })
        } catch (error) {
            alert(error)
        }
    }
 
    useEffect(() => {
        if (userType === 'master') {
            setItems(MasterLeftbarItems)
        } else if (userType === 'teacher') {
            setItems(TecherLeftbarItems)
        } else if (userType === 'student') {
            setItems(StudentLeftbarItems)
        } else if ( userType === 'school' ) { 
            setItems(SchoolLeftbarItems)
        } else if (userType === 'guidance') {
            setItems(GuidanceLeftbarItems)
        } else if (userType === 'parent') {
            setItems(ParentLeftbarItems)
        }
 
    } , [])

    const [selectedIndex, setSelectedIndex] = useState("")

    const handleClick = index => {
        if (selectedIndex === index) {
            setSelectedIndex("")
        } else {
            setSelectedIndex(index)
        }
    }

    useEffect(() => {
        console.log(userType)
    } , [])

  return (

      <Box variant='outlined' sx={Style?.Container}>
        
        <CardMedia 
            component="img"
            image={info?.logo ?`${Vals?.API}/school-logo/${info?.logo}`  : '../logo512.png'}
            alt="logo"
            sx={{width:"75%",height:"auto" ,mr:'auto',ml:'auto',pt:'30px',pb:'30px',}} 
        />

        {/* <Box sx={Style?.AvatarMCon}>
            <Box sx={Style?.AvatarCon}>
                <Avatar   variant="rounded"/>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'flex-start',
                    justifyContent:'flex-start',
                    ml : dir=== 'rtl' ? 0 : 1,
                    mr : dir=== 'ltr' ? 0 : 1
                }}>
                    <Typography sx={{color:'primary.text',fontSize:17}}> {info?.name} </Typography>
                    <Typography  sx={{color:'#c1c1c1',fontSize:15}}> {info?.type} </Typography>
                </Box>

            </Box>
        </Box> */}
 
        { itmes?.map((val, mainKey) => {

            return  (
                <List key={mainKey} 
                    sx={{ 
                        width: '100%',
                        height:'auto',  
                    }} 
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader 
                            sx={{ 
                                backgroundColor:'primary.main',
                                fontWeight:'bold',
                                fontSize:'16px',
                                color:'primary.text',
                            }}
                            component="div" id="nested-list-subheader" >
                            {val.title}
                        </ListSubheader>
                    }>

                    { val.list.map((listVal, midKey) => { 

                        return (
                        
                            <div key={midKey} >

                                <Box sx={Style?.mainBtn}>

                                    <ListItemIcon sx={ [ Style?.ItemColor , { color : listVal.title === page ? 'primary.orange' : 'primary.text' , } ] } >
                                        {listVal.icon}
                                    </ListItemIcon>

                                    <ListItemButton 
                                        onClick={ () => { 
                                            if (listVal.dropdown.length > 0) {
                                                handleClick(midKey) 
                                            } else {
                                                if ( listVal.title === 'Logout' ) {
                                                    logout() 
                                                } else {
                                                    navigatePage( listVal.link , listVal.title ) 
                                                }
                                            }
                                        }}  
                                        sx={[ 
                                            Style?.ListItem , 
                                            { 
                                                backgroundColor : listVal.title === page ? 'primary.blue' : 'white' , textAlign:dir === 'rtl' ? 'right' : 'left'
                                            } 
                                        ]}>
                                        <ListItemText  primary={ listVal.title } sx={ {  
                                                        color : listVal.title === page? 'white' :'primary.text' ,
                                            '&:hover': { 
                                                color : 'primary.text'  
                                            }
                                        }}/>
                                        {  listVal.dropdown.length > 0 ? midKey === selectedIndex ? <ExpandLess sx={{color:'primary.text'}} /> : <ExpandMore sx={{color:'primary.text'}} />  : '' }
                                    </ListItemButton>

                                </Box>

                                <Box sx={{display:'flex',flexDirection:'column'}}> 

                                    <Collapse in={midKey === selectedIndex}    timeout="auto"  unmountOnExit>

                                        { listVal.dropdown.map((dropVal, lastKey) => {  

                                            return (

                                                <List  component="div" disablePadding key={lastKey}>

                                                    <ListItemButton sx={{ pl: 4  }}  onClick={() =>  {  navigatePage(dropVal.link , listVal.title)} }>

                                                    <ListItemText   primary={dropVal.title}  sx={{textAlign:dir==='rtl'?'right':'left', color:'primary.text'}} />

                                                    </ListItemButton>

                                                </List>
                                                        
                                            ) 

                                        })}   
                                
                                    </Collapse>  

                                </Box>
                            
                            </div>
                        )

                    } ) }



                </List>
            
            )   

        } ) }

        <Box sx={{width:'80%',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start',mt:10,mb:6}}>
            {userType === 'teacher' || userType === 'school' ? (
                <Box sx={{width:'80%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',cursor:'pointer'}}>
                    <SettingsIcon sx={{color:'primary.text',fontSize:18,mr:dir==='ltr'?1:0,ml:dir==='rtl'?1:0}} /> 
                    <Typography fontSize={18} color='primary.text' > {lang?.contact_support_} </Typography>
                </Box>
            ) : ''}
            <Typography fontSize={13} color='black' fontWeight={'bold'}  mt={0.3}>{lang?.classenda_school_admission_}</Typography>
            <Typography fontSize={13} color='primary.text' mt={0.3}> <CopyrightIcon sx={{color:'primary.text',fontSize:13}} /> {currentYear} {lang?.all_rights_reserved_}</Typography>
            <Typography fontSize={13} color='primary.text' mt={0.3}>{lang?.made_with_by_classenda_}</Typography>
        </Box>
 
      </Box>
  
 
    
  )
}

export default SideBar