import { Avatar, Grid, InputBase, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Btn, TableHeader, TextInput } from '../../components'
import { Style } from './Style'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useData } from '../../config/Provider'

const AddSubject = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ classroomData , setClassroomData ] = useState([])
  const [ classroom , setClassroom ] = useState(0)
  const [ search , setSearch ] = useState('')
  const [ check , setCheck ] = useState(0)
  const [ name , setName ] = useState('')
  const [ description , setDescription ] = useState('')
  const {lang} = useData()

  const getData = async () => {
    await API.get( `/teacher-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getClassroom = async ()  => {
    await API.get( `/teacher-classroom-attend/${classroom}` , Vals?.GET_HEADER )
    .then( function ( response ) {
      if (response?.data?.status === true) {
        setClassroomData(response?.data?.data)
      } else {
        console.log(response?.data?.msg)
      }
    })
    .catch((e) => console.log(e) )
  }

  const getSearch = async () => {
    await API.get( `/teacher-search-classrooms/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (classroom > 0) {
      getClassroom()
    }
  } , [classroom])

  useEffect(() => {
    if (search?.length > 0 ) {
      getSearch()
    } else {
      getData()
    }
  }, [search?.length])

  const ClassRoomData = () => {

    const StudentCard = ({values}) => {


      const checked = async (e,name) => {
        setCheck(e)
        setName(name)
      }

      return(
        <ListItem sx={[Style?.StudentCard,{cruser:'pointer'}]} onClick={() => checked(values?.student_id , `${values?.student_fname} ${values?.student_lname}`)}>
          <Avatar size="larg" sx={Style?.avatar}>{values?.student_fname?.substr(0,1)}</Avatar>
          <Box mt={2} sx={{justifyContent:'space-between',display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
            <Typography color='primary.text' variant='p'>{values?.student_fname} {values?.student_lname}</Typography>
          </Box>
        </ListItem>
      )
    }
    return (
      <>
        <TableHeader  {...{title:'Add Evaluation', buttonIcon : '' , buttonTitle:'Back' , page:() => setClassroom(0)}}/>
          <Grid container>
            {classroomData?.length > 0 ? classroomData?.map((values,k) => ( 
                <Grid item={true} key={k} xs={12} sm={12} md={4} lg={3} xl={3}>
                  <StudentCard  {...{values}} />
                </Grid>
            )) : <Typography color='primary.text'> No students found </Typography>}
          </Grid>
      </>
    )
  }
 
  const Tag = ({item}) => (
    <Box sx={[Style?.Tag , { backgroundColor: 'primary.main' , borderColor : classroom === item?.division_id ? 'primary.orange' : 'primary.border' }]}
       onClick={() => Select(item?.division_id , `${item?.cr_title} / ${item?.division_title}`)} >
        <Typography color='primary.text'> {item?.cr_title} / {item?.division_title}</Typography>
    </Box>
  )

  const Select = (classroom , title) => {
    const currentDate = new Date();
    setClassroom(classroom)
  }

  const AddEv = async () => {
    await API.post( `/add-evaluation/` , {  description : description , student_id : check , info: JSON.stringify(selectedEv) } , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
            nav('/evaluations/')
        } else {
            console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    setEvType('')
    setEvSelected(false)
  }, [check])
  
  const [evType, setEvType] = useState('')
  const [evSelected, setEvSelected] = useState(false)

  const SelectEvType = (e) => {
    setEvType(e)
    setEvSelected(true)
    setselectedEv([])
  }

  const PTypes = [
    { title : lang?.on_task_ , value : 'on task'} ,
    { title : lang?.participating_ , value : 'participating' } ,
    { title : lang?.cooperative_ , value : 'cooperative' } ,
    { title : lang?.well_done_  , value : 'well done'} ,
    { title : lang?.respectful_  , value : 'respectful'} ,
  ]

  const NTypes = [
    { title : lang?.not_organized_  , value : 'not organized'} ,
    { title : lang?.hard_to_stay_on_task_  , value : 'hard to stay on task'} ,
    { title : lang?.uncooperative_  , value : 'uncooperative'} ,
    { title : lang?.disruptive_  , value : 'disruptive'} ,
    { title : lang?.disrespectful_  , value : 'disrespectful'} ,
  ]

  const [selectedEv, setselectedEv] = useState([])

  const selectEv = (e) => {
    const a = selectedEv?.find((item) => item.title === e)
    if (a) {
      const b = selectedEv?.filter((item) => item.title !== e)
      setselectedEv(b)
    } else {
      setselectedEv([...selectedEv , {title : e}])
    }
  } 

  useEffect(() => {
    console.log(selectedEv)
  }, [selectedEv?.length])
  
  return (
    <Box sx={Style?.Container}>
      {check == 0 ?   
      
        classroom !== 0 ? 
          <ClassRoomData />
        : 
          <>
            <TableHeader {...{ title:'Add Evaluation' , buttonTitle : 'back'  , page : () => nav('/evaluations/') }} />
            <TextInput {...{value:search,set:setSearch,placeholder:lang?.search_,type:'text'}}/>
            <Box mt={3}>
              {data?.map((item,k) => ( <Tag key={k} {...{item}} /> ))}
            </Box>
          </>
      
      : 
      

        <Box p={2}>

        <TableHeader {...{ title:`${lang?.add_evaluation_} ( ${name} )` , buttonTitle : lang?.back_ ,  page : () => setCheck(0) }} />

          <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
            
            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}>
              <Box sx={{width:'100%',}}>
                <Box sx={[Style?.Tag , {borderColor:evType===lang?.nigative_?'primary.orange':'primary.border'}]} onClick={() => SelectEvType(lang?.nigative_)}>
                    <Typography color='primary.text'> {lang?.nigative_} </Typography>
                </Box>
                <Box sx={[Style?.Tag , {borderColor:evType===lang?.positive_?'primary.orange':'primary.border'}]} onClick={() => SelectEvType(lang?.positive_)}>
                    <Typography color='primary.text'> {lang?.positive_} </Typography>
                </Box>
                {evSelected&& (
                  <>
                  
                    {evType === lang?.positive_ && (
                      <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
                        {PTypes?.map((item,key) => 
                          <Box key={key} sx={[Style?.Tag , 
                            { borderColor : selectedEv?.find((v) => v.title === item.value ) ? 'primary.orange' : 'primary.border' }
                          ]} onClick={() => selectEv(item?.value) }>
                            <Typography color='primary.text'> {item?.title} </Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                    {evType === lang?.nigative_ && (
                      <Box sx={{width:'100%',float:'left',mt:2,mb:2}}>
                        {NTypes?.map((item,key) => 
                          <Box key={key} sx={[Style?.Tag , 
                            { borderColor : selectedEv?.find((v) => v.title === item.value ) ? 'primary.orange':'primary.border'}
                          ]} onClick={() => selectEv(item?.value)}>
                            <Typography color='primary.text'> {item?.title} </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box> 

              {/* <InputBase value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Title' sx={Style?.Input} variant="outlined" autoComplete='off'/> */}
              
              <InputBase 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
                placeholder={lang?.note_} 
                sx={Style?.Input} 
                multiline={true}
                rows={3}
                variant="outlined" autoComplete='off'
              />
              {selectedEv?.length > 0 && (
                <Btn {... { buttonTitle:`${lang?.add_}` , func : AddEv }} />
              )}
            </Box>

            <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

          </Box>

        </Box>
      
      
      }

    </Box>
  )
}

export default AddSubject