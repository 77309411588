import { IconButton, InputBase, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { TableHeader } from '../../components'
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Style } from './Style'
import Vals from '../../config/Vars';
import { API, HandleCreate64ForState } from '../../config/functions';
import { useData } from '../../config/Provider';
import SummarizeIcon from '@mui/icons-material/Summarize';

const ParentAddStudentDoc = ({nav}) => {

    const [ title , setTitle ] = useState('')

    const {lang} = useData()
    const [ file , setFile ] = useState(null)
    const selectFileUpload = HandleCreate64ForState(setFile)

    const page = async () => {
        var data = new FormData();
        data.append(`file` , file?.blob ,  file?.name )
        data.append(`filename` , title  )
        await API.post(`/add-doc-personal/`, data , Vals?.POST_UPLOAD_HEADER )
        .then(function (response) {

            if (response?.data?.status) {
                nav('/student-docs/')
            } 

        }).catch(function (error) {
            console.log(error)
        });
    }

    return (
        <Box sx={Style?.Container}>
        
            <TableHeader {...{ title:lang?.add_student_document_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />

            <Box sx={{justifyContent:'center',display:'flex',alignItems:'center' }}>
                
                <Box sx={{ width:{sx:'50%',md:'50%'} , display:'flex',flexDirection:'column' ,  }} p={2}>
                    
                    <Box sx={{width:'100%',display:'flex',alignItems:'center',flexDirection:'row' , justifyContent:'center'}}>
                        
                        <InputBase value={title} onChange={(e) => setTitle(e.target.value)} placeholder={lang?.description_} sx={Style?.Input} variant="outlined" autoComplete='off'/>

                        <IconButton aria-label="file"component="label" >
                            <input type='file' hidden={true}  onChange={ selectFileUpload }/>
                            <SummarizeIcon  sx={{color:"primary.text",fontSize:38,mt:1}}/>
                        </IconButton> 

                    </Box>

                    {file?.name && (
                        <Typography sx={{color:'primary.text',mt:3}}> {lang?.file_} : {file?.name}</Typography>
                    )}
                    
                </Box>

                <Box sx={{ width:{sx:'50%',md:'50%'} , }} p={2}></Box>

            </Box>

        </Box>
    )
}

export default ParentAddStudentDoc