import React, { useState } from 'react'
import html2pdf from "html2pdf.js";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { API, generatePassword } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';

const Pdf = ({ id , single , setLoad }) => {
    
    const { info } = useData()

    const getData = async () => {
        try {
          const response = await API.get(single ? `get-student/${id}` : `get-classroom-pdf/${id}`, Vals?.API) 
          await handleDownloadPdf(single ? [response?.data?.data] : response?.data?.data);
        } catch (error) {
          console.log(error);
        }
      };
 
    const handleDownloadPdf = async (data) => {


        const imagePath = "./images/A4-password.jpg";
        const image = new Image();
        image.crossOrigin = "Anonymous";

        image.onload = async () => {

            setLoad(true)
     
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0);

            const imgData = canvas.toDataURL("image/jpeg");

            let htmlContent = ''; 

            data?.map(( item , key )=> {

                const pass = generatePassword(item?.user_email)

                htmlContent += `

                    <div style="position: relative;width:100%">

                        <img src="${imgData}" style="max-width: 99%; height: auto;" />

                        <div style="
                            font-size : 18px ;
                            position : absolute ; 
                            top : 10% ; 
                            left: 50% ; 
                            transform : translate(-50%, -50%);
                            width : 100% ;
                            text-align : center ;
                        ">

                            ${info?.logo != null ? (` 
                                <img src="${info?.logo}" style="
                                    width : auto ; 
                                    height : 65px ;
                                "/>
                            `) : '' }

                        </div>

                        <h1 style=" 
                                font-size : 18px ;
                                position : absolute ; 
                                top : 12% ; 
                                left: 50% ; 
                                transform : translate(-50%, -50%);
                                width : 100% ;
                                text-align : center ;
                            "> ${info?.school_name} </h1>

                        <h1 style=" 
                                font-size : 18px ;
                                position : absolute ; 
                                top : 28% ; 
                                left: 50% ; 
                                transform : translate(-50%, -50%);
                                width : 100% ;
                                text-align : center ;
                            "> ${item?.student_fname} ${item?.student_lname} </h1>

                        <h1 style=" 
                                font-size : 18px ;
                                position : absolute ; 
                                top : 425px ; 
                                left: 50% ; 
                                transform : translate(-50%, -50%);
                                width : 100% ;
                                text-align : center ;
                            "> ${item?.user_email} </h1>

                        <h1 style=" 
                                font-size : 18px ;
                                position : absolute ; 
                                top : 520px ; 
                                left: 50% ; 
                                transform : translate(-50%, -50%);
                                width : 100% ;
                                text-align : center ;
                            "> ${pass} </h1>

                        <h1 style=" 
                                font-size : 18px ;
                                position : absolute ; 
                                top : 55% ; 
                                left: 50% ; 
                                transform : translate(-50%, -50%);
                                width : 100% ;
                                text-align : center ;
                            "> ${item?.student_mobile} </h1>
                    </div>
                    
                `
            })

            const pdfElement = document.createElement("div");
            pdfElement.innerHTML = htmlContent;
            document.body.style.margin = "0";
            document.body.style.padding = "0";

            html2pdf().from(pdfElement).save("image.pdf").then(() => {
                setLoad(false);
            });

        };

        image.src = imagePath;

    }
 
    return (
        <PictureAsPdfIcon 
            sx={{
                color:'primary.orange' ,
                fontSize:35 ,
                cursor:'pointer'
            }} 
            onClick={getData} 
        />
    )

}

export default Pdf