import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const SchoolClassrooms = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState('')
  const { lang , dir } = useData()
  const page = () => {
    nav('/add-classroom/')
  }

  const getData = async () => {
    await API.get( `/school-classrooms/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
  const getSearch = async () => {
    await API.get( `/school-search-classrooms/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    if (search?.length > 0) {
      getSearch()
    } else {
      getData()
    }
  } , [search?.length])
 
  const TableLoop = () => {

    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.cr_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.cr_count} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} onClick={() => nav(`/edit-classroom/${v.cr_id}`)} > 
          <IconButton  aria-label="edit">
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        {/* <TableCell sx={{color:'primary.text'}} > 
          <IconButton aria-label="delete">
            <BlockIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell>  */}
      </TableRow>
    )))
  } 

  useEffect(() => {
    getData()
  } , [])
 
  const Header = [
    { title : lang?.classroom_ } ,
    { title : lang?.subjects_per_day_ } ,
    { title : lang?.edit_ } ,
    // { title : 'Delete' } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <TableHeader {...{ title:lang?.classrooms_ , buttonTitle :lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase value={search} onChange={(e) => setSearch(e.target.value)} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default SchoolClassrooms