import { Avatar, Card, Grid, IconButton, InputBase, ListItemButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Style } from './Style';
import { API, CalculateTime } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';
const StudentChatGroup = () => {

    const [ open , setOpen ] = useState(false)
    const [ users , setUsers ] = useState([])
    const [ user , setUser ] = useState(0)
    const [ teacher , setTeacher ] = useState(0)
    const [ msgs , setMsgs ] = useState([])
    const [ msg , setMsg ] = useState([])
    const [ search , setSearch ] = useState('')
    const {lang} = useData()
    const chatContainerRef = useRef(null)

    const [ active , setActive ] = useState({
        name : '',
        type : '',
        pic : ''
    })

    const { socket , info } = useData()

    const UserCard = ({item},key) => (
        <ListItemButton key={key} sx={[Style?.userCard , { borderColor:  active?.name === `${item?.teacher_fname} ${item?.teacher_lname}`? 'primary.orange' :'primary.border'  }] } elevation={3} onClick={() => start(item?.teacher_id , item , item?.division_id)}>
            <Avatar src={item?.teacher_profile_pic} sx={Style?.userCardProfilePic}>{item?.teacher_fname?.substr(0,1)}</Avatar>
            <Box sx={Style?.userCardInfo}>
                <Typography sx={Style?.userCardInfoUsername}> {item?.teacher_fname} {item?.teacher_lname} </Typography>
                <Typography sx={Style?.userCardInfoMsg}> {item?.msg}... </Typography>
            </Box>
        </ListItemButton>
    )
 
    const ChatText = ({item},key) => (
        <Box key={key} sx={[Style?.textMsgContainer , item?.chat_sender_id === info?.id ? Style?.myText : Style?.NotMyText ] } elevation={3}>
           <Box sx={[Style?.textMsgInnerContainer ,  item?.chat_sender_id === info?.id ? {borderBottomRightRadius : 0} : {borderBottomLeftRadius : 0} , { overflowWrap:'break-word' } ] } elevation={3}>
                <Typography sx={Style?.userCardInfoUsernameName}> {item?.chat_user_name} </Typography>
                <Typography sx={Style?.userCardInfoUsername}> {item?.chat_contnet} </Typography>
                <Typography sx={Style?.userCardInfoDate}> {item?.date === 'Now' ? 'Now' :CalculateTime(item?.chate_date)} </Typography>
           </Box>
        </Box>
    )

    const getUsers = async () => {
        await API.get( `/student-chat-users/` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setUsers(response?.data?.data)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    const getSearch = async () => {
        await API.get( `/search-student-chat-users/${search}` , Vals?.GET_HEADER )
        .then( function ( response ) {
          if (response?.data?.status === true) {
            setUsers(response?.data?.data)
          } else {
            console.log(response?.data?.msg)
          }
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        if (search?.length > 0) {
            getSearch()
        } else {
            getUsers()
        }
    }, [search?.length])
    
    const back = () => {
        setOpen(false)
    }

    const start = async (id,item,division_id) => {
        setUser(division_id)
        setTeacher(id)
        await API.get( `/get-student-classroom-chats/${id}` , Vals?.GET_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
            setMsgs(response?.data?.data)
            setActive({
                name : `${item?.teacher_fname} ${item?.teacher_lname}`,
                type : 'TEACHER',
                pic : item?.teacher_profile_pic ,
                id : item?.teacher_id
            })
            setOpen(true)
            setTimeout(() => {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1000)
            } else {
            console.log(response?.data?.msg)
            }
        })
        .catch((e) => console.log(e) )
 
    }

    const sendMsg =   () => {
        if (typeof msg !== 'string' || !msg.trim()) {
            return false;
        }
        socket?.emit('SEGmessage', { 
            msg : msg , 
            id : info?.id , 
            school : info?.school , 
            getter : user  ,
            type : 'classroom' ,
            teacher : teacher , 
            user : 'student',
            name : info?.name
        })
        setMsg('')
    }

    useEffect(() => {
 
        socket?.on('RCGmessage', (req) => {
            setMsgs((e) => {
                return[...e , {
                    chat_contnet : req?.chat_contnet ,
                    chat_sender_id : req?.chat_sender_id ,
                    chat_date:req?.chat_date ,
                    getter : req?.getter,
                    chat_user_name : req?.name
                }]
            })
            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        })

    } , [])

  return (
    <>

    <Box sx={[Style?.PageHeader ]} elevation={0} spacing={0}>
        <Box>
            <Typography sx={{fontSize:18,color:'primary.text'}}> {lang?.messenger_}  </Typography>
            <Typography sx={{fontSize:30,color:'primary.text'}}> {lang?.chat_app_}  </Typography>
        </Box>
        <Box>
            {open && (
                <IconButton onClick={back} sx={{display:{ md:'none' } , }} >
                    <CloseOutlinedIcon sx={{color:'primary.text'}} />
                </IconButton>
            )}
        </Box>
    </Box>
   
    <Card sx={[Style?.Container ]} elevation={0} spacing={0}>
         
        <Grid container>
       
            <Grid item xs={12} sm={12} md={2.2} lg={2.2} xl={2.2} sx={{display:{ xs: open ? 'none' : 'flex' ,sm: open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                <Box sx={Style?.ChatSideBar}>
                    <Box sx={Style?.InputContainer}>
                        <InputBase onChange={(e) => setSearch(e.target.value)} value={search} placeholder={lang?.search_} sx={Style?.SearchInput} variant="outlined" autoComplete='off'/>
                    </Box>
                    <Box sx={Style?.ChatItemsComtainer}>
                        {users?.map((item,key) => (<UserCard key={key} {...{item,key}} />))}
                    </Box>
                </Box>
            </Grid> 

            <Grid item xs={12} sm={12} md={9.8} lg={9.8} xl={9.8}  sx={{display:{ xs: !open ? 'none' : 'flex' ,sm: !open ? 'none' : 'flex' , md:'none' ,lg:'none' ,xl:'flex' , }}}>
                
                <Box sx={Style?.ChatRightBar}>

                    <Box sx={Style?.ChatHeader}>
                       {active?.name && (
                            <>
                                <Avatar  sx={Style?.userCardProfilePic}>{active?.name?.substr(0,1)}</Avatar>
                                <Box sx={Style?.ChatUsernameInfo}>
                                    <Typography sx={Style?.ChatUsername}> {active?.name} </Typography>
                                    <Typography sx={Style?.ChatUserType}> {lang?.group_} </Typography>
                                </Box> 
                            </>
                       )}

 
                    </Box>

                    <Box sx={Style?.ChatTexts}>

                        {msgs?.map((item , key) => ( <ChatText key={key} {...{item,key}} /> ))}
                        <div ref={chatContainerRef}></div>

                    </Box>

                    <Box sx={Style?.TypeInputSectionContainer}>
                            
                        {active?.name && (
                            <>
                                <InputBase onChange={(e)=>setMsg(e.target.value)} value={msg} placeholder={`${lang?.type_}...`} sx={Style?.TypeInput} variant="outlined" autoComplete='off'/>

                                <IconButton aria-label="send" onClick={sendMsg}>
                                    <SendOutlinedIcon  sx={{color:"primary.text"}}/>
                                </IconButton>      
                            </>
                        )}


                    </Box>

                </Box>
            </Grid>
        </Grid>
    </Card>
    </>
  )
}

export default StudentChatGroup