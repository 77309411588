import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { DataTable, Pdf, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, CircularProgress, IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const SchoolDivisions = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ search , setSearch ] = useState()
  const [ load , setLoad ] = useState(false)
  const { lang , dir } = useData()
  const page = () => {
    nav('/add-division/')
  }

  const getData = async () => {
    await API.get( `/school-divisions/` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const getSearch = async () => {
    await API.get( `/search-school-divisions/${search}` , Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (

      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.cr_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.division_title} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > {v.stCount} </TableCell> 
        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/edit-division/${v.division_id}`)}>
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell>

        <TableCell sx={{color:'primary.text',textAlign:dir==='rtl'?'right':'left'}} > 
            <Pdf {...{ id : v.division_id , single : false ,  setLoad : setLoad }} />
        </TableCell>
  
      </TableRow>
    )))
  } 

  useEffect(() => {
    if (search?.length > 0) {
      getSearch()
    } else {
      getData()
    }
  } , [search?.length])
 
  const Header = [
    { title : lang?.classroom_  } ,
    { title : lang?.division_  } ,
    { title : lang?.students_  } ,
    { title : lang?.edit_ } ,
    { title : lang?.PDF_info_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <Backdrop sx={{ color: 'primary.orange', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={load}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <TableHeader {...{ title:lang?.division_ , buttonTitle : lang?.add_ , buttonIcon : <AddIcon /> , page : page }} />
      <Box sx={{
        display:'flex',
        flexDirection:'row',
        width:'100%',
        alignItems:'center',
        justifyContent:'space-between',
      }}>
        <InputBase value={search} onChange={(e) => setSearch(e.target.value)} placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      </Box>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default SchoolDivisions