import { Box } from '@mui/system'
import React, { useState ,useEffect } from 'react'
import { AlertsDialog, DataTable, TableHeader } from '../../components'
import { Style } from './Style'
import AddIcon from '@mui/icons-material/Add';
import { IconButton, InputBase, TableCell, TableRow, TextField } from '@mui/material';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import BlockIcon from '@mui/icons-material/Block';
import Vals from '../../config/Vars';
import { API } from '../../config/functions';
import { useData } from '../../config/Provider';

const Schools = ({nav}) => {
  const [ data , setData ] = useState([])
  const [ showAlert , setShowAlert ] = useState(false)
  const [ deleteId , setDeleteId ] = useState(0)
  const {lang} = useData()
  const page = () => {
    nav('add-school')
  }

  const getData = async () => {
    await API.get( `/schools/`  ,  Vals?.GET_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          setData(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }

  const block = async () => {
 
    await API.post( `/block-school/` , {id : deleteId} , Vals?.POST_HEADER )
    .then( function ( response ) {
        if (response?.data?.status === true) {
          const filter = data?.filter((v) => v.school_id != deleteId ) 
          setData(filter)
          setShowAlert(false)
        } else {
          console.log(response?.data?.msg)
        }
    })
    .catch((e) => console.log(e) )
  }
 
  const TableLoop = () => {
    return ( data?.map( ( v , k ) => (
      <TableRow key={k} sx={Style?.Row}> 
        <TableCell sx={{color:'primary.text'}} > {v.school_name} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > {v.school_student_count} </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton  aria-label="edit" onClick={() => nav(`/edit-school/${v.school_id}`)}>
            <ModeEditTwoToneIcon sx={{color:'primary.orange'}}/>
          </IconButton>
        </TableCell> 
        <TableCell sx={{color:'primary.text'}} > 
          <IconButton aria-label="delete" onClick={() => blockAlert(v.school_id) }>
            <BlockIcon sx={{color:'primary.orange'}} />
          </IconButton>
        </TableCell> 
      </TableRow>
    )))
  } 

  useEffect(() => {
    getData()
  } , [])


  const blockAlert =(id) => {
    setDeleteId(id)
    setShowAlert(true)
  }
  
 
  const Header = [
    { title : lang?.school_ } ,
    { title : lang?.users_ } ,
    { title : lang?.edit_ } ,
    { title : lang?.block_ } ,
  ]

  return (
    <Box sx={Style?.Container}>
      <AlertsDialog {...{
        open:showAlert,setOpen:setShowAlert,func:block , btnTitle : lang?.block_ , alertText : `${lang?.are_you_sure_you_want_to_block_this_school_}` 
      }} />
      <TableHeader {...{ title:lang?.schools_ , buttonTitle :lang?.add_  , buttonIcon : <AddIcon /> , page : page }} />
      <InputBase placeholder={lang?.search_} sx={Style?.Input} variant="outlined" autoComplete='off'/>
      <DataTable {...{ Header , Data : <TableLoop /> }} />
    </Box>
  )
}

export default Schools