import { TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React , { useState , useEffect } from 'react'
import { DataTable, PieChart , TableHeader  } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'
import { useData } from '../../config/Provider'
import AlbumIcon from '@mui/icons-material/Album';

const StudentReport = () => {

  const [ student , setStudent ] = useState(0)
  const [ studentName , setStudentName ] = useState('')
  const {lang,dir} = useData()
 
  const StudentReport = () => {
    
    const [ chartDataArr , setChartDataArr ] = useState([])

    const getChartData = async () => {
      await API.get( `/student-chart-data/` , Vals?.GET_HEADER )
      .then( function ( response ) {
        if (response?.data?.status === true) {
          setChartDataArr(response?.data?.data)
        } else {
          console.log(response?.data?.msg)
        }
      })
      .catch((e) => console.log(e) )
    }
    useEffect(() => {
      getChartData()
    } , [student])

    const typesArr = [
      { title : lang?.on_task_ , color : '#007BA7' } ,
      { title : lang?.participating_ , color : '#50C878' } ,
      { title : lang?.cooperative_ , color : '#87CEEB' } ,
      { title : lang?.well_done_ , color : '#00FF00' } ,
      { title : lang?.respectful_ , color : '#40E0D0'  } ,
      { title : lang?.not_organized_ , color : '#E34234'  } ,
      { title : lang?.hard_to_stay_on_task_ , color : '#DC143C' } ,
      { title : lang?.uncooperative_ , color : '#8B0000' } ,
      { title : lang?.disruptive_ , color : '#FF4500' } ,
      { title : lang?.disrespectful_ , color : '#B22222' } ,
    ]

    const TableLoop = () => {

      const DataConvert = (e) => {
        const date = new Date(e);
        const formate = date.toISOString().slice(0, 10);
        return formate
      }

      return ( chartDataArr?.all?.map( ( v , k ) => (
        <TableRow key={k} sx={Style?.Row}> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {v.teacher_fname} {v.teacher_lname} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {v.e_description} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} > {JSON.parse(v.e_types)?.map((item,key) => <Typography key={key} color='primary.text'> {key+1}-{item?.title} </Typography>)} </TableCell> 
          <TableCell sx={{color:'primary.text',borderColor:'primary.border',textAlign:dir==='rtl'?'right':'left'}} >  {DataConvert(v?.e_date)} </TableCell> 
        </TableRow>
      )))
    } 
    const Header = [
      { title : lang?.teacher_ } ,
      { title : lang?.note_ } ,
      { title : lang?.evaluation_ } ,
      { title : lang?.date_ } ,
    ]

    return (
        <Box sx={{height:'auto'}}> 
            <Box sx={{
              width:'100%',
              height:'100%',
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',alignItems:'center'
            }}>
              <Box sx={{height:'100%',width:'50%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Box sx={{
                  width:'50%'
                }}>
                  {typesArr?.map((item,key) => (
                    <Box key={key} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',mt:1}}>
                      <AlbumIcon sx={{color:item?.color,ml:2,mr:2}} />
                      <Typography color='primary.text'> { item?.title } </Typography> 
                    </Box>
                  ))}
                </Box>

              </Box>
              <Box sx={{height:'100%',width:'30%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <PieChart  info={chartDataArr}  />
              </Box>
            </Box>
            <DataTable {...{ Header , Data : <TableLoop /> }} />
        </Box>
    )
  }
   
  return (
    <Box sx={Style?.Container}>
      <Box sx={Style?.ItemContainer}>
        <TableHeader {...{title:lang?.report_ }} />
        <StudentReport />
      </Box>
    </Box>
  )
}

export default StudentReport