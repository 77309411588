export const Style = {
    
    Container : {
        mr : 2 ,
        ml : 2 ,
        mt : 2 ,
        p:2,
        backgroundColor:'white',
        border:'2px solid #e4e6e5',
        borderRadius:2
    } ,

    InnerContainer : {

    } ,

    Row : {         
    "& td": { 
        borderColor:'primary.border'
    },
    transition:'0.2s', 
    cursor : 'pointer' , 
    borderColor:'red' ,
    '&:hover' : { 
            backgroundColor : 'primary.main'
        }
    }

}